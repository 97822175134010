(function(window){
    'use strict';

	// Called when the API object is instantiated
    function definePalmuLibrary(){
        var Palmu = {};

        var _loggingServer = "";
        var _scriptPath = "";
		var _isResponding = false;
		var _queue = {}
		var _startTime = Date.now();
		var _indexId = 0;
		var _instanceData = new Array();
		var _maxRetries = 3;
		var _numRetries = 0;
		var _resendTimer = {};
		var _inProgress = false;

		// Set the meta data related to the app being logged.
		// Should be called after getting the API object and before logging anything.
		Palmu.configure = function(user, app, instance, version) {
			_instanceData["userName"] = user;
			_instanceData["appName"] = app;
			_instanceData["appInstance"] = instance;
			_instanceData["versionNumber"] = version;
			_instanceData["logInstanceCreated"] = _startTime;
		}

		// Data structure that should be used to encapsulate the data to be logged.
		Palmu.LogEntry = function() {
			this.id = "";
			this.userAgent = {};
			this.timestamp = 0;
			this.feature = "";
			this.value = "";
			this.parameters = {};
			this.view = "";
			this.level = "";
			this.context = {};
		}

		// Returns the status of the logging server.
		// true: is responding
		// false: is not responding
		Palmu.status = function() {
			return _isResponding;
		}

		// Set the logging server URI.
		Palmu.setLoggingServer = function(server) {
			_loggingServer = server;
			console.log("Set logging server URI to: " + _loggingServer);
		}

		// Set the log script path.
		Palmu.setScriptPath = function(path) {
			_scriptPath = path;
			console.log("Set script path to: " + _scriptPath);
		}

		// Add entry to queue and trigger send.
		// returns: ID assigned for the log entry
		Palmu.log = function(logEntry) {
			var entryId = _startTime + "_" + (_indexId++);
			logEntry.timestamp = Date.now();
			logEntry.id = entryId;

			var uaInfo = Palmu.getUserAgentInformation();
			for(var i in uaInfo) {
				logEntry.userAgent[i] = uaInfo[i];
			}

			for(var i in _instanceData) {
				logEntry[i] = _instanceData[i];
			}

			_queue[entryId] = logEntry;
			Palmu.sendToServer();
			return entryId;
		}

		// Send the queued log entries to the server.
		// If the server returns success, check if new items have been added to queue
		// and call self again to send.
		Palmu.sendToServer = function() {

			if(_inProgress) // Request already in progress, return
				return;

			if($.isEmptyObject(_queue)) { // Nothing in the queue, abort

				if(undefined != _resendTimer) {
					clearInterval(_resendTimer);
				}
				return;
			}

			if(_numRetries == _maxRetries) { // Reached max retry count, clear timer

				_numRetries = 0;

				if(undefined != _resendTimer) {
					clearInterval(_resendTimer);
				}
				return;
			}

			_inProgress = true;
			_numRetries++;
			var output = JSON.stringify(_queue);

			// Send log entries to server
			$.ajax({
                url: _loggingServer + _scriptPath,
				type: 'post',
				data: output,
				dataType: 'json',
				contentType: 'application/json',
				success: function(data) {

					if(undefined != _resendTimer) {
						clearInterval(_resendTimer);
					}

					//console.log("Entries successfully logged.");

					for(var entry in data) {
						var prop = data[entry];
						delete _queue[prop]; // !
					}

					_inProgress = false;

					if(!$.isEmptyObject(_queue)) {
						_numRetries = 0;
						Palmu.sendToServer();
					}
				},
				error: function(xhr, ajaxOptions, thrownError) {

					console.log("Error on try " + _numRetries + ". Tries left: " + (_maxRetries-_numRetries));

					if(!$.isEmptyObject(_queue) && _numRetries < _maxRetries) {

						_resendTimer = setTimeout(function() {
							_inProgress = false;
							console.log("Error logging data to server. Resending after 3 seconds");
							Palmu.sendToServer();
						}, 3000);
					}
				}
			});
		}

		// Check connection to server
		Palmu.checkConnection = function() {
			$.ajax({
				url: _loggingServer + _scriptPath,
				type: 'get',
				success: function(data) {
					_isResponding = true;
				},
				error: function(xhr, ajaxOptions, thrownError) {
					if(xhr.status == 404) {
						console.log("Server not responding: " + thrownError);
						_isResponding = false;
					}
				}
			});
		}

		// Generate user agent information
		Palmu.getUserAgentInformation = function() {
			var info = new Array();
			info["resolution"] = $(window).width() + "x" + $(window).height();
			info["userAgent"] = navigator.userAgent;
			return info;
		}

		Palmu.LogLevel = {
			"INTERACTION": 0,
			"DEBUG": 1,
			"INFO": 2,
			"WARN": 3,
			"ERROR": 4,
			"FATAL": 5
		}

        return Palmu;
    }
    if(typeof(Palmu) === 'undefined'){
        window.Palmu = definePalmuLibrary();
		console.log("Library created");
    }
    else{
        console.log("Library already defined.");
    }
})(window);
