/**
 * @file Handlels generating the different rooms and adding static elements to them.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

/**
 * List of rooms and coordinates for map and elements in rooms.
 * <p>
 * Each room must have the next key-value pairs:
 *    <ul>
 *      <li>coordinates: coordinates for the room in the frontpage layout</li>
 *      <li>task_coordinates: where to add the stars on the frontpage</li>
 *      <li>background1: centered background image with transparent background</li>
 *      <li>background2: background image that is stretched accross the display (walls and floor)</li>
 *      <li>stage_id: #id of the stage object</li>
 *      <li>page_id: #id of the page for the room</li>
 *      <li>key: room key for easy access</li>
 *    </ul>
 * </p>
 * <p>
 * Elements that are added to rooms as separate images/HTML (interactive or not) can have the next properties:
 *    <ul>
 *      <li>css: the position of the element in pixels, e.g. { "top": 377, "left": 10}</li>
 *      <li>img: the main image used for the element</li>
 *      <li>img_active: a special image used when there are unfisished tasks/questions connected to this element</li>
 *      <li>name: class to be used with the Konva element</li>
 *      <li>modal: #id of the modal that should be opened when the element is clicked</li>
 *   </ul>
 * </p>
 * @type {Object}
 **/
var rooms = {
    "työhuone": {
        "coordinates":[20,17,927,17,927,613,20,613],
        "task_coordinates" : [410,490],
        "elements":{
            "Puhekupla": {
                "css": { "top": 377, "left": 10}
            },
            "Sähköposti_": {
                "css": {"left": 1020, "top": 634}},
            "Omakuva": {
                "img": "img/elements/mirror.png",
                "img_active": "img/elements/mirror_active.png",
                "name": "omakuva",
                "css": {"top": 400, "left": 2010},
                "modal": "profilesModal"
            },
            "Puhelu": {
                "css": {"top": 860, "left": 914}},
            "Ovi": {
                "css": {"top": 250, "left": 0}
            },
            "Ikkuna": {
                "css": {"top":260, "left": 85},
                "img": "img/elements/window.png",
                "img_active": "img/elements/window_active.png",
                "static_img": "img/elements/window_static.png",
                "name": "ikkuna",
                "modal": "workdayModal",
                "questiongroup": 4
            }
        },
        "background1": "img/rooms/managersoffice.png",
        "background2" : "img/rooms/managersoffice_bg.png",
        "stage_id": "office_stage",
        "page_id": "office",
        "key": "työhuone"
    },
    "käytävä": {
        "coordinates": [19,614,928,613,929,18,1040,20,1041,614,1945,614,1947,729,20,726],
        "task_coordinates" : [1285,670],
        "elements": {
            "Puhekupla": {
                "css": { "top": 277, "left": 230}},
            "Viesti aloitelaatikossa": {
                "css": {"left": 888, "top": 400},
                "img": "img/elements/suggestions_box.png",
                "modal": "feedbackModal"
            },
            "Roska lattialla": {
                "css": {"top": 1035, "left": 1412}
            }
        },
        "page_id": "kaytava",
        "stage_id": "kaytava_stage",
        "background1": "img/rooms/kaytava.png",
        "background2": "img/rooms/hallway_bg.png",
        "key": "käytävä"
    },
    "toimitilat": {
        "coordinates":[1043,19,1947,20,1946,613,1042,615],
        "task_coordinates" : [1445,490],
        "elements": {
            "Puhekupla": {
                "css": { "top": 245, "left": 230}}
        },
        "page_id": "toimitilat",
        "background1": "img/rooms/Jope_Toimitilat.png",
        "background2": "img/rooms/Jope_Toimitilat_bg.png",
        "stage_id": "toimitilat_stage",
        "key": "toimitilat"
    },
    "neuvotteluhuone": {
        "coordinates":[20,729,652,730,653,1325,19,1325],
        "task_coordinates" : [285,1200],
        "elements": {
            "Puhekupla": {
                "css": { "top": 370, "left": 200}},
            "Johtoryhmä": {
                "img": "img/elements/johtoryhma.png",
                "img_active": "img/elements/johtoryhma_active.png",
                "img_no_modal": "img/elements/johtoryhma_no_modal.png",
                "name": "johtoryhmä",
                "css": {"top": 286, "left":900},
                "modal": "johtoryhmaModal"
            },
            "man1.png": {
                "css": {"top": 810, "left":1075, "position": "bottom"}
            },
            "man2.png": {
                "css": {"top": 790, "left":800, "position": "bottom"}
            },
            "man3.png": {
                "css": {"top": 875, "left":356, "position": "top"}
            },
            "woman3.png": {
                "css": {"top": 805, "left":1452, "position": "bottom"}
            },
            "woman1.png": {
                "css": {"top": 1008, "left":785, "position": "top"}
            },
            "woman2.png": {
                "css": {"top": 950, "left":1158, "position": "top"}
            }
        },
        "page_id": "neuvotteluhuone",
        "stage_id": "neukkari_stage",
        "background1": "img/rooms/Jope_ConferenceRoomV5.png",
        "background2": "img/rooms/Jope_ConferenceRoomV5_bg.png",
        "key": "neuvotteluhuone"
    },
    "kahvihuone": {
        "coordinates":[653,729,1315,729,1316,1326,654,1324],
        "task_coordinates" : [935,1200],
        "elements": {
            "Puhekupla": {
                "css": { "top": 320, "left": 230}
            },
            "Pöydällä lehden etusivulla": {
                "css": {"top": 900, "left": 865}
            },
            "Pöytä": {
                "css": {"top": 1055, "left": 355}
            }
        },
        "page_id": "kahvihuone",
        "stage_id": "kahvihuone_stage",
        "background1": "img/rooms/Jope_CoffeeRoom.png",
        "background2": "img/rooms/Jope_CoffeeRoom_bg.png",
        "key": "kahvihuone"
    },
    "hallintola": {
        "coordinates":[1315,730,1947,728,1948,1325,1316,1325],
        "task_coordinates" : [1585,1200],
        "elements": {
            "Puhekupla": {
                "css": { "top": 390, "left": 790}}
        },
        "page_id": "hallintola",
        "stage_id": "hallintola_stage",
        "background1": "img/rooms/administrationOffice.png",
        "background2": "img/rooms/administrationOffice_bg.png",
        "key": "hallintola"
    }
};
var element_shadow = Math.round((4 * scale) * 10) / 10; // Shadow added to interactive elements

/**
 * Adds the second background image that is stretched accross the display to the views canvas.
 * @param {Object} view
 */
function addRoomBackground(view) {
    if(view.stage_id && view.background2) {
        var height, width, bgImage, bg;
        var window_width = $(window).width();
        var window_height = $(window).height();

        // Background image, TODO: IE doesn't get image height + image width for svg!!
        bgImage = new Image();
        bgImage.onload = function () {
            height = Math.floor(bgImage.height * scale);
            width = Math.floor(bgImage.width * scale);
            var y;
            if(height < window_height) {
                y = parseInt((window_height - height) / 2);
            } else {
                y = 0;
            }
            if(width < window_width) {
                width = window_width;
            }
            bg = new Konva.Image({
                x: ((centering && centering[0] > 0 )? 0 : centering[0]),
                y: ((centering && centering[1] > 0 )? y : centering[1]),
                image: bgImage,
                width: width,
                height: height,
                preventDefault: false
            });

            // add the shape to the layer
            view.layers.background.add(bg);
            bg.moveToBottom();
            view.layers.background.draw();

        };
        bgImage.src = view.background2;
    }
}

/**
 * Adds background1 and elements to a room.
 * @param {Object} view
 */
function addElementsToRoom(view) {
    addRoomBackground(view);
    $.each(view.elements, function(key, element) {
        appendElementImg(key, element, view);
    });
}

/**
 * Adds an element to a room.
 * @param {String} key
 * @param {Object} element
 * @param {Object} view
 */
function appendElementImg(key, element, view) {
    var image = new Image();
    var image_src;
    image.onload = function () {
        var height = scaleValue(image.height, scale);
        var width = scaleValue(image.width, scale);
        var el_img = new Konva.Image({
            x: ((element.css.left) ? scaleValue(element.css.left, scale) + centering[0] : ((centering[0] > 0) ? $(window).width() - width : $(window).width() - width - centering[0])),
            y: scaleValue(element.css.top, scale) + centering[1],
            image: image,
            width: width,
            height: height,
            shadowColor: (element.modal) ? 'black':'transparent',
            shadowBlur: (element.modal) ? 5 : 0,
            shadowOffset: (element.modal) ? {x : scaleValue(element_shadow, scale), y : scaleValue(element_shadow, scale)} : {x:0, y:0},
            shadowOpacity: (element.modal) ? 0.5 : 0,
            name: (element.name) ? element.name : 'element',
            preventDefault: false
        });

        // add the shape to the layer
        view.layers.elements.add(el_img);
        view.layers.elements.batchDraw();

        // Static image
        if(element.questiongroup && $.inArray(element.questiongroup, excludedQuestiongroups) != -1 && element.static_img) {
            el_img.shadowColor('transparent');

        } else {

            // Check if element is interactive with connected popup
            if(element.modal && (!element.img_no_modal || element.img_no_modal !== image_src)) {
                // Log data for events
                var data = {
                    'value': key,
                    'parameters': {"popup": element.modal},
                    'level': Palmu.LogLevel.INTERACTION
                };

                el_img.cache();
                el_img.drawHitFromCache();
                el_img.filters([Konva.Filters.Brighten]);
                el_img.on('mouseover', function() {
                    document.body.style.cursor = 'pointer';
                    this.brightness(0.2);
                    view.layers.elements.batchDraw();

                    data.feature = 'Element mouseover';
                    LogData(data);
                });
                el_img.on('mouseout', function() {
                    document.body.style.cursor = 'default';
                    this.brightness(0);
                    view.layers.elements.batchDraw();
                    
                    data.feature = 'Element mouseout';
                    LogData(data);
                });
                el_img.on('click tap', function() {
                    document.body.style.cursor = 'default';
                    var img = this;
                    img.brightness(0.2);
                    view.layers.elements.batchDraw();

                    if(element.modal === 'profilesModal') {
                        loadOldProfiles();
                    } else if(element.modal === 'johtoryhmaModal') {
                        printAnswersForTopic( $('#johtoryhma .answers_container'), 'Johtoryhmä' );
                    } else if(element.modal === 'workdayModal')
                        printAnswersForTopic( $('#workday .answers_container'), 'Ihannetyöpäivä' );

                    setTimeout(function(){
                        img.brightness(0);
                        view.layers.elements.batchDraw();
                    }, 500);

                    // Open popup
                    $('#'+element.modal).popup( "open" );

                    data.feature = 'Element click';
                    LogData(data);
                });
            }
        }
    };

    // Static image
    if(element.questiongroup && $.inArray(element.questiongroup, excludedQuestiongroups) !== -1 && element.static_img) {
        image.src = element.static_img;
    } else {
        // Check if element.image available
        if(element.img) {
            if(!element.modal) { // If not modal just load image
                image_src = element.img;
            } else { // Check if profilesModal, johtoryhmäModal or workdayModal are finshed and display the right image
                var completed = true;
                var no_modal = false;
                if(element.modal === 'profilesModal') {
                    completed = checkQuestionsetCompleted("Minä johtajana");
                    if(completed) {
                        completed = checkQuestionsetCompleted("Hyvinvointi");
                    }
                } else if(element.modal === 'johtoryhmaModal') {
                    if(user.boardmember) { // Check if user is a boardmember and should see the tasks
                        completed = checkQuestionsetCompleted("Johtoryhmä");
                    } else {
                        image_src = element.img_no_modal;
                        no_modal = true;
                    }
                } else if(element.modal === 'workdayModal')
                    completed = checkQuestionsetCompleted("Ihannetyöpäivä");

                if(!completed && !no_modal && element.img_active)
                    image_src = element.img_active;
                else if(!no_modal)
                    image_src = element.img;
            }
            image.src = image_src;
        }
    }
}

/**
 * Helper funtion to get a room/view by Konva element id.
 * @param {String} id
 * @returns {element}
 */
function getRoomByElementId(id) {
    var found_element = null;
    $.each(rooms, function(key, element) {
        if(element.element_id === id) {
            found_element = element;
            return false;
        }
    });
    return found_element;
}

/**
 * Helper funtion to get a room/view by page_id defined in rooms.
 * @param {String} id
 * @returns {element}
 */
function getRoomByDivId(id) {
    var found_element = null;
    $.each(rooms, function(key, element) {
        if(element.page_id === id) {
            found_element = element;
            return false;
        }
    });
    return found_element;
}
