/**
 * @file Handles generating the map view on the frontpage.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var stage = null;
var main_scale = 0;
var scale = 0;
var min_scale_val = 580;
var centering = null;
var main_centering = null;
var map_view = {
    "background1":"img/jope_landingpage.png",
    "stage_id": "main_stage",
    "layers": {},
    "elements": {
    }
};

/**
 * Initializes the frontpage.
 */
function setupMapView() {
    if(!map_view.layers)
        map_view.layers = {};
    task_layer = new Konva.Layer();
    map_view.layers.task_layer = task_layer;
    addCanvasBackground(map_view, addRoomsToMap );
    $.each(map_view.elements, function(key, element) {
        appendElementImg(key, element, map_view);
    });
}

/**
 * Adds background to a view (canvas) and calculates centering. Calls the function
 * provided in parameters (func) after image has been loaded.
 * @param {Object} view
 * @param {Function} func
 */
function addCanvasBackground( view, func ) {
    if(view.stage_id) {
        var height, width, maxLeft, maxTop, bgImage, bg;
        var window_width = $(window).width();
        var window_height = $(window).height();
        var used_scale, used_centering;
        if(view.stage) {
            view.stage.destroy();
        }

        // Background image, TODO: IE doesn't get image height + image width for svg!!
        bgImage = new Image();
        bgImage.onload = function () {
            if(view.stage_id !== 'main_stage') {
                used_scale = scale;
                used_centering = centering;
            } else {
                used_scale = main_scale;
                used_centering = main_centering;
                window_width -= 85;
                window_height -= 85;

            }

            if(!scale) {
                if(window_height > window_width && window_height < min_scale_val) {
                    used_scale = min_scale_val / bgImage.height;
                } else if(window_width > window_height && window_width < min_scale_val) {
                    used_scale = min_scale_val / bgImage.width;
                } else {
                    used_scale = Math.min(window_height / bgImage.height, window_width / bgImage.width);
                }

                if(view.stage_id !== 'main_stage') {
                    scale = used_scale;
                } else
                    main_scale = used_scale;
            }

            height = Math.floor(bgImage.height * used_scale);
            width = Math.floor(bgImage.width * used_scale);

            if(view.stage_id !== 'main_stage' && height < window_height) {
                $('.room').css('background-position', 'center center').css('background-size', '100% '+ height + 'px');
            } else {
                $('.room').css('background-position', 'none').css('background-size', '100% 100%');
            }

            if(!used_centering) {
                used_centering = [($(window).width() - width) / 2, ($(window).height() - height) / 2];
                if(view.stage_id !== 'main_stage') {
                    centering = used_centering;
                } else {
                    main_centering = used_centering;
                }
            }

            bg = new Konva.Image({
                x: used_centering[0],
                y: used_centering[1],
                image: bgImage,
                width: width,
                height: height,
                preventDefault: false
            });

            // Calculate canvas constraints
            maxLeft = (window_width - width) / 2;
            maxTop = (window_height - height) / 2;

             // Setup stage
            view.stage = new Konva.Stage({
                container: view.stage_id,
                width: $(window).width(),
                height: $(window).height(),
                draggable: true,
                dragBoundFunc: function(pos) {
                    if(window_height < height || window_width < width) {
                        // Check if canvas is within constraints
                        if(window_width < width) {
                            if (pos.x < maxLeft) {
                               pos.x = maxLeft;
                           } else if(pos.x > Math.abs(maxLeft)) {
                               pos.x = Math.abs(maxLeft);
                           }
                        } else {
                            pos.x = this.getAbsolutePosition().x;
                        }
                       if(window_height < height) {
                            if (pos.y > Math.abs(maxTop)) {
                                pos.y = Math.abs(maxTop);
                            } else if (pos.y < maxTop) {
                                pos.y = maxTop;
                            }
                        } else
                            pos.y = this.getAbsolutePosition().y;
                    } else {
                        pos.x = this.getAbsolutePosition().x;
                        pos.y = this.getAbsolutePosition().y;
                    }

                    return {
                        x: pos.x,
                        y: pos.y
                    };
                }
            });

            if(!view.layers)
                view.layers = {};
            view.layers.background = new Konva.Layer();

            // add the shape to the layer
            view.layers.background.add(bg);
            view.stage.add(view.layers.background);

            // Add the elements layer so the layers are in right order
            if(!view.layers.elements)
                view.layers.elements = new Konva.Layer();
            view.stage.add(view.layers.elements);

            if(func) {
                func(view);
            }

        };
        bgImage.src = view.background1;
    }
}

/**
 * Helper function to calculate scaled coordinates.
 * @param {Array} coords
 * @returns {Array} Scaled coordinated
 */
function getScaledCoordinates(coords) {
    var scaled = [];
    for(var i=0;i<coords.length;i++) {
        scaled.push(Math.round((i % 2 > 0) ? coords[i] * main_scale + main_centering[1] : coords[i] * main_scale + main_centering[0]));
    }
    return scaled;
}

/**
 * Adds room containers and event handlers for the frontpage.
 */
function addRoomsToMap() {
    var layer;
    $.each(rooms, function(key, room) {
        layer = map_view.layers.background;
        var poly = new Konva.Line({
            points: (main_scale !== 0) ? getScaledCoordinates(room.coordinates) : room.coordinates,
            fill: 'transparent',
            stroke: 'transparent',
            opacity: 0.1,
            strokeWidth: 0,
            closed : true,
            preventDefault: false
        });
        rooms[key].element_id = poly._id;
        // Log data for events
        var data = {
            'feature': 'Room click',
            'value': key,
            'level': Palmu.LogLevel.INTERACTION,
            'parameters': {}
        };

        poly.on('click tap', function() {
            document.body.style.cursor = 'default';
            var id = this._id;
            data.parameters.task_count = task_queue[key].count;
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#"+ getRoomByElementId(id).page_id );
            // data.feature = 'Room click';
            LogData(data);
        });

        poly.on('mouseover', function() {
            document.body.style.cursor = 'pointer';
            this.fill('#fff');
            map_view.layers.background.batchDraw();
            // data.feature = 'Room mouseover';
            // LogData(data);
        });
        poly.on('mouseout', function() {
            document.body.style.cursor = 'default';
            this.fill('transparent');
            map_view.layers.background.batchDraw();
            // data.feature = 'Room mouseout';
            // LogData(data);
        });

        // add the shape to the layer
        layer.add(poly);

        // Redraw layer
        map_view.layers.background.draw();

        // Add room backgrounds
        addCanvasBackground( room, addElementsToRoom );

    });

    if(tasks.length > 0)
        addTasksToMap();
    else {
        //listTasks();
        generateTasks();
    }
}

/**
 * Helper function to scale any value using the provided scale.
 * @param {Number} value
 * @param {Number} used_scale
 * @returns {Number}
 */
function scaleValue(value, used_scale) {
    return Math.round((value * used_scale) * 10 / 10);
}
