/*jshint multistr: true */

/**
 * @file Handles user information like loading and updating users information.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var user = null;
var replayids = null;

/**
 * Loads users profile information and calls all the functions needed to start playing.
 * @param {String} id
 */
function getUser(id) {
    $.get("php/getuser.php",{ uid: id }, function(data) {
        var attributes = $.parseJSON(data.attributes);
        user = {};
        user.id = data.identifier;
        user.username = data.loginname;
        user.name = data.name;
        user.email = data.email;
        user.sex = (attributes.gender) ? attributes.gender : 'm';
        user.organization = data.groupid;
        user.age = (attributes.age) ? attributes.age : '';
        user.organization_admin = data.organization_admin;
        user.boardid = data.boardid;
        user.compareid = data.compareid;
        setProfile();
        getOrganizationStrings(user.organization);

        if(data.organization_admin)
            $('.organization_admin').show();
        else
            $('.organization_admin').hide();

        setAvatarImg(user.sex);

        setupLogging(user.id, 'Game');

        loadQuestionsAndAnswers();


    });
}

/** Sets up the profile info of the current user. **/
function setProfile() {
    $('input').val('');
    $('textarea').val('');
    $('#usernameInput').val(user.username);
    $('#name').val(user.name);
    $('#sex').val(user.sex).selectmenu('refresh');
    $('#age').val(user.age);
    $('#email').val(user.email);
    appendOrganizations($('#organization'), user.organization);

}

/**
 * Gets organizational strings.
 * @param {type} id
 * @returns {undefined}
 */
function getOrganizationStrings(id) {
    $.get("php/getOrganizationStrings.php",{ uid: user.id, id: id, lang: defaultLang }, function(data) {
        if(data.allPlayersLabel)
            allPlayersLabel = data.allPlayersLabel;
        if(data.organizationLabel)
            organizationLabel = data.organizationLabel;
    });
}

/** Updates changed value on profile. **/
function updateValue() {
    user[$(this).attr('id')] = $(this).val().trim();
}

/** Sets the backgroung image based on sex. **/
function setAvatarImg(sex) {
    var profile_complete = checkProfileComplete();
    //if(user.sex === 'm') {
    if(sex === 'm') {
        $('#profile img').attr('src', (profile_complete)?'img/icons/user_man.png':'img/icons/user_man_active.png');
        $('#profileImg img').attr('src', 'img/icons/user_man2.png');
    } else if (sex === 'f'){
        $('#profile img').attr('src', (profile_complete)?'img/icons/user_female.png':'img/icons/user_female_active.png');
        $('#profileImg img').attr('src', 'img/icons/user_female2.png');
    } else {
        $('#profile img').attr('src', (profile_complete)?'img/icons/user_undefined.png':'img/icons/user_undefined_active.png');
        $('#profileImg img').attr('src', 'img/icons/user_undefined_profile.png');
    }

    if(!profile_complete && $('.profile_alert').length === 0) {
        $('#profileInfo').prepend('<div class="profile_alert"><button data-shadow="false" data-inline="true" data-corners="false" data-enhanced="true" class="close ui-btn ui-btn-inline">×</button>\n\
                                <p>Profiilisi on keskeneräinen, ole hyvä ja täydennä puuttuvat tiedot.</p></div>');
    } else {
        $('.profile_alert').remove();
    }
}

/**
 * Checks whether user has made any changes to profile.
 * @returns {Boolean}
 */
function checkProfileComplete() {
    if(user.username && user.username.trim().length === 0)
        return false;
    if(user.name && user.name.trim().length === 0)
        return false;
    if(user.sex && user.sex.length === 0)
        return false;
    if(user.age && user.age.length === 0)
        return false;
    if(user.email && user.email.trim().length === 0)
        return false;

    return true;
}

/**
 * Lists organizations as options in the provided select input.
 * @param {Object} select
 * @param {Number} org
 */
function appendOrganizations(select, org) {
    if(!org)
        org = 0;
    select.empty();
    if(use_database) {
        $.get("php/getorganizations.php", function(data) {
            select.append("<option value='0'>- Valitse organisaatio -</option>");
            $.each(data, function(key, val) {
                //if(val.isreal === 't')
                select.append("<option value='"+val.id+"'>"+val.name+"</option>");
                if(val.id === org) {
                    setBoardInfo(val);
                    if(val.isreal === 'f') {
                        $('#reset_game').removeClass('testing_mode');
                    }
                }
            });
            select.val(org).selectmenu('refresh');
        });
    } else {
        select.append("<option value='0'>- Valitse organisaatio -</option>");
        select.append("<option value='1'>Organisaatio</option>");
        select.val(org).selectmenu('refresh');
    }
}

/**
 * Saves changes user has made to profile.
 */
function updateUser() {
    var username = $('#usernameInput').val().trim();
    var name = $('#name').val().trim();
    var sex = $('#sex').val();
    var age = $('#age').val().trim();
    var org = $('#organization').val();
    var email = $('#email').val().trim();
    var ok = true;

    $('#playerModal .error').remove();

    if(username.length === 0) {
        addErrorToInput($('#usernameInput'), '* Käyttäjätunnus on pakollinen tieto!');
        $('#usernameInput').val(user.username);
        ok = false;
    }
    if(name.length === 0) {
        addErrorToInput($('#name'), '* Nimi on pakollinen tieto!');
        $('#name').val(user.name);
        ok = false;
    }
    if(email.length === 0) {
        addErrorToInput($('#email'), '* Sähköposti on pakollinen tieto!');
        $('#email').val(user.email);
        ok = false;
    }

    if( (age !== '' && (+age!==parseInt(age))) || !$('#age')[0].validity.valid ) {
        addErrorToInput($('#age'), '* Täytyy olla numero!');
        $('#age').val(user.age);
        ok = false;
    }

    if(checkForUnsavedChanges()) { // Check if user changed anything...
        setAvatarImg(sex);

        if(ok && use_database) {
            var userdata = {identifier: user.id, loginname: username, name: name, gid: org, email: email,
                        attributes: '{"gender": "'+ sex + '", "age": ' + ((age)? parseInt(age):null) + '}' };

            $.post("php/updateuser.php", userdata, function(data) {
                user.username = username;
                user.name = name;
                user.email = email;
                user.sex = sex;
                user.organization = org;
                user.age = age;

                $('#profileInfo .alert').remove();
                $('#profileInfo').prepend('<div class="alert alert-success"><p><img src="img/ok.gif" alt="OK" /> Tiedot tallennettu!</p></div>');
                window.scrollTo(0, 0);

            },'text').fail(function(data) {
                // Show fail message
                $('#profileInfo .alert').remove();
                $('#profileInfo').prepend('<div class="alert alert-error">'+data.responseText+'</div>');
                window.scrollTo(0, 0);
            });

        } else if(!use_database) {
            $('#profileInfo .alert').remove();
            $('#profileInfo').first('<div class="alert alert-success"><p><img src="img/ok.gif" alt="OK" /> Tiedot tallennettu!</p></div>');
            window.scrollTo(0, 0);
        }

    } else if(ok) {
        $('#profileInfo .alert').remove();
        $('#profileInfo').prepend('<div class="alert alert-success"><p><img src="img/ok.gif" alt="OK" /> Tiedot tallennettu!</p></div>');
        window.scrollTo(0, 0);
    }
}

/**
 * Checks if user has made any changes to profile.
 * @returns {Boolean}
 */
function checkForUnsavedChanges() {
    var username = $('#usernameInput').val();
    var name = $('#name').val();
    var sex = $('#sex').val();
    var age = $('#age').val();
    var email = $('#email').val();
    var changes = false;

    if(user!== null && (username !== user.username || name !== user.name || sex !== user.sex || age != user.age || email !== user.email))
        changes = true;

    return changes;
}

/**
 * A helper function to add an error message to input label.
 * @param {Object} input
 * @param {String} text
 */
function addErrorToInput(input, text) {
    if(text)
        input.closest('.form-group').find('label').append(' <span class="error">'+text+'</span>');
    else
        input.closest('.form-group').find('label').append(' <span class="error">* pakollinen tieto!</span>');
    input.one('change', function() {
        input.closest('.form-group').find('.error').remove();
    });
}

/**
 * Called when user wants to update their password.
 * @param {Object} e
 */
function updatePwd(e) {
    e.preventDefault();
    var username = user.username;
    var old_pwd = $('#oldPwd').val().trim();
    var new_pwd = $('#inputPasswordNew').val().trim();
    var pwd_check = $('#inputPasswordCheckNew').val().trim();
    var ok = true;
    var dialog = $('#changePassword .change_password');
    dialog.find('.text-error, .error').remove();
    dialog.find('.alert').remove();

    if(old_pwd.length === 0) {
        addErrorToInput(dialog.find('#oldPwd'), '* Vanha salasana on pakollinen tieto!');
        ok = false;
    }
    if(new_pwd.length === 0) {
        addErrorToInput(dialog.find('#inputPasswordNew'), '* Salasana on pakollinen tieto!');
        ok = false;
    }
    if(pwd_check.length === 0) {
        addErrorToInput(dialog.find('#inputPasswordCheckNew'), '* Salasanan tarkistus on pakollinen tieto!');
        ok = false;
    }
    if(new_pwd !== pwd_check) {
        dialog.prepend('<div class="alert alert-error">Salasana ja salasanan tarkistus eivät täsmää!</div>');
        ok = false;
    }

    if(ok) {
        var userdata = {"username": username, "old_pwd": old_pwd, "new_pwd": new_pwd, "new_pwd_check": pwd_check };

        $.post("php/updatePassword.php", userdata, function(data) {
            $('#updatePassword').before('<div class="alert alert-success"><p><img src="img/ok.gif" alt="OK" /> Salasana vaihdettu!</p></div>');
            dialog.find("#oldPwd").val("");
            dialog.find("#inputPasswordNew").val("");
            dialog.find('#inputPasswordCheckNew').val("");
            dialog.find('#username').val("");
            $(document).one('click', function() {
                $('#playerModal .alert').remove();
            });

        },'text').fail(function(data) {
            // Show fail message
            dialog.prepend('<div class="alert alert-danger">'+data.responseText+'</div>');
            dialog.find("#oldPwd").val("");
            dialog.find("#inputPasswordNew").val("");
            dialog.find('#inputPasswordCheckNew').val("");
            dialog.find('#username').val("");
        });
        $(document).one('click', function() {
            dialog.find('.alert').remove();
        });
    }
}

/**
 * Log out.
 * @returns {Boolean}
 */
function logOut() {
    $.post('php/login.php?action=logout', function(data) {
        if(data === 'success') {
            window.location.href = base_url + "login.html";
        } else
            alert('Virhe uloskirjautumisessa!');
    });
    return false;
}

/**
 * Sets boarmember info for the user. Defines whether board tasks are shown.
 * @param {Object} org
 */
function setBoardInfo(org) {
    // Get usergroup information, if use_boards === true, check if user part of a board,
    if(org.use_boards === 't') {
        if(user.boardid || user.boardid === 0)
            user.boardmember = true;
        else
            user.boardmember = false;
    } else { // else display board tasks to all
        user.boardmember = true;
    }
}

/**
 * Gets replayids for main game and questiongroups.
 */
function loadQuestionsAndAnswers() {
    $.getJSON('php/getReplayids.php?uid='+ user.id, function(data) {
        replayids = data;
        loadQuestions();
        getAnswerStatistics();
        getAllAnswers();
        if(user.boardid || user.boardid === 0)
            getBoardResults();
        startGame();
    });
}
