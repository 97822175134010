/**
 * @file Handles logging.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var logging_on = true;
var version = "3.0";
var application = "BosSi";
var logAddress = "php/ilMediator.php";

/**
 * Setup logging component.
 * @param {String} username
 * @param {String} instance
 */
function setupLogging( username, instance ) {
    var name = (username) ? username : 'demo';
    if(!instance)
        instance = '1';
    Palmu.setLoggingServer(logAddress);
    Palmu.setScriptPath("");
    Palmu.checkConnection();

    // Confiqure logging component (user, app, instance, version)
    Palmu.configure(name, application, instance, version);

}

/**
 * Function to save logdata.
 * @param {Object} logData
 */
function LogData(logData) {
    var view = $('.ui-page-active').attr('id');
    var popup = $('.ui-popup-active').attr('id');

    if(popup) {
        if(!logData.context)
            logData.context = {};
        logData.context.popup = popup;
    }

    var data = new Palmu.LogEntry();
        data.feature = logData.feature;
        data.value = logData.value;
        data.parameters = logData.parameters;
        data.view = view;
        data.level = logData.level;
        data.context = logData.context;

    if(logging_on)
        Palmu.log(data);
}

/**
 * Check if previous listener attached to event -> remove old listener.
 * @param {Object} element
 * @param {String} event
 */
function chechForExistingListener( element, event) {
    var events = $._data(element[0], 'events');
    if(events && events[event]) {
        element.off(event); // Remove previous listener
    }
}

/**
 * Register all static ui-elements (window, tabs, panels, buttons (add event, search), modal buttons + inputs, zoom, search sequences )
 * Must be done before binding any other event listeners! addLogEventListener overrides previously set listeners with same event.
 */
function registerStaticLogEventListeners() {
    // Window focus, blur, scroll and resize
    var window_data = {
        view: 'Window',
        level: Palmu.LogLevel.INTERACTION
    };
    $(window).focus( function() {
        window_data.feature = 'Window focus';
        LogData(window_data);
    });
    $(window).blur( function() {
        window_data.feature = 'Window blur';
        LogData(window_data);
    });

    // Buttons - Default to make sure all are bound
    $('button').each(function () {
        var data = {};
        data.value = $(this).html();
        data.level = Palmu.LogLevel.INTERACTION;
        if($(this).attr('id'))
            data.parameters = { "element_id": $(this).attr('id')};
        chechForExistingListener($(this), 'click');
        chechForExistingListener($(this), 'mouseover');
        chechForExistingListener($(this), 'mouseout');

        // Register event listener
        $(this).on('click', function() {
          data.feature = 'Button click';
          LogData(data);
        });
        $(this).on('mouseover', function() {
          data.feature = 'Button mouseover';
          LogData(data);
        });
        $(this).on('mouseout', function() {
          data.feature = 'Button mouseout';
          LogData(data);
        });
    });

    // Links and tab-links
    $('a').not('.ui-tabs-anchor').each( function() {
        var data = {};
        data.value = $(this).attr('href');
        data.level = Palmu.LogLevel.INTERACTION;
        data.parameters = {};
        if($(this).attr('id'))
            data.parameters.element_id = $(this).attr('id');
        chechForExistingListener($(this), 'click');
        chechForExistingListener($(this), 'mouseover');
        chechForExistingListener($(this), 'mouseout');

        // Register event listener
        $(this).on('click', function() {
          data.feature = 'Link click';
          LogData(data);
        });
        $(this).on('mouseover', function() {
          data.feature = 'Link mouseover';
          LogData(data);
        });
        $(this).on('mouseout', function() {
          data.feature = 'Link mouseout';
          LogData(data);
        });
    });
    $('a.ui-tabs-anchor').each(function () {
        var data = {};
        data.value = $(this).attr('href');
        data.level = Palmu.LogLevel.INTERACTION;
        data.parameters = {};
        if($(this).attr('id'))
            data.parameters.element_id = $(this).attr('id');

        // Register event listener
        $(this).on('click', function() {
            data.feature = 'Switch tab click';
            LogData(data);
        });
        $(this).on('mouseover', function() {
          data.feature = 'Switch tab mouseover';
          LogData(data);
        });
        $(this).on('mouseout', function() {
          data.feature = 'Switch tab mouseout';
          LogData(data);
        });
    });

    $('.close_fullscreen').each(function () {
        var data = {};
        data.value = 'Close modal';
        data.level = Palmu.LogLevel.INTERACTION;
        chechForExistingListener($(this), 'click');
        chechForExistingListener($(this), 'mouseover');
        chechForExistingListener($(this), 'mouseout');

        // Register event listener
        $(this).on('click', function() {
          data.feature = 'Close fullscreen click';
          LogData(data);
        });
        $(this).on('mouseover', function() {
          data.feature = 'Close fullscreen mouseover';
          LogData(data);
        });
        $(this).on('mouseout', function() {
          data.feature = 'Close fullscreen mouseout';
          LogData(data);
        });
    });


    // Inputs (focusin, focusout, change)
    $('input, select, textarea').each(function () {
        var data = {"parameters": {}};
        data.level = Palmu.LogLevel.INTERACTION;
        if($(this).attr('id')) {
            data.value = $(this).attr('id');
            data.parameters.element_id = $(this).attr('id');
        }
        data.parameters.type = $(this).attr('type');
        chechForExistingListener($(this), 'focusin');
        // Register event listener
        $(this).on('focusin', function() {
            data.feature = 'Input focusin';
            if(data.parameters.type !== 'password')
                data.parameters.value = $(this).val();
            LogData(data);
        });

        chechForExistingListener($(this), 'focusout');
        // Register event listener
        $(this).on('focusout', function() {
            data.feature = 'Input focusout';
            if(data.parameters.type !== 'password')
                data.parameters.value = $(this).val();
            LogData(data);
        });

        chechForExistingListener($(this), 'change');
        // Register event listener
        $(this).on('change', function() {
            data.feature = 'Input change';
            if(data.parameters.type !== 'password')
                data.parameters.value = $(this).val();
            LogData(data);
        });
    });
}

/**
 * Adds logging to questionnaire questions on show.
 * @param {Object} question
 * @param {Object} container
 */
function addLoggingToOldQuestions(question, container) {
    if(!container)
        container = $('#question-content .question-content');

    container.find('button').each(function () {
        var data = {"parameters": { "questionid": question.questionid}};
        data.feature = 'Button click';
        data.value = $(this).html();
        data.level = Palmu.LogLevel.INTERACTION;
        if($(this).attr('id'))
            data.parameters.element_id = $(this).attr('id');
        chechForExistingListener($(this), 'click');
        chechForExistingListener($(this), 'mouseover');
        chechForExistingListener($(this), 'mouseout');

        // Register event listener
        $(this).on('click', function() {
            data.feature = 'Button click';
            LogData(data);
        });
        $(this).on('mouseover', function() {
            data.feature = 'Button mouseover';
            LogData(data);
        });
        $(this).on('mouseout', function() {
            data.feature = 'Button mouseout';
            LogData(data);
        });
    });

    container.find('input, select, textarea').each(function () {
        var data = {"parameters": { "questionid": question.questionid}};
        data.level = Palmu.LogLevel.INTERACTION;
        if($(this).attr('id')) {
            data.value = $(this).attr('id');
            data.parameters.element_id = $(this).attr('id');
        }
        data.parameters.type = $(this).attr('type');
        chechForExistingListener($(this), 'focusin');
        // Register event listener
        $(this).on('focusin', function() {
            data.feature = 'Input focusin';
            data.parameters.value = $(this).val();
            LogData(data);
        });

        chechForExistingListener($(this), 'focusout');
        // Register event listener
        $(this).on('focusout', function() {
            data.feature = 'Input focusout';
            data.parameters.value = $(this).val();
            LogData(data);
        });

        chechForExistingListener($(this), 'change');
        // Register event listener
        $(this).on('change', function() {
            data.feature = 'Input change';
            data.parameters.value = $(this).val();
            LogData(data);
        });
    });
}

/**
 * Adds logging to graph tabs.
 * @param {Object} container
 */
function addLoggingToGraphTabs(container) {
    container.find('a.ui-tabs-anchor').each(function () {
        var data = {};
        data.value = $(this).attr('href');
        data.level = Palmu.LogLevel.INTERACTION;
        data.parameters = {};
        if($(this).attr('id'))
            data.parameters.element_id = $(this).attr('id');
        // Register event listener
        $(this).on('click', function() {
            data.feature = 'Switch tab click';
            LogData(data);
        });
        $(this).on('mouseover', function() {
            data.feature = 'Switch tab mouseover';
            LogData(data);
        });
        $(this).on('mouseout', function() {
            data.feature = 'Switch tab mouseout';
            LogData(data);
        });
    });
}
