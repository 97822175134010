/*jshint multistr: true, laxbreak: true */

/**
 * @file General graph related functions and variables that are used both on game and admin sites.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var graph_funcs = [];
var userColor1 = '#8481af';
var userColor2 = '#736b9d'; //#f47171
var organizationColor1 = '#f4c440';
var organizationColor2 = '#efb41b'; // #71f471
var allplayersColor1 = '#36a37c';
var allplayersColor2 = '#278b6d'; // #7070f3
var boardColor = '#8683b0';
var max_width = 650;
var max_height = 450;
var answerLimit = 3;
var fullscreenGraph;
var allPlayersLabel = 'Kaikki pelaajat';
var organizationLabel = 'Oma organisaatio';

/** Redraws visible graph on resize if necessary.  **/
function resizeGraph() {
    var page = $( "body" ).pagecontainer( "getActivePage" );
    var chartWidth = page.width();
    var graph_canvas =  $('.chart')[0];

    if(graph_canvas.width !== Math.round(chartWidth * 0.9)) { // Check if we really want to resize...
          redoGraphs();
    }
}

/**
 * Recreates all the graphs added into graph_funcs.
 */
function redoGraphs() {
    var funcs = clearGraphInfo();
    for(var i=0;i<funcs.length;i++) {
        funcs[i].call();
    }

    if(fullscreenGraph && $('#fullscreen').is(':visible'))
        fullscreenGraph.call();
}

/** Clears graph data ang graph_funcs variable. Should be done after deleting a graph / before creating a new one.
 *
 * @returns {clearGraphInfo.graph_funcs|Array} copy of the graph_funcs variable
 */
function clearGraphInfo() {
    RGraph.ObjectRegistry.Clear();
    $('.RGraph_tooltip').remove();
    var funcs = graph_funcs;
    graph_funcs = [];
    return funcs;
}

/**
 * A helper funtion to check chart width.
 * @param {Number} chartWidth
 * @returns {Number}
 */
function calculateChartWidth(chartWidth) {
    if(chartWidth < 260)
        chartWidth = 260;
    else if(chartWidth > max_width)
        chartWidth = max_width;
    return chartWidth;
}

/**
 * A helper function to check chart height.
 * @param {Number} chartHeight
 * @returns {Number}
 */
function calculateChartHeight(chartHeight) {
    if(chartHeight < 100)
        chartHeight = 100;
    else if(chartHeight > max_height)
        chartHeight = max_height;
    return chartHeight;
}

/**
 * Created the canvas for a graph.
 *
 * @param {Object} container Container where to add the canvas
 * @param {String} type Type of the graph
 * @param {Object} graph_json The JSON data needed to create the graph
 * @param {Array} colors Color for the key labels
 * @param {Array} key_labels
 * @param {String} chartId Id for the chart
 * @param {Number} cWidth Canvas width
 * @param {Number} cHeight Canvas height
 * @returns {String} chart id
 */
function appendChartCanvas(container, type, graph_json, colors, key_labels, chartId, cWidth, cHeight ) {
    var page = $( "body" ).pagecontainer( "getActivePage" ).attr('id');
    var chartWidth = (cWidth > 0) ? cWidth : $('#'+page+' .pageWidth').width() * 0.9;
    var chartHeight = (cHeight > 0) ? cHeight : chartWidth * 0.6;
    var popup_visible = false;
    var id = (chartId)?chartId:'chart' + new Date().getTime();
    var popup_id;

    graph_json.id = id;
    if( document.getElementById("fullscreen-screen") && $('#fullscreen-screen').is(':visible') ) {
        popup_visible = true;
        popup_id = $('#fullscreen-screen canvas').attr('id');
        $('#fullscreen').popup('close');
    }
//    chartWidth = calculateChartWidth(chartWidth);
//    chartHeight = calculateChartHeight(chartHeight);

    container.find('.graphs, .graph_container, #open_popup').remove();
    container.append('<div class="graph_container"><a class="open_popup"><div><canvas id="'+id+'" class="chart" width="'+chartWidth+'" \n\
                            height="'+chartHeight+'"></canvas><div class="fullscreen_img"></div></div></a></div>');
    container.find('.open_popup').on('click', toggleFullscreen);

    $('#'+id).parent().parent().click( function() {
        createFullscreenGraph(type, graph_json, colors, key_labels);
        var data = {"parameters": {}};
        data.feature = 'Graph to fullscreen';
        //data.value = id;
        data.level = Palmu.LogLevel.INTERACTION;
      //  data.parameters.graph_json = graph_json;
        data.parameters.type = type;
        data.parameters.graphId = id;
        LogData(data);
    });
    if(popup_visible === true && popup_id === id ) {
        $('#'+id).parent().parent().click();
    }
    return id;
}

/**
 * Creates the fullscreen graph on click.
 * TODO: Keep the ratio of the graphs!
 * @param {String} type
 * @param {Object} graph_json
 * @param {Array} colors
 * @param {Array} key_labels
 */
function createFullscreenGraph(type, graph_json, colors, key_labels) {
    var height = ($(window).height() - 20);
    var width = ($(window).width()- 30);
    if(type !== 'radar') {
        var gutter = calculateGutter(graph_json.options.labels, width, ((graph_json.options.hmargin)?graph_json.options.hmargin:0), ((graph_json.options['gutter.left'])?graph_json.options['gutter.left']:50) );
        graph_json.options['gutter.bottom'] = gutter;
        graph_json.options['text.angle'] = ((gutter > 25) ? 90 : 0);
        if((gutter > 25))
            height += gutter;
    }
    graph_json.id = 'fullscreen_chart';
    $('#fullscreen canvas').remove();
    $('#fullscreen').html('<div class="close_fullscreen"></div>\n\
                            <canvas id="fullscreen_chart" width="'+width+'" height="'+height+'"></canvas>');
    var fullscreen;
    if(type === 'radar') {
        var index = graph_json.options.labels.length / 2 / 2;
        var gutter_left = RGraph.measureText(graph_json.options.labels[graph_json.options.labels.length-index], false, 'Arial', 10)[0] + 40;
        var gutter_right = RGraph.measureText(graph_json.options.labels[index], false, 'Arial', 10)[0] + 40;

        if(width < (gutter_left + gutter_right + 40)) {
            width = (gutter_left + gutter_right + 40);
            $('#fullscreen canvas').attr('width', width);
        }
        if(height < 100) {
            height = 100;
            $('#fullscreen canvas').attr('height', height);
        }
        graph_json.options['gutter.left'] = gutter_left;
        graph_json.options['gutter.right'] = gutter_right;
        fullscreen = new RGraph.Radar(graph_json).draw();

        // Check if quinn and add tooltips
        if(graph_json.options.labels[0] === 'Uudistaja') {
            createQuinnTooltips(fullscreen, 'fullscreen_chart');
        }
    } else if(type === 'line')
        fullscreen = new RGraph.Line(graph_json).draw();
    else if(type === 'bar')
        fullscreen = new RGraph.Bar(graph_json).draw();
    else if(type === 'hbar')
        fullscreen = new RGraph.HBar(graph_json).draw();

    // Add questions if included
    if(graph_json.question_labels) {
        var list = $('<ol class="question_labels"></ol>');
        var div = $('<div class="question_labels_container"></div>');
        for(var j=0;j<graph_json.question_labels.length;j++) {
            list.append('<li value="'+graph_json.options.labels[j]+'">'+graph_json.question_labels[j]+'</li>');
        }
        div.append(list);
        $('#fullscreen').prepend(div);
    }
    addKeys(colors, key_labels, fullscreen, $('#fullscreen'), 'keys_full');

    $('.fullscreen .close_fullscreen').click(toggleFullscreen);

    // Set top based on scroll height!
    $('#fullscreen').css('top', $(document).scrollTop() + 5);

    // Save current fullscreen graph to var fullscreenGraph;
    fullscreenGraph = wrapFunction(createFullscreenGraph, this, [type, graph_json, colors, key_labels]);


    $('#fullscreen .close_fullscreen').click( function() {
        var data = {};
            data.feature = 'Button click';
            data.value = 'Close fullscreen graph';
            data.level = Palmu.LogLevel.INTERACTION;
        LogData(data);
    });

}

/**
 * Displays / hides the custom fullscreen popup.
 */
function toggleFullscreen() {
    $('#fullscreen').toggle();
    $('.background').toggle();
}

/**
 * Adds keys to a chart.
 *
 * @param {Array} colors
 * @param {Array} labels
 * @param {Object} chart
 * @param {Object} container
 * @param {String} keysid
 */
function addKeys(colors, labels, chart, container, keysid) {
//    console.log('add keys');
    $('#'+keysid).remove();
    container.prepend('<div id="'+keysid+'" class="keys"></div>');
    var key = RGraph.HTML.Key(keysid, {
                            'colors': colors,
                            'labels': labels
                           });
    $('#'+keysid+' tr').click(function() {
        var i = $(this).parent().children().index(this);
        RGraph.clear(chart.canvas);
        RGraph.redrawCanvas(chart.canvas);
        chart.interactiveKeyHighlight(i);

        var data = {};
        data.feature = 'Select key';
        data.value = $(this).find('span').html();
        data.level = Palmu.LogLevel.INTERACTION;
        LogData(data);
    });
}

// Function wrapping code.
// fn - reference to function.
// context - what you want "this" to be.
// params - array of parameters to pass to function.
//http://stackoverflow.com/questions/899102/how-do-i-store-javascript-functions-in-a-queue-for-them-to-be-executed-eventuall
var wrapFunction = function(fn, context, params) {
    return function() {
        fn.apply(context, params);
    };
};

/**
 * Creates tooltips for a graph based on answer data.
 * @param {Array} data
 * @param {String} text Text to be added to the tooltips
 * @returns {Array|createTooltips.tooltips}
 */
function createTooltips(data, text) {
    var tooltips = [];
    for(var i=0;i<data.length;i++) {
        for(var j=0;j<data[i].length;j++) {
            if(typeof data[i][j] !== 'undefined') {
                if(text)
                    tooltips.push(data[i][j].toString()+ text);
                else
                    tooltips.push(data[i][j].toString());
            }
        }
    }
    return tooltips;
}

/**
 * Calculates the needed size for a gutter based on the lenght of labels.
 * @param {Array} labels
 * @param {Number} chartwidth Width of the chart
 * @param {Number} hmargin Horizontal margin (in pixels) of the chart
 * @param {Number} lgutter Left gutter
 * @returns {Number} gutter size in pixels
 */
function calculateGutter(labels, chartwidth, hmargin, lgutter ) {
    var max_length = 0;
    var gutter_bottom = 25;
    var labels_length = 0;
    var size;
    var bar_width = (chartwidth - lgutter - 80) / labels.length;
    if(hmargin.self)
        hmargin = hmargin.self;
    for(var i=0; i<labels.length;i++) {
        size = RGraph.measureText(labels[i].toString(), false, 'Arial', 10);
        max_length = Math.max(max_length, size[0]);
        labels_length += (size[0] + hmargin);
    }

    if(labels_length > (chartwidth - lgutter - 80) || max_length > bar_width)
        gutter_bottom = max_length + 10;

    return gutter_bottom;

}

/**
 * Calculates the needed gutter space for radar charts.
 * @param {Array} labels
 * @returns {Number} Size of gutter in pixels
 */
function calculateRadarGutters(labels) {
    var max_length = 0;
    var size;

    for(var i=0; i<labels.length;i++) {
        size = RGraph.measureText(labels[i], false, 'Arial', 10);
        max_length = Math.max(max_length, size[0]);
    }

    return max_length + 40;
}

/**
 * Creates the custom tooltips for Johtamisprofiili.
 * @param {Object} radar
 * @param {String} chartId
 */
function createQuinnTooltips(radar, chartId) {
    var quinn_tooltips = [
                            '<div class="quinn_tooltip"><b>Uudistaja</b> on innokas uusien asioiden kehittäjä ja muutoksessa aktiivisesti mukana oleva johtaja, joka etsii uusia toimintatapoja yhdessä työntekijöiden kanssa</div>',
                            '<div class="quinn_tooltip"><b>Neuvottelija</b> toimii aktiivisesti kaikenlaisissa verkostoissa hoitaen projekteja, yhteisiä suunnitelmia ja pr-työtä</div>',
                            '<div class="quinn_tooltip"><b>Tuottaja</b> varmistaa, että yksiköissä syntyy tuloksia ja asetetut tavoitteet saavutetaan yhdessä henkilöstön kanssa</div>',
                            '<div class="quinn_tooltip"><b>Tavoitejohtaja</b> asettaa vastuualueelleen tavoitteita sekä määrittää työntekijöiden vastuita ja työnjakoa</div>',
                            '<div class="quinn_tooltip"><b>Koordinoija</b> yhteensovittaa vastuualueen erilaisia tavoitteita ja toimintakäytäntöjä</div>',
                            '<div class="quinn_tooltip"><b>Valvoja</b> seuraa erilaisten mittareiden kautta vastuualueen tavoitteiden ja tulosten tasapainoa ja työn laatua</div>',
                            '<div class="quinn_tooltip"><b>Edistäjä</b> motivoi ja kannustaa henkilökuntaa ja tukee kaikin tavoin yhdessä tekemistä</div>',
                            '<div class="quinn_tooltip"><b>Opastaja</b> kuuntelee ja keskustelee työntekijöiden kanssa ratkoen yhdessä heidän kanssaan työhön liittyviä ongelmia</div>'
                        ];
    var coordsText = radar.coordsText;
    for(var i=0;i<coordsText.length;i++) {
        var x = radar.coordsText[i].x;
        var y = radar.coordsText[i].y;
        var heigth = radar.coordsText[i].height;
        var width = radar.coordsText[i].width;
        var rect = new RGraph.Drawing.Rect({
            id: chartId,
            x: x,
            y: y,
            width: width,
            height: heigth,
            options: {
                fillstyle: 'rgba(255,0,0,0.0)',
                shadow: false,
                tooltips: [quinn_tooltips[i]],
                'tooltips.event': 'onmousemove',
                'tooltips.highlight': false
            }
        }).draw();
        rect.onmousemove = function (e, shape)
        {
            e.target.style.cursor = 'pointer';
        };
        rect.onclick = function (e, shape)
        {
            e.preventDefault();
        };
    }
}

/**
 * Sorts an array of objects based on value.
 * @param {type} data
 * @returns {Array} Sorted array
 */
function sortCheckboxAnswers(data) {
    //console.log('sortCheckboxAnswers');
    var sortedArr = [];
    $.each(data, function(key, value) {
        sortedArr.push({"option":key, "value": value});
    });

    sortedArr = sortedArr.sort(function(a, b){
        return b.value-a.value;
    });
    return sortedArr;
}

/**
 * Counts how many times each checkbox option has been seleceted.
 * @param {Int} questionId
 * @returns {Object} Answer counts for all players and organization
 */
function countCheckboxAnswers(questionId) {
    var checkboxAnswers = {"all": {}, "org":{}};
    var i, j;
    if(answerstats.all[questionId]) {
        var allAnswers = answerstats.all[questionId];
        for(i = 0;i<allAnswers.answercount; i++) {
            if(allAnswers[i]) {
                for(j = 0;j<allAnswers[i].length; j++) {
                    if(!checkboxAnswers.all[allAnswers[i][j]])
                        checkboxAnswers.all[allAnswers[i][j]] = 1;
                    else
                        checkboxAnswers.all[allAnswers[i][j]] += 1;
                }
            }
        }
    }
    if(answerstats.usergroup[questionId]) {
        var orgAnswers = answerstats.usergroup[questionId];
        for(i = 0;i<orgAnswers.answercount; i++) {
            if(orgAnswers[i]) {
                for(j = 0;j<orgAnswers[i].length; j++) {
                    if(!checkboxAnswers.all[orgAnswers[i][j]])
                        checkboxAnswers.all[orgAnswers[i][j]] = 1;
                    else
                        checkboxAnswers.all[orgAnswers[i][j]] += 1;
                }
            }
        }
    }
    return checkboxAnswers;
}

/**
 * Checks what type of feedback is needed for a topic and calls the appropriate function.
 *
 * @param {String} questionset
 * @param {String} topic
 * @param {Int} questionId
 * @param {Object} content The DOM element where to add feedback
 * @param {Boolean} main If this is the main feedback for the whole topic (e.g. radar chart)
 * @param {String} errorText
 */
function displayGraphForTopic(questionset, topic, questionId, content, main, errorText) {
//    console.log('displayGraphForTopic');
    var parameters = questions[questionset].questionBatteries[topic].defaultparameters;
    var questionType = (parameters.scale_start)?'slider':getQuestionByID(questionId).type;

    if(parameters.batteryfeedbacktopics && main) {
        createRadarChart(questionset, parameters.batteryfeedbacktopics, content);
    } else if(questionType === 'slider') {
        createBarChartForTopic(questionset, topic, content);
    } else if(questionType === 'radio') {
        createBarChart(questionId, content);
    } else if(questionType === 'task') {
        if(errorText) {
            content.prepend('<div class="alert alert-error">'+errorText+'</div>');
            content.find('.compare_results').remove();
        } else
            content.find('.alert').remove();

        content.find('.question').remove();
        content.find('.ui-collapsible-set').remove();
        createBarChart(questionId, content);
    }
}

/**
 * Adds event handlers for a collapsible (question compare).
 * @param {String} task_id
 */
function appendEventHandlersForCollapsible(task_id) {
    var collapsible = $('#answerList').find('.answer_collapsible').last();
    // Log listeners
    var data = {};
    data.feature = 'Question compare';
    data.value = 'collapsibleexpand';
    data.level = Palmu.LogLevel.INTERACTION;
    data.parameters = {"questionset": $(this).attr('data-questionset'), "questionID":task_id};

    // Register event listener
    collapsible.on('collapsibleexpand', function() {
        LogData(data);

        // Collapse all other answers
        var collapsible = $(this);
        collapsible.parent().parent().find('.answer_collapsible').each(function() {
            if(!$(this).is(collapsible)) {
                if(!$(this).hasClass('ui-collapsible-collapsed')) {
                    collapseCollapsible($(this));
                }
            }
        });
    });

    var data2 = {};
    data2.feature = 'Question results';
    data2.value = 'collapsiblecollapse';
    data2.level = Palmu.LogLevel.INTERACTION;
    data2.parameters = {"questionset": $(this).attr('data-questionset'), "questionID":task_id};
    collapsible.on('collapsiblecollapse', function() {
        LogData(data2);
    });
}

/**
 * A helper function for collapsing a collapsible.
 * @param {Object} collapsible
 */
function collapseCollapsible(collapsible) {
    collapsible.addClass('ui-collapsible-collapsed');
    collapsible.find('.ui-collapsible-heading').addClass('ui-collapsible-heading-collapsed');
    collapsible.find('.ui-collapsible-heading-toggle').addClass('ui-icon-plus').removeClass('ui-icon-minus');
    collapsible.find('.ui-collapsible-content').addClass('ui-collapsible-content-collapsed');
}

/**
 * Gets new questions stats.
 *
 * @param {String} questionid
 * @param {Object} question
 * @param {Boolean} getpercentages
 * @returns {Object} Question stats {allAnswers: allAnswers, organizationAnswers: organizationAnswers}
 */
function getSingleQuestionsStatsNew(questionid, question, getpercentages) {
    if(!getpercentages || getpercentages !== 0) {
        getpercentages = true;
    }
    else {
        getpercentages = false;
    }

    var scalesize = question.TaskDescription.Choice.length - 1;
    var allAnswers = [];
    var organizationAnswers = [];

    var gotallanswersforquestion = true;
    var gotusergroupanswersforquestion = true;
    if($.isEmptyObject(answerstats.all[questionid]))
        gotallanswersforquestion = false;
    if($.isEmptyObject(answerstats.usergroup[questionid]))
        gotusergroupanswersforquestion = false;


    for(var i=0;i<=scalesize;i++) {
        if(gotallanswersforquestion) {
            if(answerstats.all[questionid][i] > 0) {
                if(getpercentages) {
                    all = Math.round(answerstats.all[questionid][i] / answerstats.all[questionid].answercount * 100);
                }
                else {
                    all = answerstats.all[questionid][i];
                }
            }
            else {
                all = 0;
            }
        }
        else
            all = 0;
        if(gotusergroupanswersforquestion) {
            if(answerstats.usergroup[questionid][i] > 0)
                if(getpercentages) {
                    org = Math.round(answerstats.usergroup[questionid][i] / answerstats.usergroup[questionid].answercount * 100);
                }
                else {
                    org = answerstats.usergroup[questionid][i];
                }
            else
                org = 0;
        }
        else
            org = 0;
        allAnswers.push(all);
        organizationAnswers.push(org);
    }
    return {allAnswers: allAnswers, organizationAnswers: organizationAnswers };
}

/**
 * Calculates averages for worktime duration and number/time of brakes.
 *
 * @param {Object} container
 * @param {Number} questionid
 */
function appendWorktimeResults(container, questionid) {
    var all_results = answerstats.all[questionid];
    var org_results = answerstats.usergroup[questionid];

    container.empty();

    // Comparison with all players
    if(all_results && all_results.answercount > answerLimit) {
        var all_div = $('<div class="top_results"></div>');
        all_div.append('<h3>'+allPlayersLabel+':</h3>');
        calculateWorkdayResults(all_div, all_results);
        container.append(all_div);
    }


    // Comparison with organization
    if(org_results && org_results.answercount > answerLimit) {
        var org_div = $('<div class="top_results"></div>');
        org_div .append('<h3>'+organizationLabel+':</h3>');
        calculateWorkdayResults(org_div, all_results);
        container.append(org_div);
    }
}

/**
 * Calculates the averages for ideal start and end of workday, and the amount and time
 * for breaks. Results are added to the given container.
 *
 * @param {Object} container
 * @param {Object} results
 */
function calculateWorkdayResults(container, results) {
    var break_avg_count = 0, break_avg_duration = 0, from_avg, to_avg,
        time, count = 0, break_count = 0, minutes_from = 0, hours_from = 0, minutes_to = 0, hours_to = 0;

    $.each(results, function(i, result) {
        // Only calculate if the user has filled worktime fields (from - to)
        if(i !== 'answercount' && result.from && result.from.length > 0 && result.from && result.from.length > 0) {
            // Count breaks
            if(result.breaks.length > 0) {
                break_avg_count += result.breaks.length;
                for(var j=0;j<result.breaks.length;j++) {
                    if(result.breaks[j].length > 0)
                        break_avg_duration += parseFloat(result.breaks[j]);
                }
                break_count++;
            }

            // Count worktime
            time = parseTime(result.from);
            hours_from  += time.hours;
            minutes_from  += time.minutes;

            time = parseTime(result.to);
            hours_to += time.hours;
            minutes_to += time.minutes;
            count++;
        }
    });

    // Calculate start of workday
    hours_from = Math.round(hours_from / count);
    minutes_from = Math.round(minutes_from / count);
    var from = chechHoursAndMinutes(hours_from, minutes_from);
    hours_from = from.hours;
    minutes_from = from.minutes;
    from_avg = hours_from+'.'+((minutes_from < 10)? '0' + minutes_from : minutes_from);

    // Calculate end of workday
    hours_to = Math.round(hours_to / count);
    minutes_to = Math.round(minutes_to / count);
    var to = chechHoursAndMinutes(hours_to, minutes_to);
    hours_to = to.hours;
    minutes_to = to.minutes;
    to_avg = hours_to+'.'+((minutes_to < 10)? '0' + minutes_to : minutes_to);

    // Append results
    container.append('<p>Keskimääräinen aika aloittaa työt:  ' + from_avg +'</p>');
    container.append('<p>Keskimääräinen aika lopettaa työt: ' + to_avg +'</p>');
    if(break_count > 0) {
        container.append('<p>Keskimääräinen taukojen määrä: ' + parseInt(break_avg_count / break_count) +'</p>');
    } else
        container.append('<p>Keskimääräistä taukojen määrää ei voitu laskea.');

    if(break_avg_count > 0) {
        container.append('<p>Keskimääräinen tauon pituus: ' + parseInt(break_avg_duration / break_avg_count) +' minuuttia</p>');
    } else
        container.append('<p>Keskimääräisen tauon pituutta ei voitu laskea.</p>');
}

/**
 * Handles time inputs and returns hours and minutes.
 *
 * @param {Object} time
 * @returns {Object} {hours: hours, minutes: minutes}
 */
function parseTime(time) {
    var hours = 0, minutes = 0;
    // Check if : used as separator
    if(time.indexOf(":") !== -1) {
        time = time.split(":");
        hours = time[0];
        minutes = time[1];
    } else if(parseInt(time) > 100) {// Check if time > 100
        time = parseInt(time);
        hours = parseInt(time / 100);
        minutes = time - hours * 100;
    } else {
        time = parseFloat(time);
        hours = parseInt(time);
        minutes = (time - hours) * 100;
    }

    return {hours: hours, minutes: minutes};
}

/**
 * Check that hours is not a float, and that minutes is not more than 60.
 * Returns the processed values for hours and minutes.
 *
 * @param {type} hours
 * @param {type} minutes
 * @returns {Object} {hours: hours, minutes: minutes}
 */
function chechHoursAndMinutes(hours, minutes) {
    // Check if minutes need to be added from hours
    if(hours - parseInt(hours) > 0) {
        minutes += (hours - parseInt(hours)) * 60;
        hours = parseInt(hours);
    }

    // See if more than 60 minutes left in minutes_to
    var extra = parseInt(minutes / 60);
    if(extra > 0) {
        hours += extra;
        minutes = (minutes - extra) * 60;
    }
    return {hours: hours, minutes: minutes};
}

/**
 * Helper function for getting a question by id.
 *
 * @param {Number} questionid
 * @returns {Object}
 */
function getQuestionByID(questionid) {
    questionid = parseInt(questionid);
    if(!$.isEmptyObject(questionIndexesById[questionid])) {
        return questions[questionIndexesById[questionid].questiongroup].questionBatteries[questionIndexesById[questionid].questionbattery].questions[questionIndexesById[questionid].questionindex];
    }
    else {
        return null;
    }
}

/**
 * Creates tabs for topic data (all questions listed)
 *
 * @param {Number} tabCount
 * @param {Object} container
 * @param {String} topic
 * @param {Number} questionCount
 */
function createTabs(tabCount, container, topic, questionCount) {
    container.find('.ui-tabs').remove();
    var nav = $('<div class="nav" data-role="navbar"></div>');
    var nav_links = $('<ul class="nav_links"></ul>');
    var tabs = $('<div data-role="tabs"></div');
    var tab_content = '';
    for(var i=0;i<tabCount;i++) {
        $('#'+topic+i).remove(); // Make sure there is no previous tab with same name
        nav_links.append('<li><a href="#'+topic+i+'" data-theme="a" data-ajax="false">Kysymykset '+( i * 5 + 1 )+'-'+(( (i * 5 + 5) > questionCount ) ? questionCount: i * 5 + 5 )+'</a></li>');
        tab_content += '<div id="'+topic+i+'"></div>';
    }
    nav.append(nav_links);
    nav_links.find('li a').first().addClass('ui-btn-active');
    tabs.append(nav);
    tabs.append(tab_content);
    container.append(tabs).trigger('create');
    addLoggingToGraphTabs(container);
}

/**
 * Creates a single array that can be used in a bar graph of the allData, orgData and userAnswers results if available.
 * @param {Array} allData
 * @param {Array} orgData
 * @param {Array} userAnswers
 * @returns {Array}
 */
function toBarGraphArray(allData, orgData, userAnswers) {
    var data = [];
    var insideArray = [];
    for(var i=0;i<allData.length; i++) {
        insideArray.push(allData[i]);
        if(orgData)
            insideArray.push(orgData[i]);
        if(userAnswers)
            insideArray.push(userAnswers[i]);

        data.push(insideArray);
        insideArray = [];
    }
    return data;
}

/**
 * Creates an array of the mean values provided in data array.
 * @param {Array} data
 * @returns {Array}
 */
function toMeanArray( data ) {
    var meanArray = [];
    for(var i=0;i<data.length; i++)
        meanArray.push(data[i].mean);
    return meanArray;
}

/**
 * Returns tooltips for tab data.
 *
 * @param {type} index
 * @param {type} tooltips
 * @returns {Array} tooltips
 */
function getTabTooltips(index, tooltips) {
    var counter = 0;
    var tabTooltips = [];
    index = index * (5*3);
    for(var i=index;i<tooltips.length;i++) {
        tabTooltips.push(tooltips[i]);
        counter++;
        if(counter === (5*3))
            return tabTooltips;
    }
    return tabTooltips;
}

/**
 * Returns data and labels for a tab.
 *
 * @param {Number} index
 * @param {Array} data
 * @param {Array} labels
 * @returns {Object} {"data": tabData, "labels": tabLabels}
 */
function getTabData(index, data, labels) {
    var counter = 0;
    var tabData = [];
    var tabLabels = [];
    index = index * 5;
    for(var i=index;i<data.length;i++) {
        tabData.push(data[i]);
        tabLabels.push(labels[i]);

        counter++;
        if(counter === 5)
            return {"data": tabData, "labels": tabLabels};
    }
    return {"data": tabData, "labels": tabLabels};
}

/**
 * Counts the numbers of answertexts from answerstats.
 * @param {Array} options
 * @param {String|Number} questionid
 * @returns {Array}
 */
function countAnswertextStats(options, questionid) {
    var questionanswerstats = {}, tempindexforanswers;
    var answerdataforall = answerstats.all[questionid];
    var answerdataforgroup = answerstats.usergroup[questionid];
    for(var tempindexforoptions = 0; tempindexforoptions < options.length; tempindexforoptions++) {
        var currentoption = (options[tempindexforoptions].optiontext)? options[tempindexforoptions].optiontext : options[tempindexforoptions];
        questionanswerstats[tempindexforoptions] = [];
        questionanswerstats[tempindexforoptions].option = currentoption;
        var allsum = 0;
        var groupsum = 0;
        if(answerdataforall) {
            for(tempindexforanswers = 0; tempindexforanswers < answerdataforall.answercount; tempindexforanswers++) {
                if(currentoption === answerdataforall[tempindexforanswers]) {
                    allsum++;
                }
            }
            questionanswerstats[tempindexforoptions].allsum = Math.round(allsum / answerdataforall.answercount * 100);
        }

        if(answerdataforgroup) {
            for(tempindexforanswers = 0; tempindexforanswers < answerdataforgroup.answercount; tempindexforanswers++) {
                if(currentoption === answerdataforgroup[tempindexforanswers]) {
                    groupsum++;
                }
            }
            questionanswerstats[tempindexforoptions].groupsum = Math.round(groupsum / answerdataforgroup.answercount * 100);
        }

    }
    return questionanswerstats;
}
