/*jshint multistr: true */

/*
 * @file Includes new graph functions for BosSi.
 *
 * <ul>The supported graph types are:
 *  <li>1. Line chart (createLineChart): Used for the point graphs.</li>
 *  <li>2. Bar graph for the new questions (createBarChartNew): Displays the users
 *     own answer compared to all players and same organization.</li>
 *  <li>3. Radar chart to display the final profile (createGameOverGraph)</li>
 * </ul>
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var dailyStats = null;

/**
 * Creates graphs for wellbeing, efficiency and economy points. TODO: Add friend organization results!
 */
function createPointGraphs() {
    var meter = $(this);
    clearGraphInfo();
    $('.meter_popup .graph_container, .meter_popup .no_answers').remove();

    // Get statistics from server
    $.getJSON("php/get_daily_statistics_for_user.php", {replayid: replayids.main }, function(data) {
        var graph_data = {"wellbeing": [], "efficiency": [], "economy": [], "dates": []}, labels = [], colors = [];
        dailyStats = data;
        if(dailyStats.user.length !== 0) {
            graph_data = sortPointData(graph_data, 'user');
            labels.push('Oma peli');
            colors.push(userColor2);

            if(dailyStats.all.length !== 0) {
                graph_data = sortPointData(graph_data, 'all');
                labels.push(allPlayersLabel);
                colors.push(allplayersColor2);
            }
            if(dailyStats.usergroup.length !== 0) {
                graph_data = sortPointData(graph_data, 'usergroup');
                labels.push(organizationLabel);
                colors.push(organizationColor2);
            }

            if(meter.hasClass('hyvinvointi')) {
                createLineChart($('#hyvinvointiModal .modalContent'),
                        graph_data.wellbeing,
                        colors,
                        graph_data.dates,
                        labels, ' ');
            }
            if(meter.hasClass('tehokkuus')) {
                createLineChart($('#tehokkuusModal .modalContent'),
                            graph_data.efficiency,
                            colors,
                            graph_data.dates,
                            labels, ' ');
            }
            if(meter.hasClass('talous')) {
                createLineChart($('#talousModal .modalContent'),
                            graph_data.economy,
                            colors,
                            graph_data.dates,
                            labels, ' ');
            }

        } else {
            var container;
             if(meter.hasClass('hyvinvointi')) {
                container = $('#hyvinvointiModal .modalContent');
            }
            if(meter.hasClass('tehokkuus')) {
                container = $('#tehokkuusModal .modalContent');
            }
            if(meter.hasClass('talous')) {
                container = $('#talousModal .modalContent');
            }
            container.append('<p class="no_answers">Vertaulutietoa ei ole vielä saatavilla!</p>');
        }

    });

}

/**
 * Sorts point data in a way that it can be used to create the line graph.
 * @param {Object} data
 * @param {String} group
 * @returns {Object}
 */
function sortPointData(data, group) {
    var index = data.wellbeing.length;
    var prev_i = 0;
    data.wellbeing.push([]);
    data.efficiency.push([]);
    data.economy.push([]);
    $.each(dailyStats[group], function(i, date) {
        if(!data.max_date || parseInt(i) <= parseInt(data.max_date)) {
            if(i - prev_i > 1) { // Add missing days in between
                for(var j = (prev_i+1);j<i;j++) {
                    if(jQuery.inArray( j, data.dates ) === -1)
                        data.dates.push(j.toString());
//                    data.wellbeing[index].push(((group === 'user')?
//                        parseInt((data.wellbeing[index][data.wellbeing[index].length-1] + parseInt(date.wellbeing))/2):
//                        parseInt((data.wellbeing[index][data.wellbeing[index].length-1]+ parseInt(date.average_wellbeing))/2)));
//                    data.efficiency[index].push(((group === 'user')?
//                        parseInt((data.efficiency[index][data.efficiency[index].length-1] + parseInt(date.efficiency))/2):
//                        parseInt((data.efficiency[index][data.efficiency[index].length-1]+ parseInt(date.average_efficiency))/2)));
//                    data.economy[index].push(((group === 'user')?
//                        parseInt((data.economy[index][data.economy[index].length-1] + parseInt(date.economy))/2):
//                        parseInt((data.economy[index][data.economy[index].length-1]+ parseInt(date.average_economy))/2)));

                    data.wellbeing[index].push(NaN);
                    data.efficiency[index].push(NaN);
                    data.economy[index].push(NaN);
                }
            }
            if(jQuery.inArray( i, data.dates ) === -1)
                data.dates.push(i);
            data.wellbeing[index].push(parseInt((group === 'user') ? date.wellbeing : date.average_wellbeing));
            data.efficiency[index].push(parseInt((group === 'user') ? date.efficency : date.average_efficency));
            data.economy[index].push(parseInt((group === 'user') ? date.economy : date.average_economy));
        }
        prev_i = parseInt(i);
    });

    if(group === 'user')
        data.max_date = data.dates[data.dates.length -1];

    if(data.dates.length > data.wellbeing[index].length) { // Add missing values to the end
        for(var i=data.wellbeing[index].length;i<data.dates.length;i++) {
            data.wellbeing[index].push(data.wellbeing[index][data.wellbeing[index].length-1]);
            data.efficiency[index].push(data.wellbeing[index].length-1);
            data.economy[index].push(data.wellbeing[index].length-1);
        }
    }

    return data;
}


/**
 * Creates a line chart.
 *
 * @param {Object} container
 * @param {Array} data
 * @param {Array} colors
 * @param {Array} labels
 * @param {Array} key_labels
 * @param {String} y_axis_marker
 * @param {String} graphId
 */
function createLineChart(container, data, colors, labels, key_labels, y_axis_marker, graphId) {
    var tooltips = createTooltips(data, y_axis_marker);
    var y_min = 0, min;

    for(var i=0;i<data.length;i++){
        min = Math.min.apply(null, data[i].filter(function(n) { return !isNaN(n); }));
        if(min < y_min)
            y_min = min;
    }

    var graph_json = {
        'data': data,
        'options': {
            'background.grid': true,
            'colors': colors,
            'tickmarks': 'circle',
            'linewidth': 5,
            'hmargin': 5,
            'units.post': '',
            'gutter.left': 45,
            'labels': labels,
            'filled': false,
            'filled.accumulative': false,
            'fillstyle': [allplayersColor2, organizationColor2],
            'tooltips': tooltips,
            'outofbounds': true,
            "ymin": y_min,
            'textSize': 10
        }
    };
    var chartId = appendChartCanvasNew(container, 'line', graph_json, colors, key_labels, graphId);
    graph_json.id = chartId;
    var line = new RGraph.Line(graph_json).draw();
    addKeys(colors, key_labels, line, container.find('.graph_container'), 'keys'+chartId);

    graph_funcs.push(wrapFunction(createLineChart, this, [container, data, colors, labels, key_labels, y_axis_marker, chartId]));
}

/**
 * Creates the canvas for a new chart.
 *
 * @param {Object} container
 * @param {String} type
 * @param {Object} graph_json
 * @param {Array} colors
 * @param {Array} key_labels
 * @param {String} chartId
 * @param {Int} cWidth
 * @param {Int} cHeight
 * @returns {String} chartId
 */
function appendChartCanvasNew(container, type, graph_json, colors, key_labels, chartId, cWidth, cHeight ) {
    var chartWidth = (cWidth > 0) ? cWidth : container.width() * 0.9;
    var chartHeight = (cHeight > 0) ? cHeight : chartWidth * 0.6;
    var popup_visible = false;
    var id = (chartId)?chartId:'chart' + new Date().getTime();
    var popup_id;
    if( document.getElementById("fullscreen-screen") && $('#fullscreen-screen').is(':visible') ) {
        popup_visible = true;
        popup_id = $('#fullscreen-screen canvas').attr('id');
    }

    container.find('.graphs, .graph_container, #open_popup').remove();
    container.append('<div class="graph_container"><a class="open_popup"><div><canvas id="'+id+'" class="chart" width="'+chartWidth+'" \n\
                            height="'+chartHeight+'"></canvas><div class="fullscreen_img"></div></div></div></div>');
    container.find('.open_popup').on('click', toggleFullscreen);
    $('#'+id).parent().parent().click( function() {
        createFullscreenGraph(type, graph_json, colors, key_labels);
        var data = {"parameters": {}};
        data.feature = 'Graph to fullscreen';
        data.value = chartId;
        data.level = Palmu.LogLevel.INTERACTION;
        //data.parameters.graph_json = graph_json;
        data.parameters.type = type;
        LogData(data);
    });
    if(popup_visible === true && popup_id === id ) {
        $('#'+id).parent().parent().click();
    }
    return id;
}

/**
 * Calls the createBarChartNew function.
 */
function showGraphForQuestionNew() {
    var questionID = $(this).find('h4').attr('data-qid');
    var container = $(this).find('.collapsible_content');
    createBarChartNew(questionID, container);

    var data = {};
    data.feature = 'Show graph for question (new)';
    data.value = 'collapsibleexpand';
    data.level = Palmu.LogLevel.INTERACTION;
    data.parameters = { "questionID": questionID};
    LogData(data);

    // Collapse all other graphs
    var collapsible = $(this);
    $('#answerList').find('.compare_button').each(function() {
        if(!$(this).is(collapsible)) {
            if(!$(this).hasClass('ui-collapsible-collapsed')) {
                collapseCollapsible($(this));
            }
        }
    });

}

/**
 * Creates a bar chart. Used in all the new type of questions. TODO: Add friend organization results!
 *
 * @param {String} questionId
 * @param {Object} container
 * @param {String} graphId
 */
function createBarChartNew(questionId, container, graphId) {
    // Get answer
    var question = answers_new[questionId].questiondata;
    var data = [];
    var labels = [];
    var dataOK = true;
    var answerstatsforquestion;
    var answerCount = getAnswerCounts(questionId);
    var val = parseInt(answers_new[questionId].answer_index);
    var abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'];

    // Make sure questiondata is JSON
    if (typeof question == 'string' || question instanceof String)
        question = JSON.parse(question);

    // If answercount for all players is under the answerLimit, dont't show graph
    if(answerCount.all <= answerLimit) {
        noAnswerStats(container);
    } else {
        answerstatsforquestion = getSingleQuestionsStatsNew(questionId, question, true);
        if(answerstatsforquestion && !$.isEmptyObject(answerstatsforquestion)) {
            for(var i = 0; i < question.TaskDescription.Choice.length; i++) {
                labels.push(abc[i]);
                if(answerCount.org > answerLimit)
                    data.push([answerstatsforquestion.allAnswers[i], answerstatsforquestion.organizationAnswers[i]]);
                else
                    data.push([answerstatsforquestion.allAnswers[i]]);
            }
        } else
            dataOK = false;
        if(dataOK) {
            var chartWidth = container.width() * 0.9;
            var hmargin = ((chartWidth < 420 ) ? 3 : 20);
            var tooltips = createTooltips(data, '%');
            var gutter = calculateGutter(labels, chartWidth, hmargin, 50 );
            var chartHeight = (chartWidth * 0.6) +  gutter;
            var colors = [allplayersColor1];
            var key_labels = [allPlayersLabel];

            if(answerCount.org > answerLimit) {
                colors.push(organizationColor1);
                key_labels.push(organizationLabel);
            }
            var graph_json = {
                'data': data,
                'options': {
                    'colors': colors,
                    'linewidth': 1,
                    'hmargin': {
                        self: hmargin,
                        grouped: 0
                    },
                    'labels': labels,
                    'tooltips': tooltips,
                    'shadow': false,
                    'tooltips.event': 'onmousemove',
                    'units.post': '%',
                    'ymax': '100',
                    'gutter.left': 45,
                    'gutter.bottom': gutter,
                    'text.angle': ((gutter > 25) ? 90 : 0),
                    'background.grid.autofit': true,
                    'background.grid.autofit.align': true,
                    'textSize': 10
                }
            };
            chartWidth = calculateChartWidth(chartWidth);
            chartHeight = calculateChartHeight(chartHeight);

            var chartId = appendChartCanvas(container, 'bar', graph_json, colors, key_labels, graphId, chartWidth, (chartHeight + 50) );
            graph_json.id = chartId;
            var bar = new RGraph.Bar(graph_json).Draw();
            if(val || val === 0) {
                var coords;
                if(answerCount.org > answerLimit)
                    coords = bar.coords[val * 2 + 1];
                else
                    coords = bar.coords[val];
                var rectOptions = {
                        id: chartId,
                        x: (answerCount.org > answerLimit) ? coords[0] : coords[0] + coords[2]/2,
                        y: 25,
                        width: 1,
                        height: bar.grapharea,
                        options: {
                            fillstyle: 'rgba(255,0,0,0.25)',
                            strokestyle: 'gray'
                        }
                    };
                var rect1 = new RGraph.Drawing.Rect(rectOptions).draw();
                var x = calculateXForLabel(coords, graphId, 'Sinun vastauksesi');
                var textOptions = {
                       id: chartId,
                       x: x,
                       y: 22,
                       text: 'Sinun vastauksesi',
                       options: {
                           font: 'Arial',
                           halign: 'center',
                           valign: 'bottom',
                           colors: ['gray'],
                           size: 10
                       }
                   };
                var text1 = new RGraph.Drawing.Text(textOptions).draw();
           }

            addKeys(colors, key_labels, bar, container.find('.graph_container'), 'keys'+chartId);
            graph_funcs.push(wrapFunction(createBarChartNew, this, [questionId, container, chartId]));

        } else {
            noAnswerStats(container);
        }
    }
}

/**
 * Called on game over.
 */
function showFinalProfileGraph() {
    $('#gameOverModal').popup('open');
    createGameOverGraph();
}

/**
 * Creates the game over profile graph (radar). TODO: Add friend organization results!
 * @param {String} graphId
 */
function createGameOverGraph(graphId) {
    var container = $('#gameOverGraph');
    container.empty();

    $.get("php/get_quinn_statistics.php", function(res) {

        // Create graph
        var answerCounts = {"all": res.all.count, "org": res.usergroup.count};
        var labels = ['Verkosto-orientoitunut', 'Tavoiteorientoitunut', 'Kontrolloiva', 'Osallistava'];
        var userData = [];
        var orgData = [];
        var allData = [];
        var page_width = $( "body" ).pagecontainer( "getActivePage" ).width();
        var chartWidth = (page_width > 1000) ? 1000 * 0.8 : page_width * 0.8;
        var chartHeight = chartWidth - 240;

        if(chartWidth < 260)
            chartWidth = 260;
        if(chartHeight < 100)
            chartHeight = 100;

        var data = [];
        var key_labels = [];
        var colors = [];

        // Calculate HRM, IPM, OSM and RGM points for user
        var HRM = Math.round(gameStatus.Status.HRM / gameStatus.Status.HRMTotal * 100);
        var IPM = Math.round(gameStatus.Status.IPM / gameStatus.Status.IPMTotal * 100);
        var OSM = Math.round(gameStatus.Status.OSM / gameStatus.Status.OSMTotal * 100);
        var RGM = Math.round(gameStatus.Status.RGM / gameStatus.Status.RGMTotal * 100);
        userData.push(HRM);
        userData.push(IPM);
        userData.push(OSM);
        userData.push(RGM);

        // Calculate HRM, IPM, OSM and RGM points for all if enough answers
        if(answerCounts.all > answerLimit) {
            HRM = Math.round(res.all.HRM / res.all.HRMTotal * 100);
            IPM = Math.round(res.all.IPM / res.all.IPMTotal * 100);
            OSM = Math.round(res.all.OSM / res.all.OSMTotal * 100);
            RGM = Math.round(res.all.RGM / res.all.RGMTotal * 100);
            allData.push(HRM);
            allData.push(IPM);
            allData.push(OSM);
            allData.push(RGM);
            data.push(allData);
            key_labels.push(allPlayersLabel+' (ka)');
            colors.push(allplayersColor2);
        }

        // Calculate HRM, IPM, OSM and RGM points for organization if enough answers
        if(answerCounts.org > answerLimit) {
            HRM = Math.round(res.usergroup.HRM / res.usergroup.HRMTotal * 100);
            IPM = Math.round(res.usergroup.IPM / res.usergroup.IPMTotal * 100);
            OSM = Math.round(res.usergroup.OSM / res.usergroup.OSMTotal * 100);
            RGM = Math.round(res.usergroup.RGM / res.usergroup.RGMTotal * 100);
            orgData.push(HRM);
            orgData.push(IPM);
            orgData.push(OSM);
            orgData.push(RGM);
            data.push(orgData);
            key_labels.push(organizationLabel+' (ka)');
            colors.push(organizationColor2);
        }
        data.push(userData);
        key_labels.push('Oma profiili');
        colors.push(userColor2);

        var tooltips = createTooltips( data, '%' );
        var graph_json = {
            'data': data,
            'options': {
                'colors': ['transparent'],
                'axes.color': 'rgba(0,0,0,0)',
                'background.circles.poly': false,
                'backgroundCirclesSpokes': 4,
                'backgroundCirclesCount': 4,
                'strokestyle': colors,
                'labelsAxes': 'n',
                'labelsAxesBoxed': false,
                'labelsPosition': 'edge',
                'labelsCount': 4,
                'units.post': '%',
                'labels': labels,
                'labels.offset': 20,
                'highlights': true,
                'gutter.left': 100,
                'gutter.right': 160,
                'gutterTop': 40,
                'gutterBottom': 40,
                'tooltips': tooltips,
                'linewidth': 3,
                'ymax': 100,
                'textSize' : 12,
                'labelsBold': true,
                'circle': [75, 25],
                'circle.fill': ['transparent', 'transparent'],
                'circle.stroke': ['#c9dbca ', '#c9dbca ']
            }
        };

        //graph_json.options['graph.angle'] = RGraph.degrees2Radians(22);
        var chartId = appendChartCanvas(container, 'radar', graph_json, colors, key_labels, graphId, chartWidth ,chartHeight);
        graph_json.id = chartId;
        var radar = new RGraph.Radar(graph_json).draw();
        addKeys(colors, key_labels, radar, container.find('.graph_container'), 'keys'+chartId);
        graph_funcs.push(wrapFunction(createGameOverGraph, this, [chartId]));
        createGameOverTooltips(radar, chartId);
        if(answerCounts.all <= answerLimit || answerCounts.org <= answerLimit)
            noAnswerStats(container);
        createPrintableResults();
    });
}

/**
 * Creates the custom tooltips for Johtamisprofiili.
 * @param {Object} radar
 * @param {String} chartId
 */
function createGameOverTooltips(radar, chartId) {
    var quinn_tooltips = [
                            '<div class="quinn_tooltip"><p>Elät muutoksessa ja luot sitä. Ajattelet luovasti ja esität ideoita. Ylläpidät suorituskykyä sekä neuvottelet sopimuksia.</p><p class="small">(Mukaellen Robert Quinn ym. 2003. Becoming a Master Manager – A Competency Framework. New York: John Wiley & Sons Inc.)</p></div>',
                            '<div class="quinn_tooltip"><p>Työskentelet tuottavasti ja vaalit tuottavaa työympäristöä. Visioit, suunnittelet, organisoit ja asetat päämääriä. Delegoit tehokkaasti.</p><p class="small">(Mukaellen Robert Quinn ym. 2003. Becoming a Master Manager – A Competency Framework. New York: John Wiley & Sons Inc.)</p></div>',
                            '<div class="quinn_tooltip"><p>Hallinnoit projekteja sekä eri toimintojen yhteistyötä. Tarkkailet henkilökohtaista suoriutumista ja hallinnoit organisaation suoriutumista.</p><p class="small">(Mukaellen Robert Quinn ym. 2003. Becoming a Master Manager – A Competency Framework. New York: John Wiley & Sons Inc.)</p></div>',
                            '<div class="quinn_tooltip"><p>Käytät osallistavaa päätöksentekoa ja kehität alaisiasi. Ymmärrät itseäsi ja muita sekä kommunikoit tehokkaasti.</p><p class="small">(Mukaellen Robert Quinn ym. 2003. Becoming a Master Manager – A Competency Framework. New York: John Wiley & Sons Inc.)</p></div>'
                        ];
    var coordsText = radar.coordsText;
    for(var i=0;i<coordsText.length;i++) {
        var x = radar.coordsText[i].x;
        var y = radar.coordsText[i].y;
        var heigth = radar.coordsText[i].height;
        var width = radar.coordsText[i].width;
        var rect = new RGraph.Drawing.Rect({
            id: chartId,
            x: x,
            y: y,
            width: width,
            height: heigth,
            options: {
                fillstyle: 'rgba(255,0,0,0.0)',
                shadow: false,
                tooltips: [quinn_tooltips[i]],
                'tooltips.event': 'onmousemove',
                'tooltips.highlight': false,
                'tooltips.offsettop': true
            }
        }).draw();
        rect.onmousemove = function (e, shape)
        {
            e.target.style.cursor = 'pointer';
        };
        rect.onclick = function (e, shape)
        {
            e.preventDefault();
        };

        rect.on('tooltip', function (e)
{
     var tooltip = RGraph.Registry.get('chart.tooltip');
     tooltip.style.position = 'absolute';
})
    }
}
