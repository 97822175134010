/*jshint multistr: true, laxbreak: true */

/**
 * @file Handles task answers.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var answers_new = {};
var task_groups = {};
var board_results = {};

/** Creates the list of answered tasks **/
function appendAnswersNew() {
    var answer, questiondata, answercount = 0;
    $('#answerList .answer_collapsible').remove();
    for(var key in answers_new) {
        answer = answers_new[key];
        questiondata = answer.questiondata;
        //answer.answer_index = parseInt(answer.answer_index);

        // Make sure questiondata is JSON
        if (typeof questiondata == 'string' || questiondata instanceof String)
            questiondata = JSON.parse(questiondata);

        appendAnswer( answer, questiondata );
        answercount++;
    }

    if(answercount === 0) {
        $('#answerList').append('<p class="no_answers">Ei vastauksia.</p>');
    }
}

/**
 * Appends an answer to a task to #answerList.
 * @param {Object} answer
 * @param {Object} questiondata
 */
function appendAnswer( answer, questiondata ) {
    if(questiondata.StaticParameters.showComparison !== false) {
        $('#answerList .no_answers').remove();
        var answer_container;
        var abc = ['a) ', 'b) ', 'c) ', 'd) ', 'e) ', 'f) ', 'g) ', 'h) ', 'i) ', 'j) ', 'k) ', 'l) ', 'm) ', 'n) ', 'o) ', 'p) ', 'q) ', 'r) ', 's) ', 't) ', 'u) '];
        var array = JSON.parse("[" + answer.answer_index + "]");
        if(!task_groups[questiondata.Name]) {
            task_groups[questiondata.Name] = $('<div data-role="collapsible" class="answer_collapsible"><h4>'+questiondata.Name+'</h4></div');
            $('#answerList').append(task_groups[questiondata.Name]);
            $('#answerList').trigger('create');
        }
        answer_container = $('<div class="answer_container"></div>');
        answer_container.append('<p>'+questiondata.TaskDescription.Description+'</p>');

        // Add options and mark own selection with class 'alert-success'
        for(var i=0;i<questiondata.TaskDescription.Choice.length;i++) {
            if(questiondata.openAnswer) {
                var div = $('<div class="openAnswer_container'+ ((array.indexOf(i) !== -1)? ' alert-success':'')+'">');
                div.append('<textarea data-task="'+questiondata.TaskID+'" data-index="'+i+'" >'+answer.answer_text+'</textarea>');
                div.append('<button data-task="'+questiondata.TaskID+'" data-index="'+abc[i]+i+'" >Vastaa</button>');
                answer_container.append(div);
            } else
                answer_container.append('<button class="'+ ((array.indexOf(i) !== -1)? ' alert-success':'')+'" data-task="'+questiondata.TaskID+'" data-index="'+i+'" >'+abc[i]+questiondata.TaskDescription.Choice[i].Description+'</button>');
        }

        answer_container.append('<div class="compare_button ui-collapsible ui-collapsible-inset ui-corner-all ui-collapsible-themed-content ui-collapsible-collapsed" data-enhanced="true" data-role="collapsible">\n\
                            <h4 data-qid="'+questiondata.TaskID+'" class="btn btn-primary ui-collapsible-heading ui-collapsible-heading-collapsed">\n\
                            <a class="ui-collapsible-heading-toggle ui-btn ui-icon-plus ui-btn-icon-left ui-btn-inherit btn btn-primary" href="#">Vertaa<span class="ui-collapsible-heading-status"> click to expand contents</span></a>\n\
                            </h4>\n\
                            <div class="ui-collapsible-content ui-body-inherit ui-collapsible-content-collapsed" aria-hidden="true"><div class="collapsible_content"></div>\n\
                            </div></div>');

        task_groups[questiondata.Name].find('.ui-collapsible-content').first().append(answer_container);
        $('#answerList').trigger('create');
        appendEventHandlersForCollapsible(questiondata.TaskID);
    }
}

/**
 * Displays final results for 'Ihannetyöpäivä'.
 * @param {Object} content
 * @param {Boolean} show_last_question Display last question with the results
 * @param {Object} last_question Question object for the last question
 */
function showIdealWorkdayResults(content, show_last_question, last_question) {
    var result = $('<p class="workday_result text-info"></p>');
    var answer, questionIndex, question, shirt, pants, shoes, hair, questionid;
    content.empty();

    // Get first questionid to get the right questionid
    questionid = parseInt(questions["Ihannetyöpäivä"].questionBatteries["Ihannetyöpäivä"].questions[0].questionid);
    questionid++;

    /* Pukeutuminen ja kampaus
     * ”Luot pukeutumisellasi vaikutelman:
        - uskottavasta ammattilaisesta (pikkutakki/jakku, suorat housut/asiallinen hame, asialliset kengät, asiallinen kampaus -yhdistelmä)
        - asiallisesta työntekijästä (kauluspaita/neule, farkut/suorat housut/asiallinen hame, asialliset kengät/saapikkaat, asiallinen kampaus/pystäri)
        - rennosta kaverista (huppari/kukkamekko, lökärit, tennarit, sandaalit, ponnari/pystäri/värikäs -yhdistelmä)
        - omaleimaisesta persoonasta (”omituiset jämäyhdistelmät”)”
     */
    result.append("Luot pukeutumisellasi vaikutelman ");
    shirt = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    pants = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    shoes = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    hair = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(shirt === "asiallinen kauluspaita + jakku/pikkutakki" && (pants === "suorat housut" || pants === "asiallinen hame") &&
            (shoes === "asialliset mustat kengät miehille" || shoes === "asialliset mustat kengät naisille" || shoes === "mustat saapikkaat")
            && (hair === "asiallinen kampaus miehelle" || hair === "asiallinen kampaus naiselle (nuttura)" || hair === "asiallinen kampaus naiselle (polkkatukka)")) {
                result.append("uskottavasta ammattilaisesta.");
    } else if((shirt === "asiallinen kauluspaita + jakku/pikkutakki" || shirt === "neule" ) && (pants === "suorat housut" || pants === "asiallinen hame" || pants === "farkut") &&
            (shoes === "asialliset mustat kengät miehille" || shoes === "asialliset mustat kengät naisille" || shoes === "mustat saapikkaat")
            && (hair === "asiallinen kampaus miehelle" || hair === "miesten lyhyt" || hair === "asiallinen kampaus naiselle (nuttura)" || hair === "asiallinen kampaus naiselle (polkkatukka)")) {
                result.append("asiallisesta työntekijästä.");
    } else if((shirt === "huppari" || shirt === "kesämekko" ) && pants === "lökärit" && (shoes === "tennarit" || shoes === "ruskeat kevyet sandaalit")
            && (hair === "ponnari" || hair === "violetti" || hair === "punertava" || hair === "sininen" || hair === "kalju" || hair === "miesten lyhyt")) {
                result.append("rennosta kaverista.");
    } else
        result.append("omaleimaisesta persoonasta.");

    /* Kulkuväline
        - ”Yhdistät mielelläsi liikunnan työpäivään ja säästät samalla luonnonvaroja.” (kävely/pyöräily)
        - ”Teet kokonaistaloudellisia matkustusvalintoja.” (bussi/juna)
        - ”Olet maailmanvalloittaja.” (lentokone)
        - ”Arvostat liikkumisessa nopeutta ja sujuvuutta.” (oma auto)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid += 3;
    if(answer) {
        if((answer.length === 1 && (answer[0] === "kävellen" || answer[0] === "pyörällä")) ||
                (answer.length === 1 && $.inArray( "kävellen", answer) > -1 && $.inArray( "pyörällä", answer) > -1))
            result.append(" Yhdistät mielelläsi liikunnan työpäivään ja säästät samalla luonnonvaroja.");
        else if((answer.length === 1 && (answer[0] === "bussilla" || answer[0] === "junalla")) ||
                (answer.length === 1 && $.inArray( "bussilla", answer) > -1 && $.inArray( "junalla", answer) > -1))
            result.append(" Teet kokonaistaloudellisia matkustusvalintoja.");
        else if(answer.length === 1 && answer[0] === "lentokoneella")
            result.append(" Olet maailmanvalloittaja.");
        else if(answer.length === 1 && answer[0] === "autolla")
            result.append(" Arvostat liikkumisessa nopeutta ja sujuvuutta.");
    }

    /* Karttatehtävä
     * jaettu kahteen eri kysymykseen:
     * 1. Liikutko työpäivän aikana muualla kuin kodin ja työpaikan välillä?
     * 2. Valitse ihanteellinen ympäristö työpaikallesi
        - ”Olet paikallinen suuruus.” (pienet etäisyydet/korkeintaan lähiseutu)
        - ”Sinulle globalisaatio ei ole pelkkää sanahelinää.” (EU/kaukomaat)
        - ”Urbaanina ihmisenä kaupungin syke on sinulle luontevaa.” (kaikki kaupunkimaiset valinnat)
        - ”Rauhallinen ympäristö ja luonnon läheisyys on sinulle tärkeää.” (kaikki luontoa koskevat huomiot)
        - ”Kaipaat mahdollisuutta liikkua työssäsi sekä kaupungin sykkeessä että luonnon rauhassa.” (molemmanlaisia valintoja)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionIndex = questionIndexesById[questionid].questionindex;
    questionid++;
    question = questions["Ihannetyöpäivä"].questionBatteries["Ihannetyöpäivä"].questions[questionIndex];
    answer = question.options[answer];

    if(answer === "saman paikkakunnan rajojen sisällä" || answer === "lähiseudulla") {
        result.append(" Olet paikallinen suuruus.");
    } else if(answer === "EU:ssa" || answer === "kaukomailla") {
        result.append(" Sinulle globalisaatio ei ole pelkkää sanahelinää.");
    }

    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    questionIndex = questionIndexesById[133].questionindex;
    question = questions["Ihannetyöpäivä"].questionBatteries["Ihannetyöpäivä"].questions[questionIndex];
    answer = question.options[answer];

    if(answer === "urbaani miljöö") {
        result.append(" Urbaanina ihmisenä kaupungin syke on sinulle luontevaa.");
    } else if(answer === "maaseutu") {
        result.append(" Rauhallinen ympäristö ja luonnon läheisyys on sinulle tärkeää.");
    } else if(answer === "kaupungin ja luonnon tasapainoinen kokonaisuus") {
        result.append(" Kaipaat mahdollisuutta liikkua työssäsi sekä kaupungin sykkeessä että luonnon rauhassa.");
    }

    /* Työskentelytila ja sosiaaliset kontaktit
        - ”Kaipaat keskittymisrauhaa ja omaa tilaa.” (omassa huoneessa työpaikalla/kotona/kirjastossa)
        - ”Saat energiaa työkavereiden kanssa työskentelystä eikä melu haittaa sinua.” (kahden hengen huone/avokonttori)
        - ”Olet verkostoituja ja liikut mielelläsi eri paikoissa.” (asiakkaan tilat/yhteistyökumppanin tilat/hotelli)
        - ”Nautit työnteosta viehättävissä ympäristöissä.” (kahvila/kesäinen puutarha)
        - ”Haluat tehdä omaa työtäsi ihmisten keskellä mukavassa porukassa.” (yhteisölliset työtilat)
        - ”Etsit itsellesi sopivan ympäristön tilanteen mukaan.” (useita hyvin erilaisia valintoja)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if(answer[0] === "omalla työpaikalla yksin omassa huoneessa" || answer[0] === "kotona" || answer[0] === "kirjastossa")
            result.append(" Kaipaat keskittymisrauhaa ja omaa tilaa.");
        else if(answer[0] === "kahden hengen huoneessa" || answer[0] === "avokonttorissa")
            result.append(" Saat energiaa työkavereiden kanssa työskentelystä eikä melu haittaa sinua.");
        else if(answer[0] === "kahden hengen huoneessa" || answer[0] === "avokonttorissa")
            result.append(" Saat energiaa työkavereiden kanssa työskentelystä eikä melu haittaa sinua.");
        else if(answer[0] === "asiakkaan tiloissa" || answer[0] === "yhteistyökumppanin tiloissa" || answer[0] === "hotellissa")
            result.append(" Olet verkostoituja ja liikut mielelläsi eri paikoissa.");
        else if(answer[0] === "kahvilassa" || answer[0] === "kesäisessä puutarhassa" )
            result.append(" Nautit työnteosta viehättävissä ympäristöissä.");
        else if(answer[0] === "yhteisöllisessä työtilassa (jossa työskentelee eri työnantajan palveluksessa olevia)" )
            result.append(" Haluat tehdä omaa työtäsi ihmisten keskellä mukavassa porukassa.");
        else
            result.append(" Etsit itsellesi sopivan ympäristön tilanteen mukaan.");
    }

    /* Tunteet
        - ”Tunteet eivät mielestäsi kuulu työhön.” (ellei valkkaa mitään)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    if(answer && answer.length === 0 || (answer && answer.length === 1 && answer[0] === "en koe tunteita"))
        result.append(" Tunteet eivät mielestäsi kuulu työhön.");
    questionid++;

    /* Työaika
        - ”Pidät selkeästä työn ja vapaa-ajan rajautumisesta.” (”kahdeksasta neljään tai yhdeksästä viiteen” –tyyppiset, perinteisesti tauotetut ratkaisut)
        - ”Haluat hallita itse omaa aikaasi.” (kaikki em. standardimallista poikkeavat rytmitykset)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    var from = parseInt(answer.from);
    var to = parseInt(answer.to);
    var breaks = answer.breaks;
    var breaks_duration = 0;
    for(var i=0;i<breaks.length;i++) {
        breaks_duration += parseInt(breaks[i]);
    }
    if((from === 8 || from === 9) && (to === 16 || to === 17 || to === 4 || to === 5) && breaks.length === 3 && breaks_duration >= 45 && breaks_duration <= 90)
        result.append(" Pidät selkeästä työn ja vapaa-ajan rajautumisesta.");
    else {
        result.append(" Haluat hallita itse omaa aikaasi.");
    }

    /* Työsuhde
        - ”Olet oman itsesi herra: vapausasteet ovat sinulle tärkeitä.” (yksinyrittäjä/ammatinharjoittaja/free)
        - ”Työskentelet mielelläsi yhdessä hyvän porukan kanssa.” (kimppafirma/osuuskunta)
        - ”Ennakoitavuus ja turvallisuus ovat sinulle tärkeitä työsuhteessa.” (julkinen sektori)
        - ”Haluat työlläsi edistää tärkeäksi kokemiasi asioita.” (järjestö)
        - ”Sinua kiehtovat ne mahdollisuudet, joita markkinaehtoiset yritykset voivat avata.” (yksityinen sektori)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if($.inArray("olen yksinyrittäjä, ammatinharjoittaja tai free lancer", answer) > -1 )
            result.append(" Olet oman itsesi herra: vapausasteet ovat sinulle tärkeitä.");
        if($.inArray("työskentelen osuuskunnassa", answer) > -1 )
            result.append(" Työskentelet mielelläsi yhdessä hyvän porukan kanssa.");
        if($.inArray("olen palkkatyösuhteessa julkisella sektorilla", answer) > -1 )
            result.append(" Ennakoitavuus ja turvallisuus ovat sinulle tärkeitä työsuhteessa.");
        if($.inArray("olen palkkatyösuhteessa järjestöön", answer) > -1 )
            result.append(" Haluat työlläsi edistää tärkeäksi kokemiasi asioita.");
        if($.inArray("olen palkkatyösuhteessa yksityisellä sektorilla pk-yrityksessä", answer) > -1 || $.inArray("olen palkkatyösuhteessa yksityisellä sektorilla suuressa kansainvälisessä yrityksessä", answer) > -1 )
            result.append(" Sinua kiehtovat ne mahdollisuudet, joita markkinaehtoiset yritykset voivat avata.");
    }

    /* Kohtaamiset
        - ”Tutustut mielelläsi uusiin ihmisiin.” (uusi ihminen)
        - ”Tuttujen kanssa työ sujuu.” (tuttu ihminen)
        - ”Et arkaile ylittää hierarkioita.” (esimies/alainen)
        - ”Eläimet ovat lähellä sydäntäsi.” (kissa/koira)
        - ”Luotat teknologian mahdollisuuksiin.” (robotti)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if($.inArray("uusi ihminen", answer) > -1 )
            result.append(" Tutustut mielelläsi uusiin ihmisiin.");
        if($.inArray("ennalta tuttu ihminen", answer) > -1 || $.inArray("työkaveri", answer) > -1)
            result.append(" Tuttujen kanssa työ sujuu.");
        if($.inArray("esimies", answer) > -1 || $.inArray("alainen", answer) > -1 )
            result.append(" Et arkaile ylittää hierarkioita.");
        if($.inArray("koira", answer) > -1 || $.inArray("kissa", answer) > -1 )
            result.append(" Eläimet ovat lähellä sydäntäsi.");
        if($.inArray("robotti", answer) > -1)
            result.append(" Luotat teknologian mahdollisuuksiin.");
    }

    /* Sosiaalisuus työskennellessä, keiden kanssa pisimpään
        - ”Saat parhaiten aikaan keskittyessäsi tehtävään yksin.” (yksin omassa rauhassa)
        - ”Yksin yhdessä –luonnehdinta kuvaa työskentelytapaasi.” (itsenäisesti toisten läheisyydessä)
        - ”Saman alan ihmisen kanssa työnteko sujuu.” (kollegan kanssa)
        - ”Erilaiset näkökulmat rikastuttavat.” (eri alan yhteistyökumppanin kanssa)
        - ”Tiimityössä on mielestäsi voimaa.” (pysyvä ryhmä)
        - ”Pidät erilaisista ajatuksista ja oppimismahdollisuuksista, joita uudet ihmiset tarjoavat.” (vaihtuvajäseninen ryhmä)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if(answer === "työskentelen yksin omassa rauhassa")
            result.append(" Saat parhaiten aikaan keskittyessäsi tehtävään yksin.");
        else if(answer === "työskentelen itsenäisesti, mutta toisten keskellä/välittömässä läheisyydessä")
            result.append(" Yksin yhdessä –luonnehdinta kuvaa työskentelytapaasi.");
        else if(answer === "työskentelen työparina kollegan kanssa")
            result.append(" Saman alan ihmisen kanssa työnteko sujuu.");
        else if(answer === "työskentelen eri alan yhteistyökumppanin kanssa")
            result.append(" Erilaiset näkökulmat rikastuttavat.");
        else if(answer === "työskentelen kokoonpanoltaan pysyvässä noin viiden hengen ryhmässä")
            result.append(" Tiimityössä on mielestäsi voimaa.");
        else if(answer === "työskentelen vaihtuvajäsenisissä noin viiden hengen ryhmissä")
            result.append(" Pidät erilaisista ajatuksista ja oppimismahdollisuuksista, joita uudet ihmiset tarjoavat.");
    }

    /* Työtehtävät
        - ”Olet suurten linjojen ihminen.” (strategiat, neuvottelen tärkeän yhteistyökumppanin kanssa)
        - ”Pyrit priorisoimaan tehtäviä ammattietiikkasi edellyttämällä tavalla ja haluat kehittyä ammattilaisena.” (ammatin ydintehtävät, ammattikirjallisuus)
        - ”Haluat, että asiat hoidetaan tarkasti ja oikein.” (raportointi, reklamaatiot, kokousten valmistelu, kilpailutuksen hoitaminen)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if($.inArray("teen merkittäviä strategisia linjauksia", answer) > -1 || $.inArray("neuvottelen tärkeän yhteistyötahon kanssa", answer) > -1 )
            result.append(" Olet suurten linjojen ihminen.");
        if($.inArray("keskityn oman ammattini ydintyötehtäviin (esim. lääkäri tapaa potilaita)", answer) > -1 || $.inArray("luen ammattikirjallisuutta tai -lehtiä", answer) > -1)
            result.append(" Pyrit priorisoimaan tehtäviä ammattietiikkasi edellyttämällä tavalla ja haluat kehittyä ammattilaisena.");
        if($.inArray("raportoin työpaikan järjestelmiin", answer) > -1 || $.inArray("käsittelen asiakasreklamaatioita", answer) > -1 || $.inArray("valmiselen kokouksia", answer) > -1 || $.inArray("hoidan kilpailutusta", answer) > -1 )
            result.append(" Haluat, että asiat hoidetaan tarkasti ja oikein.");
    }
    /* Kommunikointitapa, erotellaan vain kasvokkainen ja virtuaalinen
        - ”Uskot, että kasvokkain keskustellen asiat selkiytyvät.” (kasvokkainen)
        - ”Olet huomannut, että useimmat asiat voi hoitaa virtuaalisesti.” (virtuaalinen joko palaverina tai alustana)
        - ”Sinusta on hyvä käyttää erilaisia kommunikointitapoja tilanteen mukaan.” (sekä että)
     */
    var answer1 = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    var answer2 = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    var answer3 = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer1 === "kyllä" && answer2 === "ei" && answer3 === "ei")
        result.append(" Uskot, että kasvokkain keskustellen asiat selkiytyvät.");
    else if(answer1 === "ei" && answer2 === "kyllä" && answer3 === "kyllä")
        result.append(" Olet huomannut, että useimmat asiat voi hoitaa virtuaalisesti.");
    else
        result.append(" Sinusta on hyvä käyttää erilaisia kommunikointitapoja tilanteen mukaan.");

    /* Koulutus
        - ”Koulutukseen osallistuminen on sinulle osa työtä.” (osallistuu)
        - ”Ihanteelliseen työpäivään ei mielestäsi kuulu koulutus.” (ei osallistu)
     */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer && answer === "kyllä: millaiseen?")
        result.append(" Koulutukseen osallistuminen on sinulle osa työtä.");
    else
        result.append(" Ihanteelliseen työpäivään ei mielestäsi kuulu koulutus.");

    /* Aktiivisuus vs. inaktiivisuus
        - ”Selätät istumatyön vaarat tehokkaalla liikunnalla työpäivän lomassa.” (liikunta yli 5:n)
        - ”Osaat hyödyntää pienetkin mahdollisuudet liikkumiseen työpäivän aikana.” (liikkuminen yli 5:n)
    */
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if(parseInt(answer) > 5)
            result.append(" Selätät istumatyön vaarat tehokkaalla liikunnalla työpäivän lomassa.");
    }
    answer = answers["Ihannetyöpäivä"]["Ihannetyöpäivä"][questionid].answer;
    questionid++;
    if(answer) {
        if(parseInt(answer) > 5)
            result.append(" Osaat hyödyntää pienetkin mahdollisuudet liikkumiseen työpäivän aikana.");
    }
    result.append("\"");
    content.append(result);

    if(show_last_question) {
        appendRadioQuestion(last_question, last_question.questionid, true);
    }
}

/**
 * Saves answertime for a task.
 * @param {Object} task
 */
function saveAnswerTimeNew(task) {
    var date = new Date();
    var questionAnswered = date.getTime();
    var answerTime =  Math.round((questionAnswered - questionShown) / 1000);
    var previousTime = task.answeringtime;
    if(previousTime)
        answerTime += previousTime;

    task.answeringtime = answerTime;
    questionShown = 0;
}

/**
 * Update show count for a task.
 * @param {Object} task
 */
function updateQuestionShowcountNew(task) {
    // Update viewcount
    if(!task.viewcount)
        task.viewcount = 0;
    task.viewcount += 1;

    // Log task shown
    var data = {
        'feature': 'Show task',
        'value': task.TaskID,
        //'parameters': {"task": task, "taskID":task.TaskID, "showCount":task.viewcount},
        'parameters': {"taskID":task.TaskID, "showCount":task.viewcount},
        'level': Palmu.LogLevel.INFO
    };
    LogData(data);

    if(!answers_new[task.TaskID])
        answers_new[task.TaskID] = {};
    var answer = answers_new[task.TaskID];
    if(answer.show_count)
        answer.show_count++;
    else
        answer.show_count = 1;
}

/**
 * Gets board results for user.
 */
function getBoardResults() {
    $.get("php/get_single_boardresults.php", {uid: user.id, bid: user.boardid}, function(data) {
        board_results = data;
    });
}

/**
 * Displays board results for a specific topic.
 *
 * @param {String} topic
 * @param {String} battery
 * @param {object} container
 */
function displayBoardTopicResults(topic, battery, container) {
    var user_avg = 0, group_avg = 0;

    // Get user average
    user_avg = getUserAverageForTopic(topic, battery);
    container.append('<span class="board_average">Oma arviosi (ka): <b>' + user_avg + '</b></span>');

    // Get group average
    group_avg = getGroupAverageForTopic(topic, battery);
    if(group_avg || group_avg === 0) {
        container.append('<span class="board_average">Ryhmän antama keskiarvo: <b>' + group_avg + '</b></span>');
    } else
        container.append('<span class="board_average">Ryhmäkohtaista vertailudataa ei ole vielä saatavilla.</span>');
}

/**
 * Calculates user average for a topic.
 * @param {String} topic
 * @param {String} battery
 * @returns {Int} user average
 */
function getUserAverageForTopic(topic, battery) {
    var count = 0, user_avg = 0, answerset;

    answerset = answers[topic][battery];
    $.each(answerset, function(i, answer) {
        if(answer.answer) {
            count++;
            user_avg += parseInt(answer.answer);
        }
    });
    return (parseFloat((user_avg / count).toFixed(1)));
}

/**
 * Calculates group average for topic. // TODO: Get friend organization average
 * @param {String} topic
 * @param {String} battery
 * @returns {Int} group average null if not enough answers to calculate average
 */
function getGroupAverageForTopic(topic, battery) {
    var answer, count = 0, group_avg = 0, answer_count = 0, user_answer;

    var topic_questions = questions[topic].questionBatteries[battery].questions;
    // If user is not a boardmember, calculate results based on the whole organization, else use board answers
    var answerArr = (!user.boardid)?answerstats.usergroup:board_results;
    var userCount = 0;
    for(var i=0;i<topic_questions.length;i++) {
        answer = answerArr[topic_questions[i].questionid];
        user_answer = answers[topic][battery][topic_questions[i].questionid];
        if(answer) {
            if(user_answer.answer) {
                group_avg += parseInt(answer.answersum) + parseInt(user_answer.answer);
                userCount = 1;
            } else {
                group_avg += parseInt(answer.answersum);
                userCount = 0;
            }

            if(parseInt(answer.answercount) + userCount > answer_count)
                answer_count = parseInt(answer.answercount) + userCount;

            count += parseInt(answer.answercount) + userCount;
        }
    }
    return (count !== 0 && answer_count > answerLimit) ? (parseFloat((group_avg / count).toFixed(1))): null;
}

/**
 * Displays the final board results after all board questions have been answered.
 * @param {Object} container
 */
function displayBoardFinalResults(container) {
    var user_avg = 0, group_avg = 0, user_topic_count = 0, group_count = 0, temp = null;
    var batteries = questions['Johtoryhmä'].questionBatteries;
    $.each(batteries, function(i, battery) {
        user_topic_count++;
        user_avg += getUserAverageForTopic('Johtoryhmä', i);
        temp = getGroupAverageForTopic('Johtoryhmä', i);
        if(temp) {
            group_avg += temp;
            group_count++;
        }
    });

    user_avg = (parseFloat(user_avg) / user_topic_count).toFixed(1);

    container.append('<h4>Koko johtoryhmän laadun arviointi</h4>');
    container.append('<span class="board_average">Oma arviosi (ka): <b>' + user_avg + '</b></span>');

    if(group_count !== 0) {
        group_avg = (parseFloat(group_avg) / group_count).toFixed(1);
        container.append('<span class="board_average">Ryhmän antama keskiarvo: <b>' + group_avg + '</b></span>');
    } else
        container.append('<span class="board_average">Ryhmäkohtaista vertailudataa ei ole vielä saatavilla.</span>');

    container.append('<div class="board_results"><p>Jos laatupisteet ovat välillä <b>0 - 4.0</b>, johtoryhmänne toiminnassa on paljon kehittämisen tarvetta.</p>\n\
                    <p>Jos laatupisteet ovat välillä <b>4.1 - 7.5</b>, johtoryhmänne toimii kohtuullisen hyvin, mutta kehitettävääkin löytyy. Pohtikaa, mikä teema-alue kaipaisi eniten kehittämistä.</p>\n\
                    <p>Jos laatupisteet ovat välillä <b>7.6 - 10</b>, johtoryhmänne toimii kiitettävästi, onneksi olkoon! Arviointi kannattaa kuitenkin toistaa säännöllisesti.<p></div>');
}
