/*jshint multistr: true, laxbreak: true */

/**
 * @file Handles the clock and time in the game.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var $hands;
var seconds = 0;
var minutes = 8 * 60;
var workdays = 0;
var clockHandle;

/***********************************************
* CSS3 Analog Clock- by JavaScript Kit (www.javascriptkit.com)
***********************************************/
/**
 * Called every second to update time on the clock.
 */
function updateclock(newTime) {
    var hours = Math.floor(minutes / 60);
    var minutes_ = minutes - 60 * hours;
    var hour_as_degree = ( hours + minutes_/60 ) / 12 * 360;
    var minute_as_degree = minutes_ / 60 * 360;
    var second_as_degree = seconds /60 * 360;

    if(!$hands)
      $hands = $('#liveclock div.hand');

    if($hands) {
      $hands.filter('.second').css({transform: 'rotate(' + second_as_degree + 'deg)' });
      seconds++;
      if(seconds === 1) {
          $hands.filter('.hour').css({transform: 'rotate(' + hour_as_degree + 'deg)' });
          $hands.filter('.minute').css({transform: 'rotate(' + minute_as_degree + 'deg)' });
      } else if(seconds === 60) {
          seconds = 0;
          minutes++;
          $hands.filter('.hour').css({transform: 'rotate(' + hour_as_degree + 'deg)' });
          $hands.filter('.minute').css({transform: 'rotate(' + minute_as_degree + 'deg)' });

          checkWorktimeLeft();
      }
      if(newTime && newTime === minutes) {
          clearInterval(clockHandle);
          updateclock();
          clockHandle = setInterval(updateclock,1000);
      }
    }
}

/**
 * Starts the clock.
 */
function setupClock() {
    $hands = $('#liveclock div.hand');
    clockHandle = null;
    updateclock();
    clockHandle = setInterval(updateclock,1000);
}

/**
 * Displays workday results and resets clock.
 */
function showWorkdayResults() {
    var modal = $('#workday_results');
    var content = modal.find('.results');
    var doneCount = (gameStatus.Status && gameStatus.Status.tehdyt)?gameStatus.Status.tehdyt.length:0;
    var tasksTotal = gameStatus.Status.tasksInTotal;
    var progress = parseInt((doneCount/tasksTotal)*100);

    modal.find('#workdayResults').html(workdays);
    content.empty();
    content.append('<div class="points"><p>\n\
                        <img src="img/icons/hyvinvointi.png" alt="Hyvinvointi" /> <b>Hyvinvointi: ' + $('#hv_pist').html() + '</b><br />\n\
                        <img src="img/icons/tehokkuus.png" alt="Tehokkuus" /> <b>Tehokkuus: ' + $('#tehokkuus_pist').html() +'</b><br/>\n\
                        <img src="img/icons/talous.png" alt="Talous" /> <b>Talous: ' + $('#talous_pist').html() +'</b>\n\
                    </p></div>');
    content.trigger('create');
    modal.popup( "open" );

    // Set progress indicator
    $('#profileProgress').html(progress);
    updateProfileProgress = function() {
	$('.ko-progress-circle').attr('data-progress', progress);
    };
    setTimeout(updateProfileProgress, 200);

    // Log show workdayresults
    var data = {
        'feature': 'Show workday results',
        'level': Palmu.LogLevel.INFO,
        'parameters': {'workdays': workdays, 'minutes': minutes, 'seconds': seconds},
        // 'context': {'status': gameStatus.Status}
    };
    LogData(data);

    updateclock();
    resetClock();
    clearTasks();
}

/**
 * Resets the clock.
 */
function resetClock() {
    seconds = 0;
    minutes = 8 * 60;
    clearInterval(clockHandle);
}

/**
 * Checks if workday is over.
 *
 * @param {Boolean} taskDone
 * @returns {Boolean} worktime left or not
 */
function checkWorktimeLeft(taskDone) {
    if(minutes >= 960) {
        workdays++;
        // Log new workday
        var data = {
            'feature': 'Workday changed',
            'level': Palmu.LogLevel.INFO,
            'parameters': {'workdays': workdays, 'minutes': minutes, 'seconds': seconds}
        };
        LogData(data);

        // Check if any popups open (user in the middle of a task) and only show results if no immediate followup needed
        if(taskDone || !$('.ui-popup-container').is(':visible') || !immediateFollowup)
            showWorkdayResults();
        return false;
    }
    return true;
}

/**
 * Sets up the clock based on game status.
 *
 * @param {Int} status_workdays
 * @param {Int} status_minutes
 */
function setupTime( status_workdays, status_minutes ) {
    workdays = status_workdays;
    minutes = status_minutes;
    $('#workday').html(workdays + 1);
    setupClock();
}

/**
 * Saves current time in gameStatus.
 */
function updateStatusTime() {
    gameStatus.Status.UI_Workdays = workdays;
    gameStatus.Status.UI_Minutes = minutes;
}

function animateUpdateClock(newTime) {
    clearInterval(clockHandle);
    updateclock(newTime);
    clockHandle = setInterval(function() { updateclock(newTime);},1);
}


// Calendar (https://codepen.io/MickCoelho/pen/YyKagE)
//var calendar;
//
//var onStartTweenBottom = function(dayBottomP, dayP){
//  TweenMax.set(dayBottomP, { z: 1 });
//};
//var onUpdateTweenBottom = function(dayBottomP, dayP, currentDayP){
//    if(this.target._gsTransform.rotationX < 90){
//      dayP.style.zIndex = currentDayP + 1;
//    }
//};
//var onCompleteTweenBottom = function(dayBottomP, dayP){
//};
//
//var onStartTweenTop = function(dayTopP, dayP){
//  TweenMax.set(dayTopP, { z: 1 });
//};
//var onUpdateTweenTop = function(dayTopP, dayP, currentDayP){
//    if(this.target._gsTransform.rotationX < -90){
//      dayP.style.zIndex = currentDayP ;
//    }
//};
//
//var today = new Date();
//var calendarParams = {
//      //day: today.getDate(),
//      day: 0,
//      month: 'Päivä',
//     // year: 2015,
//      duration: 8,
//      durationFlip: 0.4
//    },
//    startDayDate,
//    startDay;
//
//var monthNames = ["Päivä"];
//var currentDay = 0;
//var topOverlay,
//    bottomOverlay;
//var topDelay,
//    bottomDelay;
//var monthName = "Päivä";
//
//
//function init(){
//    calendar = $('.calendar')[0];
//    startCalendar();
//}
//
//function reset(){
//  calendar.classList.remove('in');
//  calendar.innerHTML ='';
//}
//
//function startCalendar(){
//    console.log('startCalendar');
//  reset();
//  var durationFlip = calendarParams.durationFlip;
//  var i = 0,
//      j = 0;
//  var day,
//      dayBottom,
//      dayTop;
//  var extraDelay = 1;
//
//
//  //startDayDate = new Date(calendarParams.year, calendarParams.month, calendarParams.day - 1);
//  //startDay = startDayDate.getDate();
//  startDay = 0;
//
//  setTimeout(function(){
//    calendar.classList.add('in');
//  }, extraDelay * 100);
//
//
//  for ( i = 0 ; i < calendarParams.duration + 2; i++){
//    //CREATE DAY CONTAINER
//    //currentDay = startDayDate.getDate();
//
//    monthName = monthNames[0];
//    //startDayDate.setDate(startDayDate.getDate() + 1);
//    dayTop = document.createElement('p');
//    dayTop.classList.add('top')
//    dayTop.innerHTML = '<span>' + currentDay + '</span><span class="overlay"></span>';
//    dayBottom = document.createElement('p');
//    dayBottom.classList.add('bottom')
//    dayBottom.innerHTML = '<span>' + currentDay + '</span><span class="month">' + monthName + '</span><span class="overlay"></span>';
//    day = document.createElement('div');
//    day.classList.add('day');
//    day.setAttribute('data-day', currentDay);
//    day.appendChild( dayTop );
//    day.appendChild( dayBottom );
//    calendar.insertBefore( day, calendar.firstChild );
//    topOverlay = dayTop.querySelector('.overlay');
//    bottomOverlay = dayBottom.querySelector('.overlay');
//
//    topDelay = (durationFlip * 0.5) * j + extraDelay;
//    bottomDelay = topDelay + (durationFlip * 0.5);
//    currentDay = currentDay +1;
//
//
//    //ANIMATING BOTTOM HALF
//    if(i > 0){
//      TweenMax.set(dayBottom, {
//        transformOrigin:'50% 0%',
//        rotationX: 180, z: 0 }
//      );
//      TweenMax.to(dayBottom, durationFlip, {
//        rotationX: 0,
//        transformOrigin:'50% 0%',
//        force3D: true,
//        delay: topDelay,
//        onStart: onStartTweenBottom,
//        onStartParams: [dayBottom, day],
//        onUpdate: onUpdateTweenBottom,
//        onUpdateParams: [dayBottom, day, j],
//        onComplete: onCompleteTweenBottom,
//        onCompleteParams: [dayBottom, day]
//      })
//    }
//
//    if(i <= calendarParams.duration){
//      durationFlip -= 0.002;
//      durationFlip = Math.max(durationFlip, 0.2);
//      TweenMax.to(bottomOverlay, durationFlip * 2, {
//        opacity: 0.5,
//        force3D: true,
//        delay: (durationFlip * 0.5) * j + (durationFlip * 0.5) + extraDelay
//      })
//    //ANIMATING TOP HALF
//      TweenMax.set(dayTop, {
//        transformOrigin:'50% 100%',
//        rotationX: 0
//      });
//      TweenMax.to(dayTop, durationFlip, {
//        rotationX: -180,
//        transformOrigin:'50% 100%',
//        force3D: true,
//        delay: bottomDelay,
//        onStart: onStartTweenTop,
//        onStartParams: [dayTop, day],
//        onUpdate: onUpdateTweenTop,
//        onUpdateParams: [dayTop, day, j]
//      })
//      TweenMax.to(topOverlay, durationFlip * 1.2, {
//        opacity: 0.5,
//        force3D: true,
//        delay: (durationFlip * 0.5) * j + (durationFlip * 0.5) + extraDelay
//      })
//    }
//    //durationFlip -= 0.002
//    j ++;
//  }
//}
//
//window.onload = init;
