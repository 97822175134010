/*jshint multistr: true, laxbreak: true */

/**
 * @file Handles the new tasks.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */
var tasks = [];
var selectedTask;
var task_layer;
var task_queue  = null;
var phone_timeut;
var immediateFollowup; // TODO: Don't use global variable!
var people_images = { // All the available charachters listed
	"male": [
		"man1.png",
		"man2.png",
		"man3.png",
                "man4.png",
                "man5.png"
	],
	"female": [
		"woman1.png",
		"woman2.png",
		"woman3.png",
                "woman4.png",
                "woman5.png"
	],
	"executive": {
		"female": [
			"woman3.png"
		],
		"male": [
			"man3.png"
		],
		"all": [
			"woman3.png",
			"man3.png"
		]
	},
	"foreigners": {
		"female": [
			"woman2.png",
                        "woman4.png"
		],
		"male": [
			"man3.png",
                        "man4.png"
		],
		"all": [
			"man3.png",
			"woman2.png",
                        "man4.png",
                        "woman4.png"
		]
	},
	"all": [
		"man1.png",
                "woman1.png",
                "woman2.png",
		"man2.png",
		"man3.png",
                "woman3.png",
                "man4.png",
                "woman4.png",
                "man5.png",
                "woman5.png"
	],
        "conference_room": [
            "man1.png",
            "woman1.png",
            "woman2.png",
            "man2.png",
            "man3.png",
            "woman3.png"
        ]
};
var loading = true;

/**
 * Calls mediator.php to generate tasks for user.
 */
function generateTasks(attempt) {
    var data;
    updateStatusTime();
    var xhr = new XMLHttpRequest();
    xhr.onload = function(e) {
        if(this.responseText) {
            try {
                var r = JSON.parse(this.responseText);
                tasks = r.CurrentTasks;

								// Log tasks generated
								data = {
                    'feature': 'Generate tasks',
                    'value': 'Done',
                    'level': Palmu.LogLevel.INFO
                };
								LogData(data);

                sortTasks();
                gameStatus.Status = r.Status;

                // Add task counts to context
                // data.context = {};
                // if(typeof tasks != "undefined" && window.tasks != void 0 ) {
                //     data.context.available_tasks_count = tasks.length;
                //     // Huonekohtaiset tehtävämäärät!
                //     if(task_queue) {
                //         data.context.tasks_työhuone = task_queue.työhuone.Puhekupla.length + task_queue.työhuone.other.length;
                //         data.context.tasks_käytävä = task_queue.käytävä.Puhekupla.length + task_queue.käytävä.other.length;
                //         data.context.tasks_toimitilat = task_queue.toimitilat.Puhekupla.length + task_queue.toimitilat.other.length;
                //         data.context.tasks_neuvotteluhuone = task_queue.neuvotteluhuone.Puhekupla.length + task_queue.neuvotteluhuone.other.length;
                //         data.context.tasks_kahvihuone = task_queue.kahvihuone.Puhekupla.length + task_queue.kahvihuone.other.length;
                //         data.context.tasks_hallintola = task_queue.hallintola.Puhekupla.length + task_queue.hallintola.other.length;
                //         data.context.tasks_omakuva = task_queue.Omakuva.length;
                //         data.context.tasks_mentori = task_queue.Mentori_.length;
                //         data.context.tasks_puhelu = task_queue.Puhelu.length;
                //     }
                // }

            } catch(event) {
                // Log error from server
                data = {
                    'feature': 'Generate tasks',
                    'value': 'Error',
                    'level': Palmu.LogLevel.ERROR,
                    'context': {'error_msg': event, 'data': this.responseText, "attempt": attempt}
                };
                LogData(data);
                retryGenerateTasks(attempt);
            }
        } else {
            // Log error from server
            data = {
                'feature': 'Generate tasks',
                'value': 'Error',
                'level': Palmu.LogLevel.ERROR,
                'context': {'error_msg': 'No response from server!', "attempt": attempt}
            };
            LogData(data);
            retryGenerateTasks(attempt);
        }
    };
    xhr.onerror = function(e) {
            console.log("error (list tasks)", e);
            // Log error
            data = {
                'feature': 'Generate tasks',
                'value': 'Error',
                'level': Palmu.LogLevel.ERROR,
                'context': {'error_msg': e, "attempt": attempt}
            };
            LogData(data);
            retryGenerateTasks(attempt);
    };
    xhr.onabort = function(e) {
            console.log("abort (list tasks)", e);
            // Log abort
            data = {
                'feature': 'Generate tasks',
                'value': 'Error',
                'level': Palmu.LogLevel.WARN,
                'context': {'error_msg': e, "attempt": attempt}
            };
            LogData(data);
            retryGenerateTasks(attempt);
    };
    xhr.open("POST", "php/mediator.php");
    var dataToSend = gameStatus;
    dataToSend.action = 'generateTasks';
    dataToSend.replayid = replayids.main;
    xhr.send(JSON.stringify(dataToSend));
}

function retryGenerateTasks(attempt) {
    if(!attempt) {
        attempt = 1;
    }
    attempt++;
    if(attempt < 4)
        generateTasks(attempt);
    else
        showAlert('Tehtävien generointi epäonnistui!');
}

/**
 * Sort tasks based on priority/room to task_queue.
 * 1. Show mentor comments immediatelly despite of location
 * 2. Phone despite of location
 * 3. Human tasks/knock on the door when entering room
 * 4. Omakuva tasks only in the beginning of the workday!
 * @returns {undefined}
 */
function sortTasks() {
    task_queue = {
        "Omakuva": [],
        "Mentori_": [],
        "Puhelu": [],
        "työhuone": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        },
        "käytävä": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        },
        "toimitilat": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        },
        "neuvotteluhuone": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        },
        "kahvihuone": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        },
        "hallintola": {
            "Puhekupla": [],
            "other": [],
            "count": 0
        }
    };
    var task, format, choices, task_ids = [];
    var page = $( "body" ).pagecontainer( "getActivePage" ).attr('id');
    updateStatusTime();

    if(tasks.length > 0) {
        for(var i=0;i<tasks.length;i++) {
            task = tasks[i];

            try {
                if (typeof task.StaticParameters == 'string' || task.StaticParameters instanceof String)
                    task.StaticParameters = JSON.parse(task.StaticParameters);
                if (typeof task.TaskDescription.DynamicParameters[0] == 'string' || task.TaskDescription.DynamicParameters[0] instanceof String)
                    task.TaskDescription.DynamicParameters = JSON.parse(task.TaskDescription.DynamicParameters[0]);

                format = task.StaticParameters.format;
                choices = task.StaticParameters.choices;


                // Show Omakuva tasks if start of a new day and mentor comments immediatelly
                if((format === 'Omakuva' && (workdays % 2) === 0 && minutes === 480) || format === 'Mentori_' && choices === 0) {
										task_queue['Omakuva'].push(task);
                    showTask(task);
                } else {
                    if (format === 'Puhelu') {
                        task_queue.Puhelu.push(task);
                        task_queue['työhuone'].other.push(task);
                    } else if(format === 'Puhekupla') {
                        task_queue[task.StaticParameters.location].Puhekupla.push(task);
                    } else if(format === 'Mentori_' && choices === 0) {
                        task_queue.Mentori_.push(task);
                    } else if(format === 'Omakuva') {
                        task_queue['Omakuva'].push(task);
                    } else if(format !== 'Omakuva') {
                        task_queue[task.StaticParameters.location].other.push(task);
                    }

                    if(format !== 'Omakuva')
                        addTaskToMap(task);
                }

                // Add id to the list of added tasks
                task_ids.push(task.TaskID);
            } catch(error) {
                console.log(error);
                console.log(task);
                // Log error in task JSON
                var data = {
                    'feature': 'Sort tasks',
                    'value': 'Error',
                    'level': Palmu.LogLevel.ERROR,
                    'context': {'error_msg': error, 'task': task}
                };
                LogData(data);
            }
        }

        // Check task count
        if(task_ids.length > 0) {
            // Log tasks added to page
            var data = {
                'feature': 'Tasks added to map and canvas',
                'value': 'Done',
                'level': Palmu.LogLevel.INFO,
                'context': {'added_tasks': task_ids}
            };
            LogData(data);

            checkPageTasks(page);
        } else { // Something has gone wrong.
            var data = {
                'feature': 'Sort tasks',
                'value': 'Error',
                'level': Palmu.LogLevel.ERROR,
                'context': {'error_msg': 'task_ids is empty'}
            };
            LogData(data);
            showAlert('Tehtävien lisääminen epäonnistui!');
        }
    } else {
        // GAME OVER
        gameOver();
    }
}

/**
 * Called on page load and after generating new tasks. Checks if there are task to be shown immediatelly or audio to be played.
 * @param {String} page page id
 */
function checkPageTasks(page) {
    if(!selectedTask && $(".ui-popup-active, .ui-dialog.ui-page-active").length === 0) {
        var room = getRoomByDivId(page);
        if(task_queue.Mentori_.length > 0) {
            showTask(task_queue.Mentori_[0]);
            task_queue.Mentori_.splice(0, 1);
        } else if(task_queue.Omakuva.length > 0) {
            showTask(task_queue.Omakuva[0]);
            task_queue.Omakuva.splice(0, 1);
        } else if(task_queue.Puhelu.length > 0) {
            showTask(task_queue.Puhelu[0]);
            task_queue.Puhelu.splice(0, 1);
        } else if(room) {
            var key = room.key;
            if(task_queue[key].Puhekupla.length > 0) {
                if(page !== 'office') {
                    showTask(task_queue[key].Puhekupla[0]);
                } else if(audio_on) { // Knock on door, TODO: Make sure graphics loaded before knocking
                    audio.loop = false;
                    audio.setAttribute("src","sounds/knock-on-door.mp3");
                    audio.load();
                    audio.play();
                }
                task_queue[key].Puhekupla.splice(0, 1);
            }
        }
    }
    if(loading) {
        loading = false;
        $('#loading').hide();
    }
    return;
}

/**
 * Called after selecting an option. Saves the answer and checks if worktime over
 * or should new task be generated.
 *
 * @param {String} taskID
 * @param {Int} optionIndex
 * @param {Object} task
 * @param {String} open_answer
 * @param {Int} attempt
 */
function selectOption(taskID, optionIndex, task, open_answer, attempt) {
    updateStatusTime();
    var data = {
        'feature': 'Select option',
        'value': 'Saved',
        'level': Palmu.LogLevel.INFO,
        'parameters': {'taskID': taskID, 'optionIndex': optionIndex, "attempt": attempt}
    };
    var xhr = new XMLHttpRequest();
    xhr.onload = function(e) {
        if(this.responseText) {
            saveAnswerUI(optionIndex, taskID, open_answer, task);

            try {
                var r = JSON.parse(this.responseText);
                gameStatus.Status = r.Status;

                setupPoints();

                // TODO: Add animation to clock
                // Check if skipping days
                var duration = ( parseInt(r.Status.Aika) - gameStatus.Status.UI_Aika);
                if(duration > 480) {
                    setupTime( workdays + Math.floor(duration / 480), 480 );
                } else {
                    var newTime = minutes+duration;
                    //minutes += duration;
                    if(minutes !== newTime) {
                        minutes = newTime -1 ;
                        animateUpdateClock(newTime);
                    }
                }
                seconds = 0;
                gameStatus.Status.UI_Aika = parseInt(r.Status.Aika);

                if(checkWorktimeLeft( true )) {
                    loading = true;
                    setTimeout(function() {
                        if(loading)
                            $('#loading').show();
                    }, 500);
                    generateTasks();
                }

                immediateFollowup = null;
                // Answer click already logged, only log if save fails?
                //LogData(data);

            } catch(error) { // Problem with response received from server
                // Log error in status
                data.value = 'Error';
                data.level = Palmu.LogLevel.ERROR;
                data.context = {'error_msg': error, "data":this.responseText, "attempt": attempt};
                LogData(data);

                if(this.responseText.indexOf('No uid in session! I die.') > -1) {
                    sessionExpired();
                } else if(this.responseText.indexOf('duplicate key value violates unique constraint') > -1) {
                    // Try restarting the game
                    startGame();
                } else
                    showAlert('Palvelimelta saatiin virheellinen vastaus tallennettaessa vastausta kysymykseen '+taskID+'!');
            }

        } else {
            // Log selection error from server
            data.value = 'Error';
            data.level = Palmu.LogLevel.ERROR;
            data.context = {'error_msg': 'No response from server!', "attempt": attempt};
            LogData(data);

            if(!attempt || attempt < 4)
                retrySelectOption(e, taskID, optionIndex, task, open_answer, attempt);
            else
                showAlert('Palvelimeen ei juuri nyt saada yhteyttä! Yritä lataamalla sivu uudelleen.');
        }
    };
    xhr.onerror = function(e) {
            // Log selection error
            data.value = 'Error';
            data.level = Palmu.LogLevel.ERROR;
            data.context = {'error_msg': e, "attempt": attempt};
            LogData(data);

            if(!attempt || attempt < 4)
                retrySelectOption(e, taskID, optionIndex, task, open_answer, attempt);
            else
                showAlert('Vastauksen tallentaminen kysymykseen '+taskID+' epäonnistui.');
    };
    xhr.onabort = function(e) {
            // Log selection abort
            data.value = 'Abort';
            data.level = Palmu.LogLevel.ERROR;
            data.context = {'error_msg': e, "attempt": attempt};
            LogData(data);

            if(!attempt || attempt < 4)
                retrySelectOption(e, taskID, optionIndex, task, open_answer, attempt);
            else
                showAlert('Vastauksen tallentaminen kysymykseen '+taskID+' epäonnistui.');

    };

    saveAnswerTimeNew(task);

    xhr.open("POST", "php/mediator.php");

    // TODO: Don't add more stuff to actual gameStatus!
    var dataToSend = gameStatus;
    dataToSend.action = 'choice';
    dataToSend.taskID = taskID;
    dataToSend.optionIndex = optionIndex;
    dataToSend.questiondata = task;
    dataToSend.answer_text = (open_answer)? open_answer: '';
    dataToSend.uid = user.id;
    dataToSend.gamedate = workdays + 1;
    dataToSend.answeringtime = task.answeringtime;
    dataToSend.replayid = replayids.main;
    xhr.send(JSON.stringify(dataToSend));

}

/**
 * Adds answer to UI and local variable answers_new
 * @param {type} optionIndex
 * @param {type} taskID
 * @param {type} open_answer
 * @param {type} task
 */
function saveAnswerUI(optionIndex, taskID, open_answer, task) {
    if(optionIndex.indexOf(",") === -1) {
        optionIndex = parseInt(optionIndex);
    }
    // Add answer to UI and answers_new
    appendAnswer( {"answer_index": optionIndex, "answer_text": open_answer}, task );

    if(!answers_new)
        answers_new = {};
    answers_new[taskID] = {"answer_index": optionIndex, "answer_text": open_answer, "questiondata": task};
}

/**
 * Clled when saving selected option fails. Max number of attempts = 3.
 * @param {Object} e
 * @param {type} taskID
 * @param {type} optionIndex
 * @param {type} task
 * @param {type} open_answer
 * @param {type} attempt
 */
function retrySelectOption(e, taskID, optionIndex, task, open_answer, attempt) {
    if(!attempt || attempt < 4) { // Retry max 3 times
        if(!attempt)
            attempt = 1;
        attempt++;
        selectOption(taskID, optionIndex, task, open_answer, attempt);
    }
}

/**
 * Adds all tasks to map e.g. after resize.
 */
function addTasksToMap() {
    var task;
    clearTasks();

    for(var i=0;i<tasks.length;i++) {
        task = tasks[i];
        if(task.displayed) {
            addTaskToMap(task);
        }
    }
}

/**
 * Adds the given task to the frontpage a.k.a "map".
 * @param {Object} task
 */
function addTaskToMap(task) {
    var x,y, star, coordinates, count, data2;
    if(rooms[task.StaticParameters.location]) {
        task.displayed = true;
        count = task_queue[task.StaticParameters.location].count;
        coordinates = rooms[task.StaticParameters.location].task_coordinates;

        if(task.StaticParameters.location !== 'käytävä') {
            x = scaleValue(coordinates[0] + (35 * count), main_scale) + main_centering[0];
            y = scaleValue(coordinates[1], main_scale) + main_centering[1];
        } else {
            x = scaleValue(coordinates[0] + (35 * count), main_scale) + main_centering[0];
            y = scaleValue(coordinates[1], main_scale) + main_centering[1];
        }
        star = new Konva.Star({
            x: x,
            y: y,
            numPoints: 5,
            innerRadius: scaleValue(8, main_scale),
            outerRadius: scaleValue(16, main_scale),
            fill: 'yellow',
            stroke: 'yellow',
            strokeWidth: 0
        });
        task.shape_id = star._id;

        // Add event handlers to Konva object
        var data = {
            'parameters': {"taskID":task.TaskID},
            'level': Palmu.LogLevel.INTERACTION
        };

        star.on('click tap', function() {
            document.body.style.cursor = 'default';
            var task = getTaskByProperty("shape_id", this._id);
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#" + rooms[task.StaticParameters.location].page_id );
            showTask(task);
            data.feature = 'Task click (star)';
            LogData(data);
        });

        // star.cache();
        // star.filters([Konva.Filters.Brighten]);
        star.on('mouseover', function() {
            document.body.style.cursor = 'pointer';
            // this.brightness(0.6);
						// this.strokeWidth(5);
						this.scale({x : 1.5, y : 1.5});
            task_layer.batchDraw();

           	data.feature = 'Task mouseover (star)';
          	LogData(data);
        });
        star.on('mouseout', function() {
            document.body.style.cursor = 'default';
            // this.brightness(0);
						// this.strokeWidth(0);
						this.scale({x : 1, y : 1});
            task_layer.batchDraw();

           	data.feature = 'Task mouseout (star)';
            LogData(data);
        });


        // add the shape to the layer
        task_layer.add(star);

        // Add clickable element on page
        addTaskElementToPage(task);

        // add the layer to the stage
        map_view.stage.add(task_layer);
        task_queue[task.StaticParameters.location].count++;

        // Log task added to landing page
//        data2 = {
//            'feature': 'Task added to map',
//            'parameters': {"taskID":task.TaskID},
//            'level': Palmu.LogLevel.INFO
//        };
//        LogData(data2);

    } else {
        console.log(task.StaticParameters.location + ' or ' + task.StaticParameters.format + ' not defined!');
        data2 = {
            'feature': 'Task not defined',
            'parameters': {"task": task, "taskID":task.TaskID},
            'level': Palmu.LogLevel.DEBUG,
            'context': { 'error_msg': task.StaticParameters.location + ' or ' + task.StaticParameters.format + ' not defined!'}
        };
        LogData(data2);
    }
}

/**
 * Clears all tasks from rooms and map.
 */
function clearTasks() {
    if(task_layer)
        task_layer.destroy();
    task_layer = new Konva.Layer();
    map_view.layers.task_layer = task_layer;
    $('.task_element').remove();

    // Clear tasks from rooms
    $.each(rooms, function(key, element) {
        if(element.layers && element.layers.tasks) {
            element.layers.tasks.destroy();
            task_queue[key].count = 0;
        }
    });

    // Log tasks cleared
    var data = {
        'feature': 'Tasks cleared',
        'level': Palmu.LogLevel.INFO
    };
    LogData(data);
}

/**
 * Add assignment elements to each room.
 * @param {Object} task
 */
function addTaskElementToPage(task) {
    // Get the space and element to add
    var page = $("#" + rooms[task.StaticParameters.location].page_id);
    var format = task.StaticParameters.format;
    var task_element;
    var view = rooms[task.StaticParameters.location];
    var element = rooms[task.StaticParameters.location].elements[format];
    var data = {
            'parameters': {"taskID":task.TaskID}
        };
    // Add the element to that page if necessary, or change existing elements class to 'active
    switch(format) {
        case 'Mentori_':
                task_element = page.append('<div class="mentor_task task_element"><img src="img/elements/mentor_bubble.png" class="mentor_bubble">\n\
                                            <div class="button_container mentor_container task_element active">\n\
                                            <a href="#mentorModal-task" data-rel="popup">\n\
                                                <div class="mentor avatar round-button active">\n\
                                                    <img class="icon" alt="Mentori" src="img/icons/mentor_male.png" />\n\
                                                </div>\n\
                                            </a>\n\
                                        </div></div>');
                task_element = task_element.find('.mentor_task');

                // Log task element added
//                data.feature =  'Task element added to page';
//                data.level =  Palmu.LogLevel.INFO;
//                LogData(data);

            break;
        case 'Puhekupla':
            // Check what image to load
            var gender = (task.TaskDescription.DynamicParameters.speakerGender) ? task.TaskDescription.DynamicParameters.speakerGender : null;
            var attr = (task.TaskDescription.DynamicParameters.peopleAttribute) ? task.TaskDescription.DynamicParameters.peopleAttribute : null;
            var number = (task.TaskDescription.DynamicParameters.numberOfPeople) ? task.TaskDescription.DynamicParameters.numberOfPeople : 1;
            var img = (task.StaticParameters.character) ? 'img/people/active/'+task.StaticParameters.character : null;
            var random_index, img_options, active = false;
            var active_index = Math.floor((Math.random() * (number - 1)) + 0);


            if(!task.images)
                task.images = [];

            for(var i=0;i<number;i++) {
                if(!img) {
                    if(!task.images[i]) {
                        var url = 'img/people/';
                        if(task.StaticParameters.location === 'neuvotteluhuone') {
                            img_options = people_images.conference_room;
                            url = 'img/people/conference/';
                        } else {
                            if(gender && attr) {
                                img_options = people_images[attr][gender];
                            } else if(attr) {
                                img_options = people_images[attr].all;
                            } else if(gender) {
                                img_options = people_images[gender];
                            } else {
                                img_options = people_images.all;
                            }
                        }
                        if(!img_options)
                            img_options = people_images.all;

                        if(number === 1)
                            random_index = Math.floor((Math.random() * (img_options.length - 1)) + 0);
                        else
                            random_index = i;

                        if(active_index === i) {
                            active = true;
                            if(img_options[random_index])
                                img = url + 'active/' + img_options[random_index];
                            else
                                img = url + 'active/man1.png';
                        } else {
                            active = false;
                            if(img_options[random_index])
                                img = url + img_options[random_index];
                            else
                                img = 'img/people/man1.png';
                        }

                        if(!img)
                            img = 'img/elements/human_bubble.png';

                        task.images.push({img: img, active: active});
                    } else {
                        img = task.images[i].img;
                        active = task.images[i].active;
                    }
            } else {
                active = true;
            }

                loadTaskImage((active)? 'human_active': 'human', img, task, element, view, active, (task.StaticParameters.location === 'työhuone') ? 0: i);
                img = null;
            }

            if(task.StaticParameters.location === 'työhuone')
                loadTaskImage('door', 'img/elements/door_open.png', task, rooms['työhuone'].elements.Ovi, view);
            else if(task.StaticParameters.location === 'kahvihuone')
                loadTaskImage('table', 'img/elements/kitchen_table.png', task, rooms.kahvihuone.elements['Pöytä'], view, false);

            break;
        case 'Sähköposti_':
            loadTaskImage('email', 'img/elements/email_active.png', task, element, view);
            break;
        case 'Omakuva':
            break;
        case 'Viesti aloitelaatikossa':
            loadTaskImage('feedback', 'img/elements/suggestions_box_active.png', task, element, view);
            break;
        case 'Puhelu':
            element = rooms['työhuone'].elements.Puhelu;
            view = rooms['työhuone'];
            loadTaskImage('phone', 'img/elements/phone_active.png', task, element, view);
            break;
        case 'Johtajan oma mietintäkupla':
            task_element = page.append('<img class="think_bubble active task_element" src="img/elements/think_bubble.png" alt="Valitse tehtävä" />');
            task_element = task_element.find('.think_bubble.active');

            // Log task element added
//            data.feature =  'Task element added to page';
//            data.level =  Palmu.LogLevel.INFO;
//            LogData(data);
            break;
        case 'Pöydällä lehden etusivulla':
            loadTaskImage('magazine', 'img/elements/magazine.png', task, element, view);
            break;
        case 'Roska lattialla':
            loadTaskImage('trash', 'img/elements/trash_active.png', task, element, view);
            break;
        default:
            alert('Format '+format+' not implemented!');
    }

		// Event handlers for task_element
		if(task_element) {
			task_element.click( function() {
					showTask(task);
					// Log task element clicked
					data.level = Palmu.LogLevel.INTERACTION;
					data.feature = 'Task click';
					LogData(data);
			});

			task_element.on('mouseover', function() {
					// Log task element clicked
					data.level = Palmu.LogLevel.INTERACTION;
					data.feature = 'Task mouseover';
					LogData(data);
			});

			task_element.on('mouseout', function() {
					// Log task element clicked
					data.level = Palmu.LogLevel.INTERACTION;
					data.feature = 'Task mouseover';
					LogData(data);
			});
		}

}

/** Loads task images to the canvas.
 *
 * @param {string} imageId - Id to be given for the Konva image
 * @param {string} src - Image source
 * @param {object} task - Connected task
 * @param {object} element - Connected element
 * @param {object} view - The view where to add the image
 * @param {boolean} active - Denotes wheter the element is clickable or not (optional)
 * @param {int} number - With multiple images connected to the same task, this denotes the running number of the elements
 */
function loadTaskImage(imageId, src, task, element, view, active, number) {
    var active_element = (!active && active !== false || active === true);
    var image = new Image();

    image.onload = function () {
        var offset, height, width, x, y, image_src;
        image_src = src.substring(src.lastIndexOf('/')+1);
        if(view.page_id !== 'neuvotteluhuone') {
            offset = (number) ? number * scaleValue(image.width - 90, scale) : 0;
            height = scaleValue(((active_element || imageId !== 'human')? image.height : image.height - 70), scale);
            width = scaleValue(((active_element || imageId !== 'human')? image.width : image.width - 70), scale);
            x = scaleValue(element.css.left, scale) + ((imageId === 'human'|| imageId === 'human_active' || imageId === 'door') ? ((centering[0] > 0) ? 0 : centering[0] - 40) : centering[0]) + offset;
            y = scaleValue(element.css.top, scale) + centering[1];
        } else { // People in the conference room are defined in rooms.neuvotteluhuone.elements
            height = scaleValue(image.height, scale);
            width = scaleValue(image.width, scale);
            x = scaleValue(rooms.neuvotteluhuone.elements[image_src].css.left, scale) + centering[0];
            y = scaleValue(rooms.neuvotteluhuone.elements[image_src].css.top, scale) + centering[1];
        }

        // Create the Konva image object
        var el_img = new Konva.Image({
            x: x,
            y: y,
            image: image,
            width: width,
            height: height,
            id: imageId,
            name: imageId,
            shadowColor: 'black',
            shadowBlur: 5,
            shadowOffset: {x : scaleValue(4, scale), y : scaleValue(4, scale)},
            shadowOpacity: 0.5
        });

        if(!view.layers)
            view.layers = {};
        if(!view.layers.tasks)
            view.layers.tasks = new Konva.Layer();

        // add the shape to the layer
        view.layers.tasks.add(el_img);
        view.stage.add(view.layers.tasks);
        var data = {
                'value': imageId,
                'parameters': {"taskID":task.TaskID, "imgSrc": image_src},
                'level': Palmu.LogLevel.INTERACTION
            };

        // Attach event handlers for clikcable elements
        if(active_element) {
            el_img.cache();
            el_img.drawHitFromCache();
            el_img.filters([Konva.Filters.Brighten]);
            el_img.on('mouseover', function() {
                document.body.style.cursor = 'pointer';
                this.brightness(0.2);
                view.layers.tasks.batchDraw();

               data.level = Palmu.LogLevel.INTERACTION;
               data.feature = 'Task mouseover';
               LogData(data);
            });
            el_img.on('mouseout', function() {
                document.body.style.cursor = 'default';
                this.brightness(0);
                view.layers.tasks.batchDraw();

               data.level = Palmu.LogLevel.INTERACTION;
               data.feature = 'Task mouseout';
               LogData(data);
            });
            el_img.on('click tap', function() {
                document.body.style.cursor = 'default';
                var element = this;
                element.brightness(0.2);
                view.layers.tasks.batchDraw();

                setTimeout(function(){
                    element.brightness(0);
                    view.layers.tasks.batchDraw();
                }, 500);

                // Open task
                showTask(task);

                data.level = Palmu.LogLevel.INTERACTION;
                data.feature = 'Task click';
                LogData(data);

            });
        }
        element.id = el_img._id;

        // Make sure order of the images is correct
        if(imageId === 'human') {
            if(view.page_id !== 'neuvotteluhuone') {
                // Move to back
                el_img.moveToBottom();

                // Make sure active speaker is on top
                var shape = view.stage.find('.human_active')[0];
                if(shape) {
                    shape.moveToTop();
                }
            } else {
                // Move people behind the table to bottom, and people in front to top
                if(rooms.neuvotteluhuone.elements[image_src].css.position === 'top') {
                    el_img.moveToTop();
                } else
                    el_img.moveToBottom();
            }

        } else if(imageId === 'door' || imageId === 'table' || imageId === 'human_active') {
            if(view.page_id !== 'neuvotteluhuone') {
                // Move to top
                el_img.moveToTop();
            } else {
                // Move people behind the table to bottom, and people in front to top
                if(rooms.neuvotteluhuone.elements[image_src].css.position === 'top') {
                    el_img.moveToTop();
                } else
                    el_img.moveToBottom();
            }
        } else {
            el_img.moveToBottom();
        }

        // Make sure the door and coffee table are always on top!
        if(view.page_id === 'office') {
            var door = view.stage.find('.door')[0];
            if(door) {
                door.moveToTop();
            }
        } else if(view.page_id === 'kahvihuone') {
            var table = view.stage.find('.table')[0];
            if(table) {
                table.moveToTop();
            }
        }

        view.layers.tasks.draw();

        // Log image added to canvas
//        data.feature =  'Task image added to canvas';
//        data.level =  Palmu.LogLevel.INFO;
//        LogData(data);
    };

    image.src = src;
}

/**
 *
 * @param {String} property
 * @param {String} value
 * @returns {Object} Task if found, else null
 */
function getTaskByProperty(property, value) {
    for(var i=0;i<tasks.length;i++) {
        if(tasks[i][property] === value)
            return tasks[i];
    }
    return null;
}

/**
 * Displays the task selected by the user.
 * @param {Object} task
 */
function showTask(task) {
    selectedTask = task;
    var view = rooms[task.StaticParameters.location];

    immediateFollowup = (task.StaticParameters.immediateFollowup) ? task.StaticParameters.immediateFollowup : null;

    var format = task.StaticParameters.format;
    switch(format) {
        case 'Mentori_':
            if(task.TaskDescription.Choice.length === 0) {
                showMentorComment(task);
            } else
                showModalTask( $('#mentorModal-task'), '.mentor_container.active', true );
            break;
        case 'Puhekupla':
            if(task.StaticParameters.location === 'työhuone') {
                var active_shape = view.stage.find('.human_active')[0];
                var other_shapes = view.stage.find('.human');
                var shape;

                if(active_shape) {
                    var tween = new Konva.Tween({
                        node : active_shape,
                        x : 180 * scale,
                        duration : 0.5,
                        onFinish : function() {
                             // remove all references from Konva
                             tween.destroy();
                             // Move to top
                             active_shape.moveToTop();
                             view.layers.tasks.draw();
                        }
                    });
                    tween.play();
                }
                if(other_shapes) {
                    for(var i=0;i<other_shapes.length;i++) {
                        shape = other_shapes[i];
                        if(shape) {
                            var tween2 = new Konva.Tween({
                                node : shape,
                                x : 180 * scale * (i+2),
                                duration : 0.5 * (i+2),
                                onFinish : function() {
                                     // remove all references from Konva
                                     tween2.destroy();
                                }
                            });
                            tween2.play();
                        }
                    }
                }
            }
            showModalTask( $('#speechModal'), '#human', true, true );
            break;
        case 'Sähköposti_':
            var modal = $('#emailModal');
            // Set subject
            modal.find('.subject').html((task.TaskDescription && task.TaskDescription.DynamicParameters && task.TaskDescription.DynamicParameters.subject)?task.TaskDescription.DynamicParameters.subject:'');
            // Set sender
            modal.find('.sender').html((task.TaskDescription && task.TaskDescription.DynamicParameters && task.TaskDescription.DynamicParameters.from)?task.TaskDescription.DynamicParameters.from:'');
            showModalTask( modal, '#email', true, true );
            break;
        case 'Omakuva':
            showModalTask( $('#requiredModal'), '.mirror.active', true );
            break;
        case 'Viesti aloitelaatikossa':
            showModalTask( $('#messageModal'), '#feedback', true, true );
            break;
        case 'Puhelu':
            if(task.shown)
                showModalTask( $('#plainModal'), '#phone', true, true );
            else {
                phoneRinging();
            }
            break;
        case 'Johtajan oma mietintäkupla':
            showModalTask( $('#plainModal'), '.think_bubble.active', true );
            break;
        case 'Pöydällä lehden etusivulla':
            showModalTask( $('#plainModal'), '#magazine', true, true );
            break;
        case 'Roska lattialla':
            showModalTask( $('#plainModal'), '#trash', true, true );
            break;
        default:
            alert('Format '+format+' not implemented!');
    }
}

/**
 * Displays a modal task.
 * @param {Object} modal
 * @param {Object} element
 * @param {Boolean} remove
 */
function showModalTask( modal, element, remove) {
    var page = $("#" + rooms[selectedTask.StaticParameters.location].page_id);
    var content = modal.find('.modalContent');
    content.empty();
    content.append('<span class="testing_mode qid">'+selectedTask.TaskID+'</span>');
    content.append('<div class="task_description">'+selectedTask.TaskDescription.Description+'</div>');

    updateQuestionShowcountNew(selectedTask);

    // Get displaytime
    var date = new Date();
    questionShown = date.getTime();
    var form = $('<form>')
    var fieldset = $('<fieldset data-role="controlgroup">');
    var selectDiv = $('<div class="ui-field-contain">');
    var select = $('<select name="typeSelect" id="typeSelect">');
    if(selectedTask.TaskDescription.Choice.length > 0) {
				if(selectedTask.TaskDescription.DynamicParameters.prompt)
        	content.append('<div class="prompt">'+selectedTask.TaskDescription.DynamicParameters.prompt+'</div>');
        // Add options
        for(var i=0;i<selectedTask.TaskDescription.Choice.length;i++) {
            var last = (i===(selectedTask.TaskDescription.Choice.length-1)) ? true : false;
            if(selectedTask.TaskDescription.Choice[i].StaticParameters && (typeof selectedTask.TaskDescription.Choice[i].StaticParameters == 'string' || selectedTask.TaskDescription.Choice[i].StaticParameters instanceof String))
                selectedTask.TaskDescription.Choice[i].StaticParameters = JSON.parse(selectedTask.TaskDescription.Choice[i].StaticParameters);
            if(selectedTask.openAnswer || (selectedTask.TaskDescription.Choice[i].StaticParameters && selectedTask.TaskDescription.Choice[i].StaticParameters.type === 'openQuestion')) {
                var div = $('<div class="openAnswer_container">');
                if(selectedTask.StaticParameters && (selectedTask.StaticParameters.multipleChoice || selectedTask.StaticParameters.dropDown) && i===(selectedTask.TaskDescription.Choice.length-1)) {
										if(selectedTask.StaticParameters.multipleChoice) {
											var input = $('<input name="'+i+'" id="'+i+'" data-index="'+i+'" type="checkbox">\n\
	                               <label for="'+i+'">'+selectedTask.TaskDescription.Choice[i].Description+'</label>');
	                    fieldset.append(input);
	                    fieldset.append('<textarea data-task="'+selectedTask.TaskID+'" data-index="'+i+'" ></textarea>');
                    	addCheckboxToDOM(last, i);
										} else if(selectedTask.StaticParameters.dropDown) {
											// First add option to select
											select.append('<option value="'+i+'">'+selectedTask.TaskDescription.Choice[i].Description+'</option>');
											addSelectToDOM(last, i);

											// Create the textarea and add event handler to select so that textarea only shown with this selection
											var textarea = $('<textarea data-task="'+selectedTask.TaskID+'" data-index="'+i+'" class="hidden"></textarea>');
											var index = i;
											select.change(function() {
												if(parseInt($(this).val()) === index)
													textarea.removeClass('hidden');
												else {
													textarea.addClass('hidden');
												}
											});
	                    selectDiv.append(textarea);

										}
                } else {
                    if(selectedTask.TaskDescription.Choice[i].Description)
                        div.append('<span data-task="'+selectedTask.TaskID+'" data-index="'+i+'" >'+selectedTask.TaskDescription.Choice[i].Description+'</span>');
                    div.append('<textarea data-task="'+selectedTask.TaskID+'" data-index="'+i+'" ></textarea>');
                    if(last)
                        div.append('<button data-task="'+selectedTask.TaskID+'" data-index="'+i+'" >Vastaa</button>');
                    content.append(div);
                }

            } else if(selectedTask.StaticParameters && selectedTask.StaticParameters.multipleChoice) {
                var input = $('<input name="'+i+'" id="'+i+'" data-index="'+i+'" type="checkbox">\n\
                               <label for="'+i+'">'+selectedTask.TaskDescription.Choice[i].Description+'</label>');
                fieldset.append(input);
                if(last) {
                    addCheckboxToDOM(true, i);
                }
            } else if(selectedTask.StaticParameters && selectedTask.StaticParameters.dropDown) {
                select.append('<option value="'+i+'">'+selectedTask.TaskDescription.Choice[i].Description+'</option>');
                if(last) {
                    addSelectToDOM(true, i);
                }
            } else
                content.append('<button data-task="'+selectedTask.TaskID+'" data-index="'+i+'" >'+selectedTask.TaskDescription.Choice[i].Description+'</button>');
        }
    } else if(selectedTask.TaskDescription.DynamicParameters.prompt) {
        content.append('<button class="prompt">'+selectedTask.TaskDescription.DynamicParameters.prompt+'</button>');
    }
    content.trigger('create');

    var task = selectedTask;
    var data = {
        'parameters': {"taskID":task.TaskID},
        'level': Palmu.LogLevel.INTERACTION
    };
		if(modal)
    	modal.popup( "open" );
    modal.find("button").click( function() {
        if($(this).attr('data-task')) { // Question answered
            var open_answer = $(this).parent().find('textarea[data-index="'+$(this).attr('data-index')+'"]');
            if(open_answer.length > 0) {
                open_answer = open_answer.val();
            } else
                open_answer = null;

            if($(this).attr('data-type') === 'checkbox') {
                var indexes = [];
                $(this).parent().find('input[type="checkbox"]').each(function() {
                    if($(this).prop("checked"))
                        indexes.push($(this).attr('data-index'));
                });
                selectOption($(this).attr('data-task'), indexes.join(), task, open_answer, 1);
            } else if($(this).attr('data-type') === 'dropdown') {
                var index = $('#typeSelect').val();
                selectOption($(this).attr('data-task'), index, task, open_answer, 1);
            } else {
                selectOption($(this).attr('data-task'), $(this).attr('data-index'), task, open_answer, 1);
            }
        } else {
            generateTasks();
        }

        modal.popup( "close" );
        content.empty();
        if(!remove && element) {
            page.off('click tap', element);
            content.append('<h3>Ei aktiivisia tehtäviä!</h3>');
            page.find(element).removeClass('active');
        }
        clearTasks();

        data.feature = 'Answer task';
        data.value = $(this).attr('data-index');
        LogData(data);
    });

		modal.find("button").on('mouseover', function() {
			if($(this).attr('data-task')) {
				data.feature = 'Answer task mouseover';
				data.value = $(this).attr('data-index');
				LogData(data);
			}
		});

		modal.find("button").on('mouseout', function() {
			if($(this).attr('data-task')) {
				data.feature = 'Answer task mouseout';
				data.value = $(this).attr('data-index');
				LogData(data);
			}
		});

    // Change human image
    if(element && element === '.human.task_element')
        page.find('.human.task_element').attr('src', 'img/elements/human.png');

    selectedTask = null;

    function addCheckboxToDOM(addAnswerBtn, i) {
        form.append(fieldset);
        content.append(form);
        if(addAnswerBtn)
            content.append('<button data-task="'+selectedTask.TaskID+'" data-index="'+i+'" data-type="checkbox" >Vastaa</button>');
    }

    function addSelectToDOM(addAnswerBtn, i) {
        selectDiv.append(select);
        content.append(selectDiv);
        if(addAnswerBtn)
            content.append('<button data-task="'+selectedTask.TaskID+'" data-index="'+i+'" data-type="dropdown" >Vastaa</button>');
    }
}

/**
 * Displays a comment from the mentor.
 * @param {Object} task
 */
function showMentorComment(task) {
    var modal = $('#mentorModal-comment');
    var content = modal.find('.modalContent');
    content.empty();
    content.append('<span class="testing_mode qid">'+task.TaskID+'</span>');
    content.append('<div class="task_description">'+task.TaskDescription.Description+'</div>');

    updateQuestionShowcountNew(task);
		if(task.TaskDescription.DynamicParameters.prompt)
    	content.append('<button class="prompt">'+task.TaskDescription.DynamicParameters.prompt+'</button>');
    content.trigger('create');
    modal.popup( "open" );

    $("#mentorModal-comment button").click( function() {
        $("#mentorModal-comment").popup( "close" );
        var data = {
            'value': 'OK',
            //'parameters': {"task": task, "taskID":task.TaskID},
						'parameters': {"taskID":task.TaskID},
            'level': Palmu.LogLevel.INTERACTION
        };
        data.feature = 'Mentor comment';
        LogData(data);
    });

    selectedTask = null;
}

/**
 * Displays the phone and plays audio if not muted.
 */
function phoneRinging() {
    if(selectedTask.TaskDescription.DynamicParameters.caller)
        $('#caller_id').html(selectedTask.TaskDescription.DynamicParameters.caller);
    else
        $('#caller_id').html("Tuntematon numero");
    if(audio_on) {
        audio.loop = true;
        audio.setAttribute("src","sounds/Phone Ringing.mp3");
        audio.load();
        audio.play();
    }
    $('#phoneModal').popup( "open" );
    selectedTask.shown = true;

    // Log phone shown
    var data = {
        'feature': 'Phone ringing',
        // 'parameters': {"task": selectedTask, "taskID":selectedTask.TaskID},
				'parameters': {"taskID":selectedTask.TaskID},
        'level': Palmu.LogLevel.INFO
    };
    LogData(data);
}

/**
 * Called when user has decided to answer the phone.
 */
function answerPhone() {
    $('#phoneModal').popup( "close" );
    showModalTask( $('#plainModal'), '.phone.active', true );
    audio.pause();
}

/**
 * Called when user dismisses a call.
 */
function dissmissCall() {
    $('#phoneModal').popup( "close" );
    audio.pause();
    selectedTask = null;
}
