/*jshint multistr: true, laxbreak: true */

/**
 * @file Event handlers that are set onload and some general functions.
 * @author Katariina Tiitinen
 *
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */
var use_database = true;
var gameStatus = {};
var audio = new Audio();
var audio_on = true;
var svg_support = false;
var resize_timeout = null;
var gameover_shown = false;
var base_url = location.href.substring(0, location.href.lastIndexOf("/")+1);
var user_id = null;
var windowHeight;
var windowWidth;
var defaultLang = "fi";
var base_url = location.href.substring(0, location.href.lastIndexOf("/")+1);
var page = (location.href.lastIndexOf("#") !== -1)?location.href.substring(location.href.lastIndexOf("#")+1, location.href.length):'';
var excludedQuestiongroups = [];

// Check login. TODO: remove all async functions
$( document ).on( "mobileinit", function() {
    $.mobile.autoInitializePage = false;
    $.ajaxSetup({ async: false });
    $.post('php/login.php', function(data) {
        // Check cookie
        var lang = Cookies.get('language');
        if(data.state !== 'success' && data.state !== 'logged_in' ) {
            if(lang && lang === 'fi' || !lang && defaultLang === "fi")
                window.location.href = base_url + "login"+".html"; // Prevent being overwritten by translation script
            else
                window.location.href = base_url + "login_en.html";
        } else {
            if(lang && lang !== defaultLang)
                window.location.href = base_url + "index_en"+".html" + "#"+page;
            user_id = data.id;
            $('body').css('display', 'block');
        }
    });
    $.ajaxSetup({ async: true });
});

$(document).ready(function () {

    //Fix for nodeName undefined error on IE
    $.widget("mobile.popup", $.mobile.popup, {
      _safelyBlur: function (currentElement) {
          try {
              this._super();
          } catch (ex) {
          }
      }
    });

    $.mobile.initializePage();
    windowHeight = $(window).height();
    windowWidth = $(window).width();
    registerStaticLogEventListeners();

    // Set scrolltop on popups when opened to 0
    $( '[data-role="popup"]').bind({
       popupafteropen: function(event, ui) {
         $(this).find('.pageWrapper').scrollTop( 0 );
       }
    });

    $( "#phoneModal" ).on( "popupcreate", function( event, ui ) {
        setupPhonePosition();
    } );

    $('#changeLocale').change(function () {
        var newLang = $(this).val();

        // Create cookie to remember language
        Cookies.set('language', newLang, { expires: 7 });
        console.log(Cookies.get('language'));

        // Redirect to correct login-page
        if(newLang !== defaultLang) {
            if(newLang != 'fi') {
                console.log('redirect to: '+"index_"+newLang+".html#"+page);
                window.location.replace("index_"+newLang+".html#"+page);
            } else {
                console.log('redirect to: '+"index"+".html#"+page);
                window.location.replace("index"+".html#"+page);
            }
        }
    });

    // Save svg support
    svg_support = document.implementation.hasFeature( "http://www.w3.org/TR/SVG11/feature#BasicStructure", "1.1");

    $( "[data-role='panel']" ).panel().enhanceWithin();
    $( '[data-role="popup"]' ).popup().enhanceWithin();
    if(!use_database) {
        user = {
            "username": "Johtaja1",
            "name": "Nimi1",
            "sex": "m",
            "organization": 1,
            "email": "email"
        };
        $('#usernameInput').html(user.username);

        loadQuestions('questions.json');
    } else
        getUser(user_id);

    $( window ).resize( onResize );

    /*** BUTTONS ***/
    $(document).on('click', '.delete_break', deleteBreak);
    $(document).on('click', '.alert .ui-btn', collapseContent);
    $(document).on('click', 'button.answer', answerQuestion);
    $(document).on('click', 'button.minimize', function() {
        if(currentQuestionset === 'Minä johtajana' || currentQuestionset === 'Hyvinvointi') {
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#office" );
            $('#profilesModal').popup('open');
            loadOldProfiles();
        } else if(currentQuestionset === 'Johtoryhmä') {
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#neuvotteluhuone" );
            $('#johtoryhmaModal').popup('open');
            $('#question-content .dynamic').empty();
            printAnswersForTopic( $('#johtoryhma .answers_container'), 'Johtoryhmä' );
        } else if(currentQuestionset === 'Ihannetyöpäivä') {
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#office" );
            $('#workdayModal').popup('open');
            $('#question-content .dynamic').empty();
            printAnswersForTopic( $('#workday .answers_container'), 'Ihannetyöpäivä' );
        }
    });
    $('#saveFeedback').click(saveFeedback);
    $('#updatePwd').click(updatePwd);
    $('.toggleUpdatePassword').click( function() {
        $('#updatePassword').toggle();
        if($(this).hasClass('ui-icon-minus')) {
            $(this).removeClass('ui-icon-minus').addClass('ui-icon-plus');
            $(this).find('input').attr('data-icon', 'plus');
        } else {
            $(this).removeClass('ui-icon-plus').addClass('ui-icon-minus');
            $(this).find('input').attr('data-icon', 'minus');
        }
    });
    $('#updateUser').on("click", updateUser);
    $('#editUsername').click(function() { $('#usernameInput').select(); } );
    $('#reset_game, #reset_game_user').click(resetGame);
    $('#dismissChanges').on("click", setProfile);
    $('#workday_results button').click( function() {
        $( ":mobile-pagecontainer" ).pagecontainer( "change", "#container" );
        $('#workday').html(workdays + 1);
        generateTasks();
        setupClock();
        $('#workday_results').popup( "close" );
    });
    $('.clear_answers').click(clearAnswers);
    $('#answer_call').click(answerPhone);
    $('#dismiss_call').click(dissmissCall);
    $('.meter').click(createPointGraphs);
    $('#sound').click(updateSoundOptions);
    $(document).on('click', '#gameResults', function() {
        $('#playerModal').popup('close');
        $('#gameOverModal').popup('open');
    });

    $( "body" ).pagecontainer({ defaults: true});
    $( "body" ).on( "pagecontainershow", function( event, ui ) {
        page = $( "body" ).pagecontainer( "getActivePage" ).attr('id');

        if(task_queue && page !== 'question-content') {
            checkPageTasks(page);
        }

        // Remove alerts
        $('.error').remove();
    } );

    $( document ).one( "pagecreate", "[data-role='page']", function() {
        var page = $( this ).attr( "id" );
        if(page === 'question-content') { // Redirect to frontpage
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#container" );
        } else if(!use_database)
            $( ":mobile-pagecontainer" ).pagecontainer( "change", "#container" );
    });

    $( "body" ).on( "pagecontainerbeforechange", function( event, ui ) {
        var prev = ui.prevPage;
        var next = ui.toPage;
        var absUrl = ui.absUrl ? $.mobile.path.parseUrl(ui.absUrl).filename : "";
        if(use_database === true && typeof next == "object") { // Check login
//            $.ajaxSetup({ async: false });
            $.post('php/login.php', function(data) {
                if(data.state !== 'success' && data.state !== 'logged_in' ) {
                    sessionExpired();
                }
            });
//            $.ajaxSetup({ async: true });
        }
    } );

    $("#playerModal").on( "popupbeforeposition", function( event ) {
        $('#profileInfo .alert, #profileInfo .error').remove();
    });


    $(".logout_link").click(logOut);
    $(document).on("click", ".ui-popup-active .close_fullscreen", function() {
        var modal = $(this).closest('.ui-popup');
        modal.popup( "close" );
    });
    $(document).on("click", ".close", function() {
        $(this).closest('.task_container').css( "display", "none" );
    });
    $(document).on( 'click', '.alert button.close, .profile_alert button.close', function() {
        $(this).parent().remove();
    });
    $(document).on("click", '.inlineInput', function(e) {
        e.stopImmediatePropagation();
        var label = $(this).closest('.ui-btn');
        var parent = label.parent();
        var input = parent.find('input[type=radio], input[type=checkbox]');
        if(input) {
            input.attr('checked', true);
            if(parent.hasClass('ui-radio') && label.hasClass('ui-radio-off')) {
                var previous_selection = parent.parent().find('.ui-radio-on');
                previous_selection.removeClass('ui-radio-on').addClass('ui-radio-off');
                previous_selection.parent().find('input').attr('checked', false);
                label.removeClass('ui-radio-off').addClass('ui-radio-on');
            } else if(label.hasClass('ui-checkbox-off'))
                label.removeClass('ui-checkbox-off').addClass('ui-checkbox-on');
        }
    });

    $("#question-content").one( "pagecreate", function( event ) {
        createProgressBar();
    });

    launchFullScreen(document.documentElement);

    // Center back-button
    $('.back_button').css('top', ($(window).height() / 2 - 26) +'px');

    // Modal comments popup
    $("#mentorModal-comment").on('popupafterclose', function () {
        if(task_queue.Mentori_.length > 0)
            showTask(task_queue.Mentori_[0]);
        else if(task_queue.Puhelu.length === 0 && task_queue.työhuone.Puhekupla.length === 0 && task_queue.käytävä.Puhekupla.length === 0
                 && task_queue.toimitilat.Puhekupla.length === 0 && task_queue.neuvotteluhuone.Puhekupla.length === 0 && task_queue.kahvihuone.Puhekupla.length === 0
                  && task_queue.hallintola.Puhekupla.length === 0) { // Check if there are no tasks left!
            //listTasks();
            generateTasks();
        } else {
            var page = $( "body" ).pagecontainer( "getActivePage" ).attr('id');
            checkPageTasks(page);
        }
    });

    // Check if can play audio
    var canPlayAudio = !!audio.canPlayType && audio.canPlayType('audio/mpeg') !== "";
    if(!canPlayAudio) {
        $('#sound').hide();
        $('.meter_container').css('left', '10px');
    }

    // Event handlers for old answers
    $(document).on('click', '.answer_container .showAnswers', showAnswers);
    $(document).on('click', '.answer_container .hideAnswers', hideAnswers);
    $(document).on('collapsibleexpand', '.showGraph', showGraphForQuestion);
    $(document).on('click', '.answer_profile', answerQuestionnaire);

    // New answers
    $(document).on('collapsibleexpand', '.compare_button', showGraphForQuestionNew);

    //$(document).on('change', '#sex', function() { setAvatarImg($(this).val()); });
    $('#sex').change(function() { setAvatarImg($(this).val()); });

    // After first time closing the instructions, pop any Omakuva-tasks
    $('#mentorModal').one("popupafterclose", function() {
      // Check if any Omakuva-tasks in the queue
      if(task_queue['Omakuva'].length > 0) {
        showTask(task_queue['Omakuva'][0]);
      }
    });

});

/**
 * Sets phone size and max-height for #mentorModal .pageWrapper
 */
function setupPhonePosition() {
    // Setup phone size if smaller than default
    if( $(window).width() < 343) {
        var phone = $('#phoneModal .pageWrapper');
        phone.css('width', $(window).width());
        phone.css('height', $(window).height());
    }

    // Set max-height for mentorModal
    $('.pageWrapper').css('max-height', $(window).height() +'px');
    $('#mentorModal .pageWrapper').css('max-height', $(window).height() - 80 +'px');
}

/**
 * Launch game in fullscreen mode if possible.
 * @param {Object} element
 */
function launchFullScreen(element) {
    if(element.requestFullScreen) {
        element.requestFullScreen();
    } else if(element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if(element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
    }
}

/**
 * Called on game start. Gets old game status from server or starts a new game.
 */
function startGame() {
    var data = {}, result_ok = false;
    data.feature = 'Start game';
    var xhr = new XMLHttpRequest();
    xhr.onload = function(e) {
        if(this.responseText) {
            try {
                var r = JSON.parse(this.responseText);
                gameStatus.Status = r.Status;
                if(r.excluded_question_groups)
                    excludedQuestiongroups = r.excluded_question_groups;
                result_ok = true;
            } catch(error) {
                console.log(error);
                showAlert('Palvelimelta saatiin virheellinen vastaus peliä käynnistettäessä!');
                data.level = Palmu.LogLevel.FATAL;
                data.context = {'error_msg': error};
                LogData(data);
                result_ok = false;
            }

            if(result_ok) {
                if(!gameStatus.Status.UI_Aika)
                    gameStatus.Status.UI_Aika = 0;
                if(!map_view.stage) {
                    setupMapView();
                } else {
                    generateTasks();
                }
                if(!gameStatus.Status.UI_Workdays && gameStatus.Status.UI_Workdays !== 0) {
                    gameStatus.Status.UI_Workdays = 0;
                    data.value = 'New game';

                    // Show instructions
                    $('#mentorModal').popup( "open" );
                } else {
                    data.value = 'Load game';
                    // data.context = {'status': gameStatus.Status};
                }
                if(!gameStatus.Status.UI_Minutes)
                    gameStatus.Status.UI_Minutes = 480;
                setupTime( gameStatus.Status.UI_Workdays, gameStatus.Status.UI_Minutes );
                setupPoints();

                data.level = Palmu.LogLevel.INFO;
                LogData(data);
            }
        } else {
            showAlert('Pelin käynnistäminen epäonnistui!');
            data.level = Palmu.LogLevel.FATAL;
            data.context = {'error_msg': 'No response from server!'};
            LogData(data);
        }
    };
    xhr.onerror = function(e) {
            showAlert('Palvelimeen ei saatu yhteyttä peliä käynnistettäessä!');
            console.log("error (new game)", e);
            data.level = Palmu.LogLevel.FATAL;
            data.context = {'error_msg': e};
            LogData(data);
    };
    xhr.onabort = function(e) {
            console.log("abort (new game)", e);
            data.level = Palmu.LogLevel.WARN;
            data.context = {'error_msg': e};
            LogData(data);
    };
    xhr.open("POST", "php/mediator.php");
    xhr.send(JSON.stringify({"playerName": user.name, "playerOrganization": user.organization,"action": "createGame", replayid: replayids.main}));
}

/**
 * Helper function to collapse/show a container.
 */
function collapseContent() {
    var container = $(this).parent();
    if($(this).hasClass('ui-icon-minus')) {
        $(this).removeClass('ui-icon-minus').addClass('ui-icon-plus');
        $(this).find('input').attr('data-icon', 'plus');
    } else {
        $(this).removeClass('ui-icon-plus').addClass('ui-icon-minus');
        $(this).find('input').attr('data-icon', 'minus');
    }
    container.find('.collapsible').toggle();
}

/**
 * Saves feedback given by the user in #feedbackModal.
 */
function saveFeedback() {
    var feedback = [];
    var feedbackModal = $('#feedbackModal');
    feedbackModal.find('input, textarea').each( function() {
        if($(this).val() !== '' && $(this).val() !== 'skip') {
            feedback.push({"id": $(this).attr('id'), "val": $(this).val()});
        } else if($(this).val() === 'skip' && $(this).is(':checked')) {
            if(feedback[feedback.length-1] && feedback[feedback.length-1].id === $(this).attr('data-skip'))
                feedback.splice(feedback.length-1,1);
        }
    });

    if(feedback.length > 0) {
        $.post("php/save_feedback.php", {uid: user.id, feedback: feedback}, function(data) {
            feedbackModal.find('textarea').val('');
            feedbackModal.find('.ui-slider-input').val(5).slider("refresh");
            feedbackModal.find('.skip').prop("checked", false).checkboxradio( "refresh" );
            feedbackModal.find('.alert').remove();
            feedbackModal.find('.modalContent').prepend('<div class="alert alert-success">Tiedot tallennettu! Kiitos palautteestasi!</div>');
            feedbackModal.find('.pageWrapper')[0].scrollTo(0, 0);
        });
    } else {
        alert('Palautekentät tyhjiä!');
    }
}

/**
 * Reposition everything after resize.
 */
function onResize() {
    clearTimeout(resize_timeout);
    resize_timeout = setTimeout(function() {
        if($(window).height() - windowHeight > 40 || $(window).height() - windowHeight < -40
          || $(window).width() - windowWidth > 40 || $(window).width() - windowWidth < -40) {
            windowHeight = $(window).height();
            windowWidth = $(window).width();
            scale = null;
            main_scale = null;
            centering = null;
            main_centering = null;
            // Resize chart if exists
            if( $(".chart").length > 0 ) {
                resizeGraph();
            }
            setupPhonePosition();
            // TODO: Resize fullscreen
            setupMapView();
            $('.back_button').css('top', ($(window).height() / 2 - 26) +'px');
            resize_timeout = null;

            var data = {};
            data.feature = 'Resize screen';
            data.level = Palmu.LogLevel.INTERACTION;
            LogData(data);
        }
    }, 300);
}

/**
 * Resets game status.
 */
function resetGame() {
    var id = user.id;
    $.post("php/replay_game.php", {uid: id, replayid: replayids.main}, function(data) {
        gameover_shown = false;
        replayids.main = data;
        gameStatus.Status.UI_Aika = 0;
        gameStatus.Status.UI_Workdays = 0;
        gameStatus.Status.UI_Minutes = 480;
        resetClock();
        workdays = 0;
        startGame();
        answers_new = {};
        task_groups = {};
        $('#answerList .answer_collapsible').remove();
        $('#gameResults').hide();
    },'text').fail(function(data) {
        console.log('reset failed');
        var logdata = {
            'feature': 'Reset game',
            'value': 'Fail',
            'level': Palmu.LogLevel.ERROR
        };
        logdata.context = {'error_msg': data};
        LogData(logdata);
        showAlert('Pelin uudelleenkäynnistäminen epäonnistui.');
    });
}

/**
 * Mute/unmute sounds.
 */
function updateSoundOptions() {
    var current_val = $(this).attr('class');

    if(current_val === 'sound_on') { // Mute
        $(this).removeClass('sound_on').addClass('sound_off');
        $(this).find('img').attr('src', 'img/icons/sound/media-volume-0.png');
        audio_on = false;
    } else { // Sound on
        $(this).removeClass('sound_off').addClass('sound_on');
        $(this).find('img').attr('src', 'img/icons/sound/media-volume-2.png');
        audio_on = true;
    }
}

/**
 * Updates points from gameStatus.
 */
function setupPoints() {
    $('#talous_pist').html(gameStatus.Status.Talouspist);
    $('#hv_pist').html(gameStatus.Status.HVpisteet);
    $('#tehokkuus_pist').html(gameStatus.Status.Tehokkuuspist);
}

/**
 * Game over.
 */
function gameOver() {
    if(loading) {
        loading = false;
        $('#loading').hide();
    }
    if(!gameover_shown) {
        gameover_shown = true;
        $('#gameResults').show();
        showFinalProfileGraph();

        var data = {
            'feature': 'Game over',
            'value': 'true',
            'level': Palmu.LogLevel.INFO
        };
        data.context = {'status': gameStatus.Status};
        LogData(data);
    }
}

/**
 * Displays an alert with the provided error message.
 * @param {String} msg
 */
function showAlert(msg) {
    alert(msg + " Ole hyvä ja ota yhteys pelin ylläpitoon (magellan@uta.fi).\n\n \n\
            Ongelman selvittämistä auttaa jos kerrot viestissä käyttäjätunnuksesi, ajan jolloin virhe tapahtui sekä tämän virheilmoituksen sisällön.");
    $('#loading').hide();
}

/**
 * Called session has expired.
 */
function sessionExpired() {
    // Log session expired
    var data = {
        'feature': 'Session expired',
        'level': Palmu.LogLevel.ERROR
    };
    LogData(data);

    // Go to login
    window.location.href = base_url + "login.html?code=1";
}

function createPrintableResults() {
  // Generate HTML
  var printElement = $('#printableResults');
  var gameOverModalContent = $('#gameOverModalContent');
  var chart = gameOverModalContent.find('canvas')[0].toDataURL();
  printElement.empty();
  printElement.append(gameOverModalContent.find('.alert-success').clone());
  printElement.find('.alert-success p').last().remove();
  printElement.append('<table id="rgraph_key" style="display: inline;" cellspacing="0" cellpadding="0" border="0">\n\
                        <tbody><tr><td><img style="display: inline-block; margin-right: 5px; margin-top: 4px;" src="img/own_game_color.jpg" /></td><td><span>Oma peli</span></td></tr>\n\
                        <tr><td><img style="display: inline-block; margin-right: 5px; margin-top: 4px;" src="img/all_players_color.jpg" /></td><td><span "="">'+allPlayersLabel+'</span></td></tr>\n\
                        <tr><td><img style="display: inline-block; margin-right: 5px; margin-top: 4px;" src="img/organization_color.jpg" /></td><td><span "="">'+organizationLabel+'</span></td></tr></tbody></table>');
  printElement.append('<div><img src="'+chart+'" alt="" /></div>');
  printElement.append('<br/><br/><br/><br/><br/><div class="quinn_tooltip"><h4>Verkosto-orientoitunut</h4><p>Elät muutoksessa ja luot sitä. Ajattelet luovasti ja esität ideoita. Ylläpidät suorituskykyä sekä neuvottelet sopimuksia.</p></div>\n\
                       <div class="quinn_tooltip"><h4>Tavoiteorientoitunut</h4><p>Työskentelet tuottavasti ja vaalit tuottavaa työympäristöä. Visioit, suunnittelet, organisoit ja asetat päämääriä. Delegoit tehokkaasti.</p></div>\n\
                       <div class="quinn_tooltip"><h4>Kontrolloiva</h4><p>Hallinnoit projekteja sekä eri toimintojen yhteistyötä. Tarkkailet henkilökohtaista suoriutumista ja hallinnoit organisaation suoriutumista.</p></div>\n\
                       <div class="quinn_tooltip"><h4>Osallistava</h4><p>Käytät osallistavaa päätöksentekoa ja kehität alaisiasi. Ymmärrät itseäsi ja muita sekä kommunikoit tehokkaasti.</p></div>\n\
                       <p><i>(Mukaellen Robert Quinn ym. 2003. Becoming a Master Manager – A Competency Framework. New York: John Wiley & Sons Inc.)</i></p>'
    );
}

function printGameOverResults() {
  printJS({ printable: 'printableResults', type: 'html', header: 'BosSi - profiili' });
}

function openFeedback() {
   $("#gameOverModal").popup("close");
   $("#feedbackModal").popup("open");
}
