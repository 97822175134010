/*jshint multistr: true */

/**
 * @file Handles questionnaire questions.
 * @author Katariina Tiitinen
 * 
 * @copyright 2015-2016 University of Tampere
 * Speech-based and Pervasive Interaction Group
 * Tampere Unit for Human-Computer Interaction (TAUCHI)
 * School of Information Sciences
 */

var questions = {};
var currentQuestionset = null;
var currentTopic = null;
var questionIndex = 0;
var questionType = null;
var questionObj;
var currentPoints = 0;
var maxPoints = 100;
var currentPrice = 0;
var questionIndexesById = {};
var progressBar = {
    setValue:function(id, value) {
        var bar = $(id);
        bar.val(value);
        bar.slider("refresh");
        bar.parent().find('.progress_label').html(value+'%');       
    }
};
var timerHandle = null;
var questionShown = 0;

/**
 * Creates a progress bar for questionnaires.
 */
function createProgressBar() {
    $('#progress').append('<input name="slider" id="progress_slider" data-highlight="true" min="0" max="100" value="0" type="range" disabled="" />');
    $('#progress_slider').slider();
    var bg = $('#progress .ui-slider-bg');
    $('<label class="progress_label">0%</label>').appendTo(bg);
    bg.addClass('progress-striped');
}


/**
 * Loads questions from file given as parameter.
 * @param {String} instructionFile
 */
function loadQuestions(instructionFile) {
    $.ajaxSetup({ async: false });
    if(use_database)
        instructionFile = 'php/get_all_questions.php';
    $.getJSON(instructionFile, function(data) {
        questions = {};
        var groups = data.questionGroups;
        $.each(groups, function(i, group) {
            questions[i] = group;
            
            //Creating questionid-indextable..
            if(!$.isEmptyObject(group.questionBatteries)) {
                $.each(group.questionBatteries, function(indexforquestionbattery, tempbattery) {
                    if(!$.isEmptyObject(tempbattery.questions)) {                
                        for(var tempindexforquestions = 0; tempindexforquestions < tempbattery.questions.length; tempindexforquestions++) {
                            questionIndexesById[tempbattery.questions[tempindexforquestions].questionid] = { "questiongroup": i, 
                                "questionbattery": indexforquestionbattery, "questionindex": tempindexforquestions };
                        }
                    }
                });
            }
        });
        
    });
    $.ajaxSetup({ async: true });
}


/*
 * Gets and displays the next question for given questionset and topic. 
 * @param {type} questionset
 * @param {type} topic (optional)
 */
function appendQuestionContent(questionset, topic) {  
    //console.log('appendQuestionContent');
    clearGraphInfo();
    $('.question-content .dynamic').empty();
    $('.question-content .buttons').remove();
    $('.question-content').show();
    currentQuestionset = questionset;
    if(!topic)
        currentTopic = getTopic(currentQuestionset);
    
    $('.question-content .headerIcon').attr('src', questions[currentQuestionset].iconurl);

    if(currentTopic) {
        var percentage = getAnswerPercentage(currentQuestionset, currentTopic);
        if(questions[currentQuestionset].questionBatteries[currentTopic].defaultparameters.showTopic === true)
            $('.question-content .topic').html(currentTopic);
        else
            $('.question-content .topic').html('');
        
        updateProgress(percentage);
    } else
        $('.question-content .topic').html('');

    questionObj = getRandomQuestion(currentQuestionset, currentTopic);

    if(questionObj && questionObj.question) {
        $('#qid').html(questionObj.question.questionid);
        questionIndex = questionObj.index;
     
        if(currentTopic === 'Ihannetyöpäivä' && questionObj.question.questionid === questions['Ihannetyöpäivä'].questionBatteries['Ihannetyöpäivä'].questions[questions['Ihannetyöpäivä'].questionBatteries['Ihannetyöpäivä'].questions.length - 1].questionid )
            showIdealWorkdayResults($('.question-content .dynamic'), true, questionObj.question);
        else
            checkQuestiontype(questionObj.question, questionObj.question.questionid);

        window.scrollTo(0, 0);
    } 
}

/**
 * Updates the progress bar in a questionnaire.
 * @param {Number} percentage
 */
function updateProgress(percentage) { 
    //console.log('updateProgress');
    $('#progress .progress_label').html(percentage+'%');
    $('#progress_slider').val(percentage).slider("refresh").trigger('refresh');
}

/**
 * Called when a questionset is finished.
 * @param {String} questionset
 */
function questionsetFinished(questionset) {
    clearGraphInfo();
    $('.question-content .dynamic').empty();
    $('.question-content .buttons').remove();
    $('.question-content').show();
    
    $('.question-content .headerIcon').attr('src', questions[questionset].iconurl);
    $('.question-content .topic').html('');
    updateProgress(100);
    $('.question-content .dynamic').html('Kaikkiin tämän aihealueen kysmyksiin vastattu!');
    $('.question-content .dynamic').after('<div class="buttons">\n\
                                        <button class="ui-btn ui-corner-all ui-btn-inline ui-btn-icon-left ui-icon-arrow-l minimize">Takaisin peliin</button>\n\
                                    </div>').trigger('create'); 
}

/**
 * Checks for the questions questiontype and calls the applicable append-function.
 * @param {Obj} question
 * @param {Number} question
 */
function checkQuestiontype(question, questionId) {
//    console.log('checkQuestiontype');
//    console.log(question);
    if(question.type === "slider")
        appendSliderQuestion(question);
    else if(question.type === "checkbox")
        appendCheckboxQuestion(question);
    else if(question.type === "scenario") 
        selectScenario(question);
    else if(question.type === "task") 
        appendTaskQuestion(question);
    else if(question.type === 'textarea') 
        appendOpenQuestion(question);
    else if(question.type === 'radio') 
        appendRadioQuestion(question, questionId);
    else if(question.type === 'tuunaa_työpäivä_työaika') 
        appendWorktimeQuestion(question, questionId);
}

/**
 * Gets the showtime for a question and calls a function to update showcount.
 * @param {Number} questionId
 */
function saveQuestionShowntime(questionId) {
    var date = new Date();
    questionShown = date.getTime();
    updateQuestionShowcount(questionId);
}

/**
 * Saves showcount for a questionnaire type question.
 * @param {Number} questionId
 */
function updateQuestionShowcount(questionId) {
    checkAnswerObj();
    if(!answers[currentQuestionset][currentTopic][questionId])
        answers[currentQuestionset][currentTopic][questionId] = {};
    var answer = answers[currentQuestionset][currentTopic][questionId];
    if(answer.show_count)
        answer.show_count++;
    else
        answer.show_count = 1;
    
    var answerdata = {uid: user.id, questionid: questionId,  answerdata: answer, answeringtime: answer.answeringtime, show_count: answer.show_count, replayid: replayids[currentQuestionset]};
    $.post("php/add_answer.php", answerdata, function(data) {  
        //console.log(data);
    },'text').fail(function(data) {
        // Show fail message
        var faildata = {
            'feature': 'Save question shown',
            'value': 'Error',
            'level': Palmu.LogLevel.ERROR,
            'parameters': {'questionId':questionId, 'error_msg': data}
        };
        LogData(faildata);
    });
    
    // Log tasks shown
    var data = {
        'feature': 'Question shown',
        'parameters': {"questionID": questionId, "showCount": answer.show_count},
        'level': Palmu.LogLevel.INFO
    };
    LogData(data);
}

/**
 * Adds a questiwith a range answer to the .dynamic div. Can also include a skip option and a text field.
 * @param {Object} question
 */
function appendSliderQuestion(question) { 
    var scaleStart, scaleEnd, scaleSize, skip;
    if(question.scale_size) {
        scaleStart = question.scale_start;
        scaleEnd = question.scale_end;
        scaleSize = question.scale_size;
        skip = question.skip;
    } else { // Get scale info from questionBattery or questionGroup
        var questionGroup = questions[currentQuestionset];
        var parameters = null;
        if(questionGroup.questionBatteries[currentTopic].defaultparameters.scale_start) {
            parameters = questionGroup.questionBatteries[currentTopic].defaultparameters;
        } else {
            parameters = questionGroup.defaultparameters;           
        }
        scaleStart = parameters.scale_start;
        scaleEnd = parameters.scale_end;
        scaleSize = parameters.scale_size;
        skip = parameters.skip;
    }
    if(scaleStart && scaleEnd && scaleSize && skip !== null) { // Everything ok.
        // jQuery mobile -slider
        $('.question-content .dynamic').html('<p class="question">'+question.question+'</p>\n\
                                            <div id="slider">\n\
                                                    <label for="sliderInput" data-theme="a">0 ('+scaleStart+') - '+scaleSize+' ('+scaleEnd+')</label>\n\
                                                    <input type="number" data-type="range" data-theme="a" name="sliderInput" id="sliderInput" value="'+ scaleSize / 2 +'" min="0" max="'+scaleSize+'" data-highlight="true">\n\
                                            </div>');
        $('#sliderInput').slider();

        if(question.extras) {
            for(var i=0;i<question.extras.length;i++) {
                if(question.extras[i].input === 'textarea')
                    $('.question-content .dynamic').append('<div data-role="fieldcontain">\n\
                                                        <label for="textarea">'+question.extras[i].label+'</label>\n\
                                                        <textarea cols="40" rows="1" name="textarea" id="textarea"></textarea>\n\
                                                </div>');
            }
        }

        if( skip === true) {
             $('.question-content .dynamic').append('<div class="checkbox skip">\n\
                                                    <label>\n\
                                                      <input type="checkbox" id="skip" value="skip"> ei koske minua\n\
                                                    </label>\n\
                                                  </div>');
        }
        
        appendNavButtons();
        questionType = 'slider';
        saveQuestionShowntime(question.questionid);
    } else {
        $('.question-content .dynamic').html('Virhe haettaessa kysymyksen tietoja!');
        appendNavButtons();
        questionType = null;
        $('.answer').attr('disabled','disabled');    
    }
    addLoggingToOldQuestions(question);
}

/**
 * Adds a question with the possibility to select multipla options to the .dynamic div.
 * @param {obj} question
 */
function appendCheckboxQuestion(question) {
    var options = questions[currentQuestionset].questionBatteries[currentTopic].questions[questionIndex].options;
    var selectMax = questions[currentQuestionset].questionBatteries[currentTopic].questions[questionIndex].selectMax;
    var form = $('<fieldset data-role="controlgroup" class="options" data-iconpos="right"></fieldset>');
    $('.question-content .dynamic').html('<p class="question">'+question.question+'</p>');
    var default_container = $('<div>'), container, container_a, container_b;
     if(question.imageoptions) {
         container_a = $('<div class="ui-block-a">');
         container_b = $('<div class="ui-block-b">');
     }
    for(var i=0;i<options.length;i++) {
        if(question.imageoptions && question.options[i].optionimage) {
            if(i % 2 === 0)
                container = container_a;
            else
                container = container_b;
        } else
            container = default_container;
        if(options[i].freetext) {
            container.append('<div class="ui-checkbox '+((question.options[i].optionimage) ? "optionimage": "")+'">\n\
                        <label class="ui-btn ui-corner-all ui-btn-a ui-btn-icon-right ui-checkbox-off">\n\
                        '+ ((question.options[i].optionimage) ? question.options[i].optionimage: question.options[i].optiontext) +'\n\
                        <input type="text" class="inlineInput" />\n\
                        </label><input type="checkbox" data-iconpos="right" data-theme="a" value="'+ question.options[i].optiontext +'" data-enhanced="true">\n\
                        </div>');
        } else {
            container.append('<div class="ui-checkbox '+((question.options[i].optionimage) ? "optionimage": "")+'">\n\
                        <label class="ui-btn ui-corner-all ui-btn-a ui-btn-icon-right ui-checkbox-off">\n\
                        '+ ((question.options[i].optionimage) ? question.options[i].optionimage: question.options[i].optiontext) +'\n\
                        </label><input type="checkbox" data-iconpos="right" data-theme="a" value="'+ question.options[i].optiontext +'" data-enhanced="true">\n\
                         </div>');
        }
    }
    if(question.imageoptions) {
        form.append(container_a);
        form.append(container_b);
     }
    form.append(default_container);
    $('.question-content .dynamic').append(form);
    $('.question-content .dynamic').trigger('create');
    $('.question-content :checkbox').change( function() {
       var checked = $('.question-content :checkbox:checked');
       if(checked.length === selectMax) {
           $('.question-content :checkbox:not(:checked)').checkboxradio('disable');
       } else {
           $('.question-content :checkbox').checkboxradio('enable');
       }
           
    });
    appendNavButtons();
    questionType = 'checkbox';
    saveQuestionShowntime(question.questionid);
    addLoggingToOldQuestions(question);
}

/**
 * Checks if a scenario for this question has already selected, and if not 
 * selects one of the possible scenario-questions and saves it to answers.
 * Continues to append the question depending on the question type.
 * @param {Object} question
 */
function selectScenario(question) {
    var scenario;
    if(answers[currentQuestionset] && answers[currentQuestionset][currentTopic] && answers[currentQuestionset][currentTopic][question.questionid]) {
        var selected = answers[currentQuestionset][currentTopic][question.questionid];
        scenario = question.scenarios[selected.scenario_index];
    } else {
        var randomIndex = Math.floor((Math.random() * question.scenarios.length) + 0); 
        scenario = question.scenarios[randomIndex];
        saveSelectedScenario(randomIndex);
    }
    checkQuestiontype(scenario, question.questionid);
}

/**
 * Adds a question with radio buttons for answering to the .dynamic div.
 * @param {obj} question
 * @param {Int} questionId
 * @param {Boolean} keep_content Keep previous content
 */
function appendRadioQuestion(question, questionId, keep_content) {
    if(keep_content)
        $('.question-content .dynamic').append('<p class="question">'+question.question+'</p>');
    else
        $('.question-content .dynamic').html('<p class="question">'+question.question+'</p>');
     var form = $('<form class="options">');
     var default_container = $('<div>'), container, container_a, container_b;
     if(question.imageoptions) {
         container_a = $('<div class="ui-block-a">');
         container_b = $('<div class="ui-block-b">');
     }
     for(var i=0;i<question.options.length;i++) {
         if(question.imageoptions && question.options[i].optionimage) {
            if(i % 2 === 0)
                container = container_a;
            else
                container = container_b;
        } else
            container = default_container;
            
         if(!question.options[i].optiontext) {
            container.append('<label><input type="radio" value="'+ question.options[i] +'" data-theme="a" name="radio-option" data-iconpos="right">\n\
                             '+ question.options[i] +'</label>');
        } else if(!question.options[i].freetext) {           
            container.append('<div class="ui-radio '+((question.options[i].optionimage) ? "optionimage": "")+'">\n\
                        <label class="ui-btn ui-corner-all ui-btn-a ui-btn-icon-right ui-radio-off">\n\
                        '+ ((question.options[i].optionimage) ? question.options[i].optionimage: question.options[i].optiontext) +'\n\
                        </label><input type="radio" data-iconpos="right" name="radio-option" data-theme="a" value="'+ question.options[i].optiontext +'" '+ ((question.options[i].skip_next)? 'data-skipnext="true"':'') +' checked="" data-enhanced="true">\n\
                         </div>');
        } else {
            container.append('<div class="ui-radio '+((question.options[i].optionimage) ? "optionimage": "")+'">\n\
                        <label class="ui-btn ui-corner-all ui-btn-a ui-btn-icon-right ui-radio-off">\n\
                        '+ ((question.options[i].optionimage) ? question.options[i].optionimage: question.options[i].optiontext) +'\n\
                        <input type="text" class="inlineInput" />\n\
                        </label><input type="radio" data-iconpos="right" name="radio-option" data-theme="a" value="'+ question.options[i].optiontext +'" '+ ((question.options[i].skip_next)? 'data-skipnext="true"':'') +' checked="" data-enhanced="true">\n\
                        </div>');
        }
    }
    if(question.imageoptions) {
        form.append(container_a);
        form.append(container_b);
     }
    form.append(default_container);
    $('.question-content .dynamic').append(form);
    $('.question-content .dynamic input').first().attr('checked', 'checked');
    $('.question-content .dynamic').trigger('create');
    appendNavButtons();
    questionType = 'radio';
    saveQuestionShowntime(questionId);
    addLoggingToOldQuestions(question);
}

/**
 * Adds an open question to the .dynamic div.
 * @param {obj} question
 */
function appendOpenQuestion(question) {
    $('.question-content .dynamic').html('<p class="question">'+question.question+'</p>');
    var form = $('<form class="options">');
    form.append('<textarea cols="40" rows="1"></textarea>');
    $('.question-content .dynamic').append(form);
    appendNavButtons();
    if(question.skip) {
        $('.answer').removeAttr('disabled');
    } else {
        $('.answer').attr('disabled', 'disabled');
        $('.question-content textarea').on('keyup', function() {
           var val = $(this).val();
           if(val.length > 0) {
               $('.answer').removeAttr('disabled');
           } else
               $('.answer').attr('disabled', 'disabled');
        });
    }
    questionType = 'textarea';
    saveQuestionShowntime(question.questionid);
    addLoggingToOldQuestions(question);
}

/** 
 * Called when question is a more complicated task (not used ATM).
 * @param {Object} question
 */
function appendTaskQuestion(question) {
    $('.question-content .dynamic').html('<p class="question">'+question.task_description+'</p>');								
    questionType = "task";
    $('.question-content').show();
}

/** Adds default buttons to the .dynamic div. **/
function appendNavButtons() {
    $('.question-content .dynamic').after('<div class="buttons">\n\
                                        <button class="ui-btn ui-corner-all ui-btn-inline ui-btn-icon-left ui-icon-arrow-l minimize">Takaisin peliin</button>\n\
                                        <button class="ui-btn ui-corner-all ui-btn-inline ui-btn-icon-left ui-icon-action answer btn-primary">Vastaa</button>\n\
                                    </div>').trigger('create');                               
}

/** 
 * Gets the first unfinished topic in the given questionset.
 * @param {type} questionset
 * @returns {String} Returns the key of the topic if available, else null
 */
function getTopic(questionset) {
    if(questions[questionset] ) {
        for (var key in questions[questionset].questionBatteries) {
            if(!checkAllAnswered(questionset, key)) {
                return key;      
            }
        }
    } else
        return null;
}

/**
 * Checks if all the questions in a given topic has been answered.
 * @param {String} questionset
 * @param {String} topic
 * @returns {Boolean} true if all answered, else false
 */
function checkAllAnswered(questionset, topic) {
//    console.log('checkAllAnswered');
    if(questions[questionset].questionBatteries[topic].questions) {
        var questionCount = Object.keys(questions[questionset].questionBatteries[topic].questions).length;
        var answerCount = 0;
        if(answers[questionset] && answers[questionset][topic]) {
            var answerList = answers[questionset][topic];
            for (var key in answerList) {
                if($.isEmptyObject(answerList[key]) || !answerList[key].finished || (answerList[key].finished !== true && answerList[key].finished !== "true"))
                    return false;
            }

            answerCount = Object.keys(answers[questionset][topic]).length;
            if(answerCount < questionCount)
                return false;
            else
                return true;
        }
    } else
        return true;
}

/**
 * Checks if the given questionset is completed. 
 * @param {String} questionset
 * @returns {Boolean} true if completed, else false
 */
function checkQuestionsetCompleted(questionset) {
    for (var key in questions[questionset].questionBatteries) {
        if(questions[questionset].questionBatteries[key].questions) {
            if(!checkAllAnswered(questionset, key)) 
                return false;    
        } 
    }
    return true;

}

/** 
 * Returns a random question from the given questionset and topic.
 * @param {String} questionset
 * @param {String} topic
 * @returns {obj} question
 */
function getRandomQuestion(questionset, topic) {
    var answerList;
    if(questionset && topic && questions[questionset] && questions[questionset].questionBatteries[topic] ) {
        // First check if we have unfinished questions
        if(answers[questionset] && answers[questionset][topic]) {
            answerList = answers[questionset][topic];
            for (var key in answerList) {
                if(!answerList[key].finished || (answerList[key].finished !== true && answerList[key].finished !== "true")) {
                    return {"index": questionIndexesById[key].questionindex, "question":getQuestionByID(key)};
                }
            }
        }
        
        var questioncount, randomIndex = null, question, questionId;
        // No unfinished question, get random, or if currentQuestionset = 'Ihannetyöpäivä', get next
        if(currentQuestionset !== 'Ihannetyöpäivä') {
            questioncount = questions[questionset].questionBatteries[topic].questions.length;
            randomIndex = Math.floor((Math.random() * questioncount) + 0); 
            question = null;
            if(answers[currentQuestionset] && answers[currentQuestionset][currentTopic]) {
                questionId = questions[currentQuestionset].questionBatteries[currentTopic].questions[randomIndex].questionid;
                while(answers[currentQuestionset][currentTopic][questionId] && answers[currentQuestionset][currentTopic][questionId].finished && (answers[currentQuestionset][currentTopic][questionId].finished === true || answers[currentQuestionset][currentTopic][questionId].finished === "true" ) ) {
                    randomIndex = Math.floor((Math.random() * questioncount) + 0); 
                    questionId = questions[currentQuestionset].questionBatteries[currentTopic].questions[randomIndex].questionid;
                }
            }
        } else {
            if(answers[questionset] && answers[questionset][topic]) {
                answerList = answers[questionset][topic];
                for (var index in answerList) {
                    if(!randomIndex)
                        randomIndex = index;
                    else if(index > randomIndex)
                        randomIndex = index;
                }
                randomIndex = questionIndexesById[randomIndex].questionindex + 1;
            } else {
                randomIndex = 0;
            }
        }
        question = questions[questionset].questionBatteries[topic].questions[randomIndex];
        return {"index": randomIndex, "question": question}; 
    } else
        return null;
}

/**
 * Custom function for adding inputs for worktime question in 'Ihannetyöpäivä'.
 * @param {Object} question
 */
function appendWorktimeQuestion(question) {
    $('.question-content .dynamic').html('<p class="question">'+question.question+'</p>');
    var form = $('<form class="workday_options">');
    form.append('<label for="basic">Työaika:</label>\n\
        <input type="text" name="from" value=""><span>-</span>\n\
        <input type="text" name="to" value="">');
    // Breaks
    form.append('<label>Tauot:</label>');
    form.append('<div class="ui-field-contain">\n\
        <label>1. tauko, kesto (min):</label>\n\
        <input class="break" type="text" value="">\n\
        <img class="delete_break" src="img/delete.png" alt="Poista tauko" />\n\
    </div>');
    form.append('<input type="button" id="addBreak" value="Lisää tauko" />');
    form.find('#addBreak').click( function(e) {
        e.preventDefault();
        var break_count = form.find('.break').length;
        if(break_count === 0) {          
            $('.workday_options .no_breaks').remove();
        }
        break_count++;
        $(this).parent().before('<div class="ui-field-contain">\n\
            <label>'+break_count+'. tauko, kesto (min):</label>\n\
            <div class="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input class="break" type="text" value=""></div>\n\
            <img class="delete_break" src="img/delete.png" alt="Poista tauko" />\n\
        </div>');
        addLoggingToOldQuestions(question, $('.question-content .dynamic .ui-field-contain').last());
    });
    $('.question-content .dynamic').append(form);
    appendNavButtons();
    questionType = 'tuunaa_työpäivä_työaika';
    saveQuestionShowntime(question.questionid);
    addLoggingToOldQuestions(question);
}

/**
 * Deletes a brake option from the custom worktime question in 'Ihannetyöpäivä'.
 * @param {Object} e
 */
function deleteBreak(e) {  
    $(this).parent().remove();
    var break_count = $('.workday_options .break').length;
    if(break_count === 0)
        $('.workday_options #addBreak').parent().before('<span class="no_breaks">Ei taukoja!<span>');
    else {
        $('.workday_options .ui-field-contain').each( function(i) {
            $(this).find('label').html((i+1)+'. tauko, kesto (min):');
        });
    }
}